import { Howl, Howler } from 'howler';
import logger from '../core/logger.js';
import AppCore from './AppCore.js';

function svolume(category) {
	return localStorage.getItem('settings.' + category) ?? 1;
}

// const sprites05s = {
// 	chest: [0, 500],
// 	click_strong: [500, 150], // original - click_wrong
// 	click: [1000, 80],
// 	error: [1500, 666] // original - dialog
// };

// const sprites1s = {
// 	click_strong_1: [0, 1000], // original - equipment
// 	unlock: [1000, 1000],
// 	award: [2000, 1000],
// 	coffee_off: [3000, 1000],
// 	coin: [4000, 1000]
// };

// const sprites2s = {
// 	coins: [0, 2000],
// 	happy: [2000, 2000],
// 	upgrade_0: [4000, 2000], // original - object_upgrade
// 	open_chest: [6000, 2000],
// 	success: [8000, 2000],
// 	unit_upgrade: [10000, 2000],
// 	spend_coin_0: [12000, 2000],
// 	spend_coin_1: [14000, 2000]
// };

// const sprites4s = {
// 	applause: [0, 4000],
// 	coffee_on: [4000, 4000],
// 	defeat: [8000, 3750],
// 	legendary: [12000, 4000],
// 	manager_female_0: [16000, 4000],
// 	new_floor: [20000, 4000],
// 	victory: [24000, 3700]
// };

// const sprites6s = {
// 	floor_upgrade: [0, 6000],
// 	manager_0: [6000, 6000]
// };

export default class SoundManager {
	constructor() {
		this.assets = {};
		this.theme = null;
		this.themeName = '';
		this.themeSeek = 0;
	}

	init() {
		this.dispose();

		// window.addEventListener('blur', () => {
		// 	AppCore.instance.sounds.onWindowBlur();
		// });

		// window.addEventListener('focus', () => {
		// 	AppCore.instance.sounds.onWindowFocus();
		// });

		Howler.autoSuspend = false;

		document.addEventListener('visibilitychange', () => {
		  const { ctx } = Howler;
		  if (ctx && !document.hidden) {
		    setTimeout(() => {
		      ctx.resume();
		    }, 100);
		  }
		}, false);

		//...
	}

	dispose() {
		for (const k in this.assets) {
			const asset = this.assets[k];
			if (asset.state() !== 'unloaded') {
				asset.unload();
			}
		}

		this.assets = {};
	}

	preload() {
		return Promise.all([
			// this.loadsound('sprites05s', './res/sounds/sounds-0.5s.mp3', { sprite: sprites05s }),
			// this.loadsound('sprites1s', './res/sounds/sounds-1s.mp3', { sprite: sprites1s }),
			// this.loadsound('sprites2s', './res/sounds/sounds-2s.mp3', { sprite: sprites2s }),
			// this.loadsound('sprites4s', './res/sounds/sounds-4s.mp3', { sprite: sprites4s }),
			// this.loadsound('sprites6s', './res/sounds/sounds-6s.mp3', { sprite: sprites6s }),

			this.loadsound('theme-main', './res/sounds/music-main-0.mp3'),
			this.loadsound('theme-battle', './res/sounds/music-battle-0.mp3'),
			// this.loadsound('ambience-0', './res/sounds/ambience-office.mp3'), unused
			
			// sprites05s
			this.loadsound('chest', './res/sounds/chest.mp3'),
			this.loadsound('click_strong', './res/sounds/click_strong.mp3'),
			this.loadsound('click', './res/sounds/click.mp3'),
			this.loadsound('error', './res/sounds/error.mp3'),

			// sprites1s
			this.loadsound('click_strong_1', './res/sounds/click_strong_1.mp3'),
			// this.loadsound('unlock', './res/sounds/unlock.mp3'),	 unused
			// this.loadsound('award', './res/sounds/award.mp3'), unused
			this.loadsound('coffee_off', './res/sounds/coffee_off.mp3'),
			this.loadsound('coin', './res/sounds/coin.mp3'),

			// sprites2s
			this.loadsound('coins', './res/sounds/coins.mp3'),
			this.loadsound('happy', './res/sounds/happy.mp3'),
			this.loadsound('upgrade_0', './res/sounds/upgrade_0.mp3'),
			// this.loadsound('open_chest', './res/sounds/open_chest.mp3'), unused
			this.loadsound('success', './res/sounds/success.mp3'),
			// this.loadsound('unit_upgrade', './res/sounds/unit_upgrade.mp3'), unused
			this.loadsound('spend_coin_0', './res/sounds/spend_coin_0.mp3'),
			this.loadsound('spend_coin_1', './res/sounds/spend_coin_1.mp3'),

			// sprites4s
			this.loadsound('applause', './res/sounds/applause.mp3'),
			this.loadsound('coffee_on', './res/sounds/coffee_on.mp3'),
			this.loadsound('defeat', './res/sounds/defeat.mp3'),
			// this.loadsound('legendary', './res/sounds/legendary.mp3'), unused
			// this.loadsound('manager_female_0', './res/sounds/manager_female_0.mp3'), unused
			this.loadsound('new_floor', './res/sounds/new_floor.mp3'),
			this.loadsound('victory', './res/sounds/victory.mp3'),

			// sprites6s
			// this.loadsound('floor_upgrade', './res/sounds/floor_upgrade.mp3'), unused
			// this.loadsound('manager_0', './res/sounds/manager_0.mp3') unused
		]);
	}

	/**
	 *
	 * @param {string} name asset name
	 * @param {string} path file path
	 * @param {object} opts .
	 * @param {boolean} [opts.streaming=false] use streaming mode
	 * @param {object?} opts.sprite sprites mode
	 * @returns
	 */
	loadsound(name, path, { streaming = false, sprite = null } = {}) {
		return new Promise((resolve, reject) => {
			var sound = new Howl({
				src: [path],
				html5: streaming,
				preload: true,
				// sprite
			});

			// if (sprite) {
			// 	for (const k in sprite) {
			// 		this.assets[k] = sound;
			// 	}
			// } else {
				this.assets[name] = sound;
			// }

			sound.once('load', function () {
				let str = `Sound ${name} (path: '${path}') loaded.`;
				// if (sprite) {
				// 	str += ` Sprite list: ${Object.keys(sprite).toString()}`;
				// }
				logger.group('GAME_RES_OPERATION', str);

				resolve(sound);				
			});

			sound.once('loaderror', function (err) {
				const str = `Sound ${name} (path: '${path}') load error`;
				logger.groupError('GAME_RES_ERROR', str);
				reject(new Error(str));
			});

			sound.on('play', function () {
				logger.group('GAME_SOUND_PLAY', `Play sound ${name}: success`);
			});

			sound.on('playerror', function (err) {
				logger.groupError('GAME_SOUND_PLAY_ERROR', `Play sound ${name}: error`, err);
			});
		});
	}

	/**
	 *
	 * @param {string} name sprite name
	 * @param {number} volume .
	 * @param {number} [themeFade=0] 0-1 fades theme
	 */
	play(name, volume = 1, themeFade = 0) {
		const sound = this.assets[name];

		if (!sound || svolume('sound') === '0') return;

		try {
			logger.group(
				'GAME_SOUND_PLAY',
				`Request sound ${name}. Volume: ${volume}, theme fade ${themeFade}`
			);

			sound.play();

			// sprite default type workaround
			// let duration = 0;
			// if (sound._sprite.__default) {
			// 	sound.play();
			// 	duration = sound._sprite.__default[1];
			// } else {
			// 	sound.play(name);
			// 	duration = sound._sprite[name][1];
			// }

			// if (themeFade && this.theme) {
			// 	const originalVolume = this.theme.volume();
			// 	const maxVolume = Math.min(1 - themeFade, svolume('music'));
			// 	const theme = this.theme;
			// 	theme.fade(originalVolume, maxVolume, Math.min(2000, duration * 0.3));

			// 	let reverted = false;
			// 	const revert = () => {
			// 		if (!reverted) {
			// 			reverted = true;
			// 			theme.fade(maxVolume, originalVolume, Math.min(1000, duration * 0.1));
			// 		}
			// 	};
			// 	sound.once('end', revert);
			// 	sound.once('stop', revert);
			// }
		} catch (err) {
			logger.groupError('GAME_SOUND_PLAY_ERROR', `Request sound ${name} failed`, err);
		}

		return sound;
	}

	stop(name) {
		const sound = this.assets[name];
		if (sound) sound.stop();
	}

	setThemeState(state) {
		state === 1 ? this.playTheme(this.themeName) : this.stopTheme();
	}

	playTheme(name) {
		this.themeName = name;

		if (svolume('music') === '0') return;

		try {
			this.stopTheme();
			this.theme = this.assets[name];
			this.theme.play();
			this.theme.loop(true);
		} catch (err) {
			logger.groupError('GAME_SOUND_PLAY_ERROR', `Request sound ${name} failed`, err);
		}
	}

	stopTheme() {
		if (this.theme) {
			this.theme.stop();
			this.theme = null;
		}
	}

	onWindowBlur() {
		if (this.theme && this.theme.playing()) {
			this.themeSeek = this.theme.seek();
			this.theme.stop();
		}
		Howler.volume(0);
	}

	onWindowFocus() {
		if (this.theme && !this.theme.playing()) {
			this.theme.seek(this.themeSeek);
			this.theme.play();
		}
		Howler.volume(1);
	}
}

/**
sounds-0.5s
	0,00 - 0,50: sound-chest
	0,50 - 0,65: sound-click-wrong
	1,00 - 1,08: sound-click
	1,50 - 1,666: sound-dialog
sounds-1s
	0,00 - 1,00: sound-equipment
	1,00 - 2,00: sound-unlock
	2,00 - 3,00: sound-award
	3,00 - 4,00: sound-coffe-on
	4,00 - 5,00: sound-coin
sounds-2s
	0,00 - 2,00: sound-coins
	2,00 - 4,00: sound-happy
	4,00 - 6,00: sound-object-upgrade
	6,00 - 8,00: sound-open-chest
	8,00 - 10,00: sound-success
	10,00 - 12,00: sound-unit-upgrade
	12,00 - 14,00: sound-spend-coin-0
	14,00 - 16,00: sound-spend-coin-1
sounds-4s
	0,00 - 4,00: sound-applause
	4,00 - 8,00: sound-coffee-off
	8,00 - 11,75: sound-defeat
	12,00 - 16,00: sound-legendary
	16,00 - 20,00: sound-manager-female-0
	20,00 - 24,00: sound-new-floor
	24,00 - 27,70: sound-victory
sounds-6s
	0,00 - 6,00: sound-floor-upgrade
	6,00 - 12,00: sound-manager-0
 */
