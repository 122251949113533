import { create as mathjsCreate, all as mathjsAll } from 'mathjs';

if (!window.mathjs) {
	window.mathjs = mathjsCreate(mathjsAll);

	window.mathjs.config({
		number: 'BigNumber',
		precision: 64
	});
}
export default window.mathjs;
