/**
 * @File OfficeView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */

import { ViewBase, VIEW_STATES } from '../../core/views.js';
import AppCore from '../AppCore.js';
import { action, viewPush, config, sound } from '../shorthands.js';
import ProfitSourceItd from '../../itd5ka/ProfitSourceItd.js';
import RoomDetailsView from './RoomDetailsView.js';
import logger from '../../core/logger.js';
import BattlePrepareView from './BattlePrepareView.js';
import BattleResultsView from './BattleResultsView.js';
import MessageView from './MessageView.js';
import {
	setScrollPos,
	listenScrollEvents,
	unlistenScrollEvents,
	holdScrollEvents,
	autoScrollTo,
	holdMouseEvents
} from '../dust/ui_utils.js';
import { generateFloorEffects } from '../../itd5ka/generateFloorEffects.js';

const CURRENTLY_IN_BATTLE = 'currently_in_battle';
const DEFEATED_IN_BATTLE = 'defeated_in_battle';
const BATTLE_MODE = 'battle_mode';
const SHIELDED = 'shielded';

/**
 * Draws itd 5ka main view
 */
export default class OfficeView extends ViewBase {

	/**
	 * @param {number} stage active stage index
	 */
	constructor(upgradeMulIndex = 0) {
		super();

		this.cache = {
			autoscrollPos: -1,
			currentlyInBattleFloor: -1
		};
		this.psources = [];

		this.upgradeMulIndex = upgradeMulIndex;
		this.upgradeMulVariants = [1, 10, 50];
	}

	/* eslint-disable complexity */
	/**
	 * @param {string} key click element key
	 * @param {oject} args click arguments
	 */
	_click(key, args) {
		const ps = AppCore.instance.game.inventory.get(args.id);

		if (AppCore.ignored_log_events.indexOf(key) < 0) {
			const logWithResources = 
				(key !== "button_location_prev") &&
				(key !== "button_location_next");
			AppCore.instance.logOnServer("user_tap", {name: key}, logWithResources);
		}

		const stage = AppCore.instance.selectedLocation;
		const battleid = AppCore.instance.game.inventory.stages[stage].id;
		const battle = AppCore.instance.game.battles[battleid];

		switch (key) {
			case 'button_upgrade': {
				if (ps.active) {
					const upgrades = this.upgradeMulVariants[this.upgradeMulIndex];
					let upgraded = 0;
					
					while (upgraded < upgrades) {
						if (action('upgrade_psource', args)) break;
						upgraded++;
					}
					
					if (upgraded > 0) {
						AppCore.instance.logOnServer("user_upgrade_floor", { upgraded: upgraded }, true);
					}

					sound('coin');
				} else {
					let upgraded = 0;
					
					action('purchase_psource');
					upgraded++;
					
					if (upgraded > 0) {
						AppCore.instance.logOnServer("user_buy_floor", null, true);	
					}
					sound('upgrade_0');
				}
				break;
			}
			case 'button_battle': {
				this._battleButtonClick();
				return;
			}
			case 'container_info':
				if (battle) {
					return;
				}

				viewPush(new RoomDetailsView(args.id));
				return;
			case 'button_itd_main':
				this.upgradeMulIndex = (this.upgradeMulIndex + 1) % this.upgradeMulVariants.length;
				AppCore.instance.itd5ka.shell.print(
					`Улучшить x${this.upgradeMulVariants[this.upgradeMulIndex]}`,
					'itd_main',
					'button'
				);
				break;
			case 'container_tasks': {
				if (!battle) {
					return;
				}

				action('battle_user_click', {
					room: args.id,
					battleid
				}, true);

				const damage = config('battle_user_click', { room: args.id });
				this._draw_task_damage({ dmg: damage, roomId: args.id, dmg_type: 'click' });
				return;
			}
			case 'button_coffee': {
				const battleid = AppCore.instance.game.inventory.stages[stage].id;
				action('use_coffee', { battleid });
				break;
			}
			default:
				return;
		}

		this.draw();
	}

	/**
	 * @override
	 */
	init() {
		const shell = AppCore.instance.itd5ka.shell;
		const psources = AppCore.instance.game.inventory.psources;
		const stage = AppCore.instance.selectedLocation;

		for (const i in psources) {
			const ps = psources[i];

			if (ps.stage !== stage) {
				continue;
			}

			const psItd = new ProfitSourceItd(
				ps.id,
				shell.html.container_psource,
				shell.html.template_psource
			);

			this.psources.push(psItd.init());

			psItd.events.on('user_click', ({ detail, key }) => {
				this._click(key, { id: ps.id, index: i });
				AppCore.instance.itd5ka.shell.logClick(`🖱️ user click :${key}`, key, detail);
			});
		}

		listenScrollEvents();
		this.listenTriggerEvents();

		shell.html.decor_background.classList.value = '';
		shell.html.decor_background.classList.add('color-' + stage);

		if (!this.psources.length) {
			logger.group(
				'GAME_RES_ERROR',
				`Ошибка загрузки конфига: в OfficeView не был создан ни один объект этажа. Что-то плохо экспортировалось.`
			);
		}
	}

	/**
	 * .
	 */
	listenTriggerEvents() {
		this.td_battle_finished = AppCore.instance.events.on('td_battle_finished', ({ win }) => {
			this.removeEmployeesShield();
			const stage = AppCore.instance.selectedLocation;
			const battleid = AppCore.instance.game.itd.stageIndexToId(stage);

			// k !== 'pvp' - костыль
			if (battleid !== 'pvp') {
				const battle = AppCore.instance.game.battles[battleid];
				if (battle) {
					battle.win = win;
					battle.cache.finishTimestamp = Date.now();
					const rewards = AppCore.instance.game.getConfig('game', 'battle_rewards_lootpacks');
					const lootpack = rewards[battle.audits - 1];
					action('register_completed_audit', battle);
					const event = win? "user_won_sprint" : "user_lose_sprint";
					AppCore.instance.logOnServer(event, {
						auditLevel: battle.auditLevel,
						audits: battle.audits,
						coffeeUsed: battle.coffeeUsed
					});
	
					AppCore.instance.views.push(
						new BattleResultsView({
							win: win,
							lootpack,
							gems: win ? config('audit_reward', battle) : 0,
							duration: battle.cache.finishTimestamp - battle.cache.startTimestamp
						})
					);
				}
			}

			delete AppCore.instance.game.battles[battleid];
			AppCore.instance.events.off('td_battle_get_damage', this.get_damage);
			AppCore.instance.events.off('td_battle_task_damage', this.task_damage);
		}).func;
		this.redraw_offices = AppCore.instance.events.on('redraw_offices', () => {
			console.log('on redraw_offices');
			this.draw();
		}, this).func;
	}

	/**
	 * @override
	 */
	show() {
		this.resetCache();
		this.draw();

		holdScrollEvents(false);

		const stage = AppCore.instance.selectedLocation;
		const shell = AppCore.instance.itd5ka.shell;
		const conf = AppCore.instance.game.inventory.stages[stage];
		shell.print(conf.name, 'itd_status');

		shell.print(`Улучшить x${this.upgradeMulVariants[this.upgradeMulIndex]}`, 'itd_main', 'button');

		shell.backgorundStyle('default');

		this.cache.clickEventId = shell.events.on(
			'user_click',
			(opts) => {
				this._click(opts.key, {});
			},
			this
		).count;

		const battleid = AppCore.instance.game.itd.stageIndexToId(stage);
		const battle = AppCore.instance.game.battles[battleid];
		holdMouseEvents(battle);
	}

	/**
	 * @override
	 */
	hide() {
		holdScrollEvents(true);

		const shell = AppCore.instance.itd5ka.shell;
		shell.print('Этажи', 'itd_main', 'button');
		shell.elementVisible('button_location_next', false);
		shell.elementVisible('button_location_prev', false);

		shell.events.off(this.cache.clickEventId);
	}

	/**
	 * @override
	 */
	dispose() {
		AppCore.instance.events.off('td_battle_get_damage', this.get_damage);
		AppCore.instance.events.off('td_battle_task_damage', this.task_damage);
		AppCore.instance.events.off('redraw_offices', this.redraw_offices);
		AppCore.instance.events.off('td_battle_finished', this.td_battle_finished);
		const header = document.querySelector('#psource.header_office');
		header.classList.add('hidden');

		unlistenScrollEvents();

		for (const i in this.psources) {
			this.psources[i].dispose();
		}
		
		if (this.roof) {
			this.roof.classList.add('unavailable');
			this.roof.classList.add('hidden');
		}
	}

	/**
	 * Draws data into HTML
	 */
	draw() {
		const shell = AppCore.instance.itd5ka.shell;
		const stage = AppCore.instance.selectedLocation;

		let npcs = [];
		for (const i in this.psources) {
			const ps = this.psources[i];
			const data = AppCore.instance.game.inventory.get(ps.id);

			generateFloorEffects(ps.html.decor_effects, data.sprite);
			ps.draw(data, npcs, this.upgradeMulVariants[this.upgradeMulIndex]);
		}

		{
			const conf = AppCore.instance.game.inventory.stages[stage];
			const active = conf.active;
			const header = document.querySelector('#psource.header_office');
			header.classList.value = '';

			header.classList[active ? 'remove' : 'add']('unavailable');
			header.classList.remove('hidden');

			header.style.backgroundImage = `url(./res/img/itd/${conf.sprite}.jpg)`;
			header.classList.add('header_office', conf.sprite);
			generateFloorEffects(header.querySelector('decor#effects-container'), conf.sprite);

			const canbuy_label = header.querySelector('label#buy_all_prev_hint');
			if (stage === 0) {
				canbuy_label.classList.add('hidden');
			} else {
				const previousStage = AppCore.instance.game.inventory.stages[stage - 1];
				const result = AppCore.instance.game.inventory.psources.filter(psource => 
					psource.active && previousStage.id === psource.stageId
				);

				canbuy_label.classList[previousStage.psources.length !== result.length ? 'remove' : 'add']('hidden');
			}
		}

		{
			this.roof = document.querySelector('#psource.roof_office');
			this.roof.classList.value = '';

			this.roof.classList.remove('unavailable');
			this.roof.classList.remove('hidden');

			this.roof.style.backgroundImage = `url(./res/img/itd/roof.jpg)`;
			this.roof.classList.add('roof_office', 'roof');
			this.roof.parentNode.appendChild(this.roof);
		}

		{
			const totalstages = AppCore.instance.game.inventory.stages.length - 1;
			shell.elementVisible('button_location_next', stage < totalstages);
			shell.elementVisible('button_location_prev', stage > 0);
		}

		//this._loop();
	}

	resetCache() {
		for (const i in this.psources) {
			const ps = this.psources[i];
			ps.resetCache();
		}
	}

	_get_first_manager_floor() {
		const stage = AppCore.instance.selectedLocation;
		const battleid = AppCore.instance.game.itd.stageIndexToId(stage);
		const battle = AppCore.instance.game.battles[battleid];
		for (const i in this.psources) {
			const ps = this.psources[i];
			const data = AppCore.instance.game.inventory.get(ps.id);
			if (
				battle &&
				data.employee &&
				AppCore.instance.game.inventory.getItdItem(data.employee).property('health') > 0
			) {
				return i;
			}
		}
		return -1;
	}

	_draw_damage(dmg) {
		const firstManagerFloor = this._get_first_manager_floor();
		if (firstManagerFloor >= 0) {
			const rect = this.psources[firstManagerFloor].html.
				decor_employee.getBoundingClientRect();
			const offsetx = (Math.random() - 0.5) * (rect.width * 0.3);
			const offsety = (Math.random() - 0.5) * (rect.height * 0.3);
			const pos = { x: rect.left + offsetx, y: rect.top + rect.height / 2 + offsety };
			const efcts = AppCore.instance.itd5ka.shell.effects;
			efcts.spawnDamageNumbers(pos, dmg.dmg.toFixed(0), "employer");
		}
	}

	_draw_task_damage(args) {
		const dmg = args.dmg;
		const roomId = args.roomId;
		const dmg_type = args.dmg_type;
		const stage = AppCore.instance.selectedLocation;
		const firstManagerFloor = this._get_first_manager_floor();
		const battleid = AppCore.instance.game.inventory.stages[stage].id;
		const battle = AppCore.instance.game.battles[battleid];
		if (!battle) {
			return;
		}
		const room = battle.roomsById[roomId];
		if (room.tasks[0] && firstManagerFloor >= 0) {
			const ps_battle = this.psources[firstManagerFloor].battle[room.tasks[0].id];
			if (ps_battle) {
				const rect = ps_battle.entry.getBoundingClientRect();

				const offsetx = (Math.random() - 0.5) * (rect.width * 0.3);
				const offsety = (Math.random() - 0.5) * (rect.height * 0.3);
				AppCore.instance.itd5ka.shell.effects.spawnDamageNumbers(
					{ x: rect.left + offsetx, y: rect.top + rect.height / 2 + offsety },
					dmg.toFixed(0),
					dmg_type
				);
			}
		}
	}

	/**
	 * _loop. Application tick loop
	 *
	 */
	_loop() {
		const shell = AppCore.instance.itd5ka.shell;
		const stage = AppCore.instance.selectedLocation;
		const battleid = AppCore.instance.game.itd.stageIndexToId(stage);
		const battle = AppCore.instance.game.battles[battleid];
		const in_battle = battle ? true : false;

		let firstManagerFloor = -1;
		for (let i = 0; i < this.psources.length; i++) {
			const ps = this.psources[i];
			const data = AppCore.instance.game.inventory.get(ps.id);

			ps.drawProgressbar(data, in_battle);
			ps.drawUpgradeButton(data, this.upgradeMulVariants[this.upgradeMulIndex], in_battle);
			ps.drawLabels(data, in_battle);
			ps.drawBattle(data, battle);

			if (
				in_battle &&
				firstManagerFloor === -1 &&
				data.employee &&
				AppCore.instance.game.inventory.getItdItem(data.employee).property('health') > 0
			) {
				firstManagerFloor = i;
				if (!this.psources[i].element.classList.contains(CURRENTLY_IN_BATTLE))
					this.psources[i].element.classList.add(CURRENTLY_IN_BATTLE);
			} else if (battle && firstManagerFloor === -1) {
				if (!this.psources[i].element.classList.contains(DEFEATED_IN_BATTLE))
					this.psources[i].element.classList.add(DEFEATED_IN_BATTLE);
			} else {
				if (this.psources[i].element.classList.contains(CURRENTLY_IN_BATTLE))
					this.psources[i].element.classList.remove(CURRENTLY_IN_BATTLE);
				if (this.psources[i].element.classList.contains(DEFEATED_IN_BATTLE))
					this.psources[i].element.classList.remove(DEFEATED_IN_BATTLE);
			}
		}

		// === #draft 220517

		if (in_battle) {
			// --- manage battle mode
			if (!shell.html.container_psource.classList.contains(BATTLE_MODE))
				shell.html.container_psource.classList.add(BATTLE_MODE);
			
			// --- activate shield badge
			if (battle.cache.coffeeUseTimestamp > 0 && firstManagerFloor >= 0) {
				const duration = AppCore.instance.game.getConfig('game', 'coffee_effect_duration') * 1000;
				const shielded = battle.cache.coffeeUseTimestamp + duration > Date.now();
				const el = this.psources[firstManagerFloor].html.decor_employee;

				if (shielded && !el.classList.contains(SHIELDED)) {
						sound('coffee_on', 1, 0.5);
						el.classList.add(SHIELDED);
				} else if (!shielded && el.classList.contains(SHIELDED)) {
						sound('coffee_off');
						el.classList.remove(SHIELDED);
				}
			}

			// -- show coffee button
			if (firstManagerFloor >= 0) {
				this.cache.currentlyInBattleFloor = firstManagerFloor;
				this.psources[this.cache.currentlyInBattleFloor].element.classList.add(CURRENTLY_IN_BATTLE);
				this.psources[this.cache.currentlyInBattleFloor].html.button_coffee.dataset.cost =
					'' + AppCore.instance.game.actions.conf('use_coffee_cost', { battleid });
			}

			if (
				firstManagerFloor >= 0 &&
				this.cache.currentlyInBattleFloor >= 0 &&
				this.psources[this.cache.currentlyInBattleFloor].element.classList.contains(
					CURRENTLY_IN_BATTLE
				)
			) {
				const cost = config('use_coffee_cost', { battleid });
				const active =
					!this.psources[firstManagerFloor].html.decor_employee.classList.contains('shielded') &&
				AppCore.instance.game.actions.testSpend('coffee', cost);
				const coffeebtn = this.psources[this.cache.currentlyInBattleFloor].html.button_coffee;
				// coffeebtn.classList[active ? 'remove' : 'add']('disabled');
	
				const coffee = AppCore.instance.game.inventory.get('coffee').amount;
				coffeebtn.classList[(active && coffee > 0) ? 'remove' : 'add']('disabled');
			}
	
			// === scroll to battle
			if (firstManagerFloor >= 0 && this.cache.autoscrollPos !== firstManagerFloor) {
				this.autoscrollToFloor(firstManagerFloor);
			}
		} else {
			// --- manage battle mode
			if (shell.html.container_psource.classList.contains(BATTLE_MODE))
				shell.html.container_psource.classList.remove(BATTLE_MODE);
		}

		// === manage coffee button
		// --- hide coffee button
		if (
			this.cache.currentlyInBattleFloor >= 0 &&
			(!in_battle || firstManagerFloor !== this.cache.currentlyInBattleFloor)
		) {
			this.psources[this.cache.currentlyInBattleFloor].element.classList.remove(
				CURRENTLY_IN_BATTLE
			);
			this.cache.currentlyInBattleFloor = -1;
		}
	}

	removeEmployeesShield() {
		for (let i = 0; i < this.psources.length; i++) {
			this.psources[i].html.decor_employee.classList['remove'](
				'shielded'
			);
		}
	}

	autoscrollToFloor(index) {
		this.cache.autoscrollPos = index;
		const height = this.psources[index].element.clientHeight;
		const pixelpos = (this.psources.length - index - 1) * height;
		autoScrollTo(-pixelpos + height / 2);
	}

	/**
	 * .
	 */
	_battleButtonClick() {
		this.cache.autoscrollPos = -1;
		let allPsourcesPurchased = true;
		let oneManagerPlaced = false;

		const stage = AppCore.instance.selectedLocation;
		const psources = AppCore.instance.game.inventory.psources;
		for (const i in psources) {
			const ps = psources[i];
			if (ps.stage !== stage) {
				continue;
			}

			if (!ps.active) {
				allPsourcesPurchased = false;
			}

			if (ps.employee) {
				oneManagerPlaced = true;
			}
		}

		if (window.globalsettings.devmode || (allPsourcesPurchased && oneManagerPlaced)) {
			viewPush(new BattlePrepareView(stage));

			this.get_damage = AppCore.instance.events.on('td_battle_get_damage', this._draw_damage, this).func;
			this.task_damage = AppCore.instance.events.on('td_battle_task_damage', this._draw_task_damage, this).func;
		} else {
			AppCore.instance.sounds.stop('click');
			AppCore.instance.sounds.play('error');
			viewPush(
				new MessageView(
					'! ! !',
					`
					<container class='battle-warn-message'>
						<ul>
							<li ${allPsourcesPurchased ? 'checked' : ''}>Необходимо приобрести все этажи этого офиса.</li>
							<li ${oneManagerPlaced ? 'checked' : ''}>Необходимо назначить хотя бы одного менеджера.</li>
						</ul>
					</container>
					`
				)
			);
		}
	}
}
