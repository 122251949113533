/**
 * @File RewardView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import AppCore from '../AppCore.js';
import ViewUiBase from './ViewUiBase.js';
import OfficeView from './OfficeView.js';
import { sound } from '../shorthands.js';

/**
 *	.
 */
export default class RewardView extends ViewUiBase {
	static elements = [];

	constructor(lootpackName = 'lootpack-0') {
		super();

		this.lootpackName = lootpackName;

		this;
	}
	/**
	 * @param {string} key click element key
	 * @param {oject} args click arguments
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
			case 'claim_reward':
				e.stopPropagation();
				AppCore.instance.views.pop();
				break;
			case 'lootpack':
				if (this.displayRewardState === 0) {
					this.setState(1);
					AppCore.instance.ticker.timeout(() => this.setState(2), 500);
				}
				break;
			default:
				break;
		}
	}

	/**
	 * show.
	 */
	show() {
		const shell = AppCore.instance.itd5ka.shell;
		this.container = shell.popup('itd_reward');

		this.setState(0);

		const itemscontainer = this.container.querySelector('tabs#content');
		//itemscontainer.innerHTML = '';

		for (const e in RewardView.elements) {
			const element = RewardView.elements[e];
			itemscontainer.removeChild(element);
		}
		RewardView.elements.splice(0);
		const entry_template = itemscontainer.querySelector('entry#item_entry.reward_template');

		let index = 0;
		for (const k in AppCore.instance.game.inventory.dust.content) {
			const drop = AppCore.instance.game.inventory.dust.content[k];
			const item = AppCore.instance.game.inventory.get(k);

			const itemWrapper = AppCore.instance.game.inventory.getItdItem(k);

			let pbarWidth = 0;
			let amount = 0;
			let cost = 0;
			if (itemWrapper.level) {
				amount = item.amount;
				cost = item.levels[itemWrapper.level] ?? null;
				pbarWidth = cost !== null ? (amount / cost) * 100 : 100;
			}

			let rarity = itemWrapper.property('rarity');
			let rarity_value = 'default';
			if (rarity) {
				const args = rarity.split(',');
				rarity_value = args[0] == '' ? rarity_value : args[0];
				rarity = `rarity ${rarity_value}`;
			} else {
				rarity = `rarity ${rarity_value}`;
			}

			const progressbarText = cost !== null ? `${item.amount}/${cost}` : 'MAX';

			const displaybar = typeof itemWrapper.level !== 'undefined';

			const entry = entry_template.cloneNode(true);
			RewardView.elements.push(entry);
			itemscontainer.appendChild(entry);
			entry.classList.remove('inv_template', 'hidden');
			entry.classList.value = `item_rarity ${rarity_value}`;
			const icon = entry.querySelector('icon');
			const level = entry.querySelector('label#level');
			const amountel = entry.querySelector('label#amount');
			const exp = entry.querySelector('progressbar#exp_bar');
			const rar = entry.querySelector('label#rarity');

			entry.setAttribute('data-itemid', item.id);
			level.innerText = `ур.${itemWrapper.property('level')}`;
			if (!displaybar) level.classList.add('hidden');
			amountel.innerText = `+${AppCore.instance.game.formatGameNumber(drop.amount)}`;
			icon.classList.value = `textures-${item.icon}`;
			exp.innerText = `★${progressbarText}`;
			exp.style.setProperty('--progress', `${pbarWidth}%`);
			if (!displaybar) exp.classList.add('hidden');
			rar.classList.value = rarity;
			rar.innerText = `${item.name}`;

			/*itemscontainer.innerHTML += `
					<entry class='item_rarity ${rarity}' id='item_entry' data-itemid='${item.id}'>
						<icon class='textures-${item.icon}'>
							<label id=level class='${displaybar ? '' : 'hidden'}'>ур.${itemWrapper.property('level')}</label>
							<label id=amount>+${AppCore.instance.game.formatGameNumber(drop.amount)}</label>
						</icon>
						<div class='amount_container'>
							<progressbar class='${displaybar ? '' : 'hidden'}'>${progressbarText}</progressbar>
						</div>
						<label class=${rarity}>${item.name}</label>
					</entry>
					`;*/

			entry.style.setProperty('--delay', `${index++ * 0.3}s`);
			if (!displaybar) amountel.style.setProperty('top', '4px');
			exp.style.setProperty('--progress', `${pbarWidth}%`);
		}
	}

	setState(index) {
		this.displayRewardState = index;
		this.container.classList.value = `state-${index}`;
		const shell = AppCore.instance.itd5ka.shell;
		shell.elementVisible('button_close_popup', index > 0);

		const chesticon = this.container.querySelector('icon#lootpack');
		chesticon.classList.value = '';

		switch (index) {
			case 0:
				sound('success');
				chesticon.classList.add(`textures-${this.lootpackName}`);
				break;
			case 1:
				sound('chest');
				chesticon.classList.add(`textures-${this.lootpackName}-open`);
				break;
			case 2:
				sound('coins', 0.8);
				sound('happy');
				break;
		}
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
		AppCore.instance.itd5ka.shell.elementVisible('button_close_popup', true);
	}
}
