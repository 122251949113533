/**
 * @file Ticker.js
 * @author tynrare
 * @version 1
 * @module Core
 */

import events from './events.js';
import logger from './logger.js';

/**
 * Ticker. Manages ticks, loops, delays
 */
export default class Ticker {
	/**
	 * constructor.
	 */
	constructor() {
		this.timeScale = 1;
		this.events = events;

		this.active = false;
		this.refDt = 33;
		this.lastFrame = 0;
		this.guid = 0;
		this.timeouts = {};
		this.intervals = {};

		this._scopedTickFunc = this._tick.bind(this);
	}

	/**
	 * init.
	 *
	 * @returns {Ticker} this
	 */
	init() {
		return this;
	}

	/**
	 * run. Starts loop tick
	 */
	run() {
		this.active = true;
		this.lastFrame = 0;
		requestAnimationFrame(this._scopedTickFunc);

		return this;
	}

	/**
	 * stop. Stops loop tick
	 */
	stop() {
		this.active = false;
	}

	/**
	 * _tick.
	 *
	 * @private
	 * @param {Number} timestamp current time
	 */
	_tick(timestamp) {
		if (!this.active) {
			return;
		}

		requestAnimationFrame(this._scopedTickFunc);

		const currFrame = Date.now();
		const dt = (currFrame - this.lastFrame) * this.timeScale;
		const dtf = (dt / this.refDt) * this.timeScale;

		// Main loop event

		if (dtf >= 1) {
			try {
				this.events.emit('game_tick', dt, dtf, timestamp);
			} catch (err) {
				this.stop();
				logger.group('CORE_WARNS', 'Ticker.game_tick error: ', err);
			}

			this._triggerDelays(currFrame);
			this._triggerIntervals(currFrame);

			this.lastFrame = currFrame;
		}
	}

	/**
	 * _triggerDelays.
	 *
	 * @private
	 * @param {number} timestamp .
	 */
	_triggerDelays(timestamp) {
		try {
			for (const k in this.timeouts) {
				const t = this.timeouts[k];
				if (timestamp >= t.timeout) {
					this.timeoutStop(k);
					const dt = timestamp - t.timestamp;
					t.callback(dt, dt / t.delay, timestamp);
				}
			}
		} catch (err) {
			logger.group('CORE_WARNS', 'Ticker.timeouts error: ', err);
		}
	}

	/**
	 * @private
	 * @param {number} timestamp .
	 */
	_triggerIntervals(timestamp) {
		try {
			for (const k in this.intervals) {
				const t = this.intervals[k];
				if (timestamp >= t.timeout) {
					const dt = timestamp - t.timestamp;

					t.timeout = timestamp + t.interval;
					t.timestamp = timestamp;

					t.callback(dt, dt / t.interval, timestamp);
				}
			}
		} catch (err) {
			logger.group('CORE_WARNS', 'Ticker.intervals error: ', err);
		}
	}

	/**
	 * timeout.
	 *
	 * @param {Function} callback .
	 * @param {number} delay timeout delay ms
	 */
	timeout(callback, delay) {
		const timestamp = this.lastFrame;
		const id = 'i' + this.guid++;

		this.timeouts[id] = {
			timeout: timestamp + delay,
			timestamp,
			delay,
			callback
		};

		return id;
	}

	timeoutStop(id) {
		delete this.timeouts[id];
	}

	/**
	 * interval.
	 *
	 * @param {Function} callback .
	 * @param {number} interval timeout delay ms
	 * @returns {string} interval id
	 */
	interval(callback, interval) {
		const timestamp = this.lastFrame;
		const id = 'i' + this.guid++;
		this.intervals[id] = {
			interval,
			timestamp,
			timeout: timestamp + interval,
			callback
		};

		return id;
	}
}
