/**
 * Based on https://github.com/davidbau/seedrandom
 *
 * @File Random.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Core
 */

import Alea from './Alea.js';

let instance = null;

/**
 * Класс рандомайза
 */
class Random {
	constructor() {
		this.storedSeeds = {};
	}

	/**
	 * instance() shorthand for getRandomizer
	 *
	 * @param {string} seed seed генерации
	 * @returns {number} случайное число 0-1
	 */
	get(seed) {
		const rnd = this.getRandomizer(seed);

		return rnd.next();
	}

	/**
	 * @param {string} seed seed for random
	 * @param {object} [options] опции
	 * @param {boolean} [options.clearcache] очистить ли результаты прошлых вызовов с этим сидом
	 *
	 * @returns {Alea} функция рандома.
	 */
	getRandomizer(seed, { clearcache = false } = {}) {
		//clear old cache
		if (clearcache) {
			this.storedSeeds[seed] = null;
		}
		if (!this.storedSeeds[seed]) {
			this.storedSeeds[seed] = new Alea(seed);
		}

		return this.storedSeeds[seed];
	}

	/**
	 * @returns {Random} instance
	 * @static
	 * @public
	 */
	static getInstance() {
		if (!instance) {
			instance = new Random();
		}

		return instance;
	}

	/**
	 * getInstance() shorthand
	 *
	 * @returns {object} instance
	 * @static
	 */
	static get instance() {
		return this.getInstance();
	}
}

export default Random;
