/* eslint-disable no-unused-vars */
import AppCore from './AppCore.js';
import { ACTION_CODES } from './tovdef/GameActions.js';
import { ViewBase } from '../core/views.js';

/**
 * Shorthand for major function for changing game state.
 *
 * @param {string} type action type
 * @param {object} [args] action arguments
 * @param {boolean} [silent=false] make action without events emitting
 * @returns {ACTION_CODES} .
 */
export function action(type, args = {}, silent = false) {
	const result = AppCore.instance.game.actions.action(type, args, silent);

	return result;
}

/**
 * Returns some config value
 *
 * @param {string} type .
 * @param {object} [args] custom args
 * @returns {number} .
 */
export function config(type, args) {
	return AppCore.instance.game.actions.conf(type, args);
}

/**
 * Adds view in top of stack
 *
 * @param {ViewBase} view to push
 */
export function viewPush(view) {
	AppCore.instance.views.push(view);
}

/**
 * Gets data from inventory
 *
 * @param {string} id id of data
 * @returns {object} .
 */
export function inventoryGet(id) {
	return AppCore.instance.game.inventory.get(id);
}

/**
 * @param {number} stage .
 * @returns {object} BattleManager
 */
export function getBattleByStageIndex(stage) {
	const battleid = AppCore.instance.game.itd.stageIndexToId(stage);
	const battle = AppCore.instance.game.battles[battleid];

	return battle;
}

/**
 * Seeded random
 *
 * @returns {number} 0-1
 */
export function random() {
	return AppCore.instance.game.random();
}

/**
 *
 * @param {string} text new text for header
 */
export function setHeaderTitle(text) {
	//document.getElementById('td_header_title').innerHTML = text;
	AppCore.instance.itd5ka.shell.print(text, 'itd_status');
}

/**
 * plays sound
 *
 * @param {string} name sound name
 * @param {number} [volume=1] sound volume
 * @param {number} [themeFade=0] 0-1 fades theme
 */
export function sound(name, volume = 1, themeFade = 0) {
	AppCore.instance.sounds.play(name, volume, themeFade);
}
