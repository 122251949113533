/**
 * Handles used and other actions
 */
export default class ItdRelicsModifiers {
	/**
	 * @param {object} raw
	 * @param {GameCore} core .
	 */
	constructor(raw, core) {
		this.raw = raw;
		this.core = core;
	}

	/**
	 *
	 * @returns {RelicsModifiers} .
	 */
	init() {
		this.cache = {};

		const rawintory = this.core.inventory.raw.content;
		for (const k in rawintory) {
			const item = rawintory[k];
			if (item.type === 'relic') {
				this.cache[k] = item;
			}
		}

		this._effectFilters = {
			psource: filter_Psource,
			employee: filter_Employee,
			gadget: filter_Gadget
		};

		return this;
	}

	/**
	 * Читай "Реликвии" в ГДД.
	 * #draft
	 *
	 * @param {string} itemid
	 * @param {string} effect
	 * @returns {number} multiplier factor depending on game state and tons of in config fields
	 */
	calc(itemid, effect) {
		let factor = 1;

		const item = this.core.inventory.getItdItem(itemid);

		if (!item) {
			return factor;
		}

		for (const k in this.cache) {
			const relic = this.cache[k];

			// A. Relic activated
			if (!relic.active) {
				continue;
			}

			// B. Relics scope fits item type
			if (relic.effectScope !== item.raw.content.type) {
				continue;
			}

			// C. Relics filter fits filter rules
			const filter = this._effectFilters[relic.effectScope];
			if (!filter(item, relic.effectFilter)) {
				continue;
			}

			// D. Relics effect fits requested effect
			if (relic.effectType !== effect) {
				continue;
			}
			const reliccard = this.core.inventory.getItdItem(k);

			factor *= reliccard.property('effectValue');
		}

		return factor;
	}
}

/**
 * @param {ItdItemCard} item itd item
 * @param {string} filter relic filter
 * @returns {Boolean}
 */
function filter_Psource(item, filter) {
	if (!filter || !filter.length) {
		return true;
	}

	return item.raw.content.stageId === filter;
}

/**
 * @param {ItdItemCard} item itd item
 * @param {string} filter relic filter
 * @returns {Boolean}
 */
function filter_Employee(item, filter) {
	if (!filter || !filter.length) {
		return true;
	}

	return item.raw.content.rarity === filter;
}

/**
 * @param {ItdItemCard} item itd item
 * @param {string} filter relic filter
 * @returns {Boolean}
 */
function filter_Gadget(item, filter) {
	if (!filter || !filter.length) {
		return true;
	}

	return item.raw.content.narrativeType === filter;
}
