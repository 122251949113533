/**
 * @File Inventory.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import {
	constructStandardRawData as csrd,
	StandardRawData
} from '../../core/standart_data_helpers.js';
import Decimal from 'decimal.js';
import ItdItemCard from './ItdItemCard.js';
import math from '../../core/mathjs';

const MAX_FORMATTED_NUMBER_LENGTH = 6;

/**
 * tovar defense game logic core
 */
export default class Inventory {
	/**
	 * @param {StandardRawData} raw .
	 * @param {GameCore} core .
	 */
	constructor(raw, core) {
		this.raw = raw;
		this.core = core;
		this.psources = [];
		this.stages = [];

		this.dust = csrd('dust');
		this.cache = {
			csrdWrappers: {}
		};
	}

	/**
	 * @returns {Inventory} .
	 */
	init() {
		this.cache.csrdWrappers = {};

		for (const k in this.raw.content) {
			const item = this.raw.content[k];
			if (item.type === 'psource') {
				this.psources.push(item);
			} else if (item.type === 'stage') {
				this.stages.push(item);
			}
		}

		this.psources.sort((a, b) => {
			return a.index - b.index;
		});

		this.stages.sort((a, b) => {
			return a.index - b.index;
		});

		return this;
	}

	/**
	 * Gets data from inventory
	 *
	 * @param {string} id id of data
	 * @returns {object} .
	 */
	get(id) {
		return this.raw.content[id];
	}

	/**
	 * Gets "item card" object from inventory
	 *
	 * @param {string} id id of data
	 * @returns {ItdItemCard} .
	 */
	getItdItem(id) {
		const rawitem = this.get(id);
		if (!rawitem) {
			return null;
		}

		let item = this.cache.csrdWrappers[id];
		if (!item) {
			item = this.cache.csrdWrappers[id] = new ItdItemCard(csrd(id, rawitem), this.core);
		}

		return item;
	}

	/**
	 * @param {string} id .
	 * @returns {string} formatted string
	 */
	getFormattedAmount(id) {
		const item = this.get(id);

		return this.core.formatGameNumber(item.amount);
	}

	/**
	 * Sets data amount in eventory
	 *
	 * @param {string} id .
	 * @param {number} amount .
	 */
	set(id, amount) {
		const item = this.get(id);
		if (item.amount instanceof Decimal) {
			item.amount = new Decimal(amount);
		} else {
			item.amount = amount;
		}
	}

	/**
	 * Add data amount in eventory
	 *
	 * @param {string} id .
	 * @param {number} amount .
	 */
	add(id, amount) {
		const item = this.get(id);
		
		if (item.amount instanceof Decimal) {
			item.amount = item.amount.add(amount);
		} else {
			item.amount += amount;
		}
	}

	/**
	 * Removes all data from dust object
	 */
	cleanDust() {
		for(const k in this.dust.content) {
			delete this.dust.content[k]
		}
	}

	/**
	 * Removes all data from dust object
	 *
	 * @param {object} data .
	 * @param {string} [id] .
	 */
	addToDust(data, id) {
		const _id = id || 'i' + this.dust.meta.guids++;
		this.dust.content[_id] = data;
	}
}
