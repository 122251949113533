import { sec } from 'mathjs';
import AppCore from '../AppCore.js';

/**
 * @returns {string} string elements
 */
export function getDustDataElements() {
	let str = '';
	for (const k in AppCore.instance.game.inventory.dust.content) {
		const item = AppCore.instance.game.inventory.dust.content[k];

		str += `<a>${k}: x${item.amount}</a></br>`;
	}

	return str;
}

export function playAnimation(element, name, duration) {
	element.classList.add(name);
	setTimeout(() => {
		element.classList.remove(name);
	}, duration);
}

export function delayedAction(action, duration) {
	setTimeout(() => {
		action();
	}, duration);
}

/**
 * @param {number} a initial value
 * @param {number} b target value
 * @param {number} t factor
 * @returns {number} .
 */
export function lerp(a, b, t) {
	return a + t * (b - a);
}

export function clamp(v, min, max) {
	return Math.min(Math.max(v, min), max);
}

const timeLetters = ['ч', 'м', 'с'];
/**
 * #GC-WARN
 *
 * @param {number} seconds time in seconds
 * @returns {string} formatted time
 */
export function formatTime(seconds) {
	const d = new Date(seconds * 1000);
	const s = d.getUTCSeconds();
	const m = d.getUTCMinutes();
	const h = d.getUTCHours();

	const t = [h, m, s];
	let str = '';
	for (const i in t) {
		if (t[i]) {
			str += `${t[i]}${timeLetters[i]} `;
		}
	}

	return str;
}

const MAX_ASSIGNED_NAME_LENGTH = 7;

export function formatAssignedName(assignedName) {
	if (assignedName.length > MAX_ASSIGNED_NAME_LENGTH) {
		assignedName = assignedName.substring(0, MAX_ASSIGNED_NAME_LENGTH) + '.';
	}

	return assignedName;
}

/* eslint-disable max-lines-per-function, max-statements, complexity */
const cache = {
	pos: 0,
	mouseEventId: null,
	tickEventId: null,
	scrollEl: null,
	active: true,
	listenResize: null,
	listenMouse: true,
	forceScrollTo: null
};

export function setScrollPos(pos) {
	if (!cache.active) {
		return;
	}

	const paddingTotal = 40;
	const maxScroll = -Math.max(
		0,
		cache.scrollEl.clientHeight - cache.scrollEl.parentNode.clientHeight + paddingTotal
	);
	cache.pos = Math.max(maxScroll, Math.min(Math.min(window.innerWidth / 1280 * 0.8, 1) * 230 * 
		AppCore.instance.itd5ka.shell.iphoneNotch, pos));
	cache.scrollEl.style.transform = `translateY(${cache.pos}px)`;
}

export function getScrollPos() {
	return cache.pos;
}

/**
 * .
 */
export function listenScrollEvents() {
	cache.active = true;
	cache.listenMouse = true;
	cache.forceScrollTo = null;
	cache.scrollEl = document.querySelector('itd5ka#psources.container');

	cache.pos = 0;
	let dragStartY = cache.pos;
	let lastDelta = 0;
	let dragging = false;
	cache.mouseEventId = AppCore.instance.inputs.on(
		'game_input_mouse',
		({ event, input, delta, velocity, dragDuration }) => {
			if (cache.forceScrollTo !== null || !cache.listenMouse) {
				return;
			}
			switch (input) {
				case 'wheel':
					setScrollPos(cache.pos + event.wheelDeltaY);
					break;
				case 'down':
					lastDelta = 0;
					dragging = true;
					cache.scrollEl.classList.add('disable_transition');
					dragStartY = cache.pos;
					break;
				case 'up':
					dragging = false;
					lastDelta = lastDelta * 2;
					cache.scrollEl.classList.remove('disable_transition');
					dragStartY = cache.pos;
					break;
				case 'drag':
					setScrollPos(dragStartY + delta.y);
					lastDelta = Math.max(Math.abs(velocity.y), Math.abs(lastDelta)) * Math.sign(velocity.y);
					break;
				default:
					break;
			}
			//console.log(input, lastDelta);
		},
		this
	);

	const frictionThreshold = 2;
	cache.tickEventId = AppCore.instance.ticker.events.on('game_tick', () => {
		lastDelta = lerp(lastDelta, 0, dragging ? 0.5 : 0.05);

		// autoscroll
		if (cache.forceScrollTo !== null) {
			const newpos = lerp(cache.pos, cache.forceScrollTo, 0.2);
			const oldpos = cache.pos;
			setScrollPos(newpos);
			if (Math.abs(oldpos - cache.pos) < 1) {
				cache.forceScrollTo = null;
			}
			// drag friction scroll
		} else if (!dragging && Math.abs(lastDelta) > frictionThreshold) {
			setScrollPos(cache.pos + lastDelta);
		}
	});

	setScrollPos(Number.MIN_SAFE_INTEGER);

	if (!cache.listenResize) {
		let threshold = true;

		cache.listenResize = window.addEventListener('resize', () => {
			if (cache.active && threshold) {
				setScrollPos(cache.pos);
				threshold = false;
				requestAnimationFrame(() => {
					threshold = true;
				});
			}
		});
	}
}

export function holdScrollEvents(hold) {
	cache.active = !hold;
	setScrollPos(cache.pos);
}

export function holdMouseEvents(hold) {
	cache.listenMouse = !hold;
}

export function autoScrollTo(pos) {
	cache.forceScrollTo = pos;
}

export function unlistenScrollEvents() {
	cache.active = false;
	if (cache.mouseEventId) AppCore.instance.inputs.off('game_input_mouse', cache.mouseEventId.func);
	if (cache.tickEventId) AppCore.instance.ticker.events.off('game_tick', cache.tickEventId.func);
}
/* eslint-enable max-lines-per-function, max-statements, complexity */
