import AppCore from '../AppCore';
import GameCore from '../GameCore';
import { MAX_COFFEE } from '../tovdef/GameActions.js';
import { action } from '../shorthands';
import { storage } from '../../js-client-utils/lib/storage.js';

/**
 * Resets app
 */
export function setInitialState() {
	AppCore.instance.events.cleanup();
	AppCore.instance.game = new GameCore();
	AppCore.instance.game.init();
	AppCore.instance.views.dispose();
	AppCore.instance.pushNewRootView();
	AppCore.instance.ticker.events.on('game_tick', AppCore.instance._loop, AppCore.instance);
}

/* eslint-disable complexity, max-statements */
/**
 * .
 */
function setDevPreset1() {
	const inventory = AppCore.instance.game.inventory;
	const CHEAT_AMOUNT = 9999999999999999;
	inventory.add('gold', CHEAT_AMOUNT);
	inventory.add('gems', CHEAT_AMOUNT);
	const coffee = AppCore.instance.game.inventory.get('coffee');
	inventory.add('coffee', MAX_COFFEE - coffee.amount);

	// Psources
	for (let i = 8; i > 0; i--) {
		action('purchase_psource');
	}

	// Employees
	let assignedEmployee = null;
	let assignedGadget = null;
	for (const k in inventory.raw.content) {
		const item = inventory.raw.content[k];

		if (item.type !== 'employee' && item.type !== 'gadget') {
			continue;
		}

		item.active = true;
		item.amount = 1;
		if (item.type === 'employee' && !assignedEmployee) {
			assignedEmployee = k;
			action('assign_item', { type: 'employee', itemid: k, roomid: inventory.psources[0].id });
		}

		if (item.type === 'gadget' && !assignedGadget) {
			assignedGadget = k;
			action('assign_item', { type: 'gadget', itemid: k, roomid: inventory.psources[0].id });
		}
	}

	console.log('emit redraw_offices');
	AppCore.instance.ticker.events.emit('redraw_offices');
}

/**
 * .
 */
 function setDevResourcesPreset1() {
	const inventory = AppCore.instance.game.inventory;
	const CHEAT_AMOUNT = 999999999999999999999999999;
	inventory.add('gold', CHEAT_AMOUNT);
}

/**
 * .
 */
 function setDevResourcesPreset2() {
	const inventory = AppCore.instance.game.inventory;
	const CHEAT_AMOUNT = 999999999999999999999999999;
	inventory.add('gems', CHEAT_AMOUNT);
}

/**
 * .
 */
 function setDevResourcesPreset3() {
	const inventory = AppCore.instance.game.inventory;
	const coffee = inventory.get('coffee');
	inventory.add('coffee', MAX_COFFEE - coffee.amount);
}
/* eslint-enable complexity, max-statements */

/**
 * .
 */
 function setDevNewUser() {
	storage.clear();
	document.location.reload();
}
/* eslint-enable complexity, max-statements */

const presetsFunctions = [setInitialState, setDevPreset1, setDevResourcesPreset1, setDevNewUser,
	setDevResourcesPreset2, setDevResourcesPreset3];

/**
 * Sets game state to harcoded preset
 *
 * @param {number} index preset index
 */
export function setDevPreset(index) {
	presetsFunctions[index]();
}
