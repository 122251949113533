import AppCore from '../AppCore.js';
import { lerp, clamp } from './ui_utils.js';

/**
 * #draft
 */
export default class TabsManager {
	static selectedTabId = null;
	static selectedTabScroll = 0;

	/**
	 * constructor.
	 */
	constructor() {
		this.buttons = {};
		this.tabs = {};
		this.selectedTab = null;
		this.scrollDragEventId = null;
	}

	/**
	 * init.
	 *
	 * @param {HTMLElement} containerEl .
	 * @param {HTMLElement} contentEl .
	 */
	init(containerEl, contentEl) {
		this.containerEl = containerEl;
		this.contentEl = contentEl;

		for (let i = 0; i < this.containerEl.childNodes.length; i++) {
			const ch = this.containerEl.childNodes[i];
			this.buttons[ch.id] = ch;
			ch.classList.remove('selected');
		}

		for (let i = 0; i < this.contentEl.childNodes.length; i++) {
			const ch = this.contentEl.childNodes[i];
			this.tabs[ch.id] = ch;
			ch.classList.add('hidden');
		}

		const ids = Object.keys(this.buttons);
		ids.forEach((k) => {
			this.buttons[k].addEventListener('click', () => {
				this.selectTab(k);
				TabsManager.selectedTabId = k;
				TabsManager.selectedTabScroll = 0;
				this.selectedTab.style.marginTop = 0 + 'px';
			});
		});

		this.selectTab(ids[0]);

		let scrollAreaRect = null;
		let scroll = TabsManager.selectedTabScroll;
		let lastDelta = 0;
		let scrollVector = 0;
		let dragging = false;
		let dragStartY = TabsManager.selectedTabScroll;
		this.scrollDragEventId = AppCore.instance.inputs.events.on('game_input_mouse',
			({ event, input, delta, velocity, dragDuration }) => {
			let ev = event.event;
			if (!event.event) {
				ev = event;
			}

			scrollAreaRect = this.selectedTab.parentElement.getBoundingClientRect();
			const inBounds =
				ev.clientX > scrollAreaRect.x &&
				ev.clientX < scrollAreaRect.x + scrollAreaRect.width &&
				ev.clientY > scrollAreaRect.y &&
				ev.clientY < scrollAreaRect.y + scrollAreaRect.height;

			switch (input) {
				case 'down':
					dragging = true;
					dragStartY = scroll;

					if (!inBounds) {
						scrollAreaRect = null;
					}

					lastDelta = 0;
					scrollVector = 0;
					break;

				case 'drag':
					if (!scrollAreaRect) {
						dragging = false;
						break;
					}
				/* falls through */
				case 'wheel': {
					if (input === 'drag' || input === 'wheel') {
						if (delta) {
							lastDelta = delta.y;
						} else {
							lastDelta = 0;
						}
						if (input === 'drag') {
							/*lastDelta = Math.max(Math.abs(velocity.y) * dragDuration, Math.abs(lastDelta))
								* Math.sign(velocity.y) * 0.05;*/
							if (scrollAreaRect) {
								lastDelta = delta.y;
							}
							//lastDelta = Math.max(Math.abs(velocity.y) * 0.01, Math.abs(lastDelta) * 0.01) 
							//	* Math.sign(velocity.y);
						}
					} else {
						lastDelta = -event.deltaY;
					}

					let beforeScrollPosition = TabsManager.selectedTabScroll;
					this.scrollTab(dragStartY + lastDelta);
					scrollVector = TabsManager.selectedTabScroll - beforeScrollPosition;

					if (event.input === 'drag') {
						break;
					}
				}
				/* falls through */
				case 'up':
					if (input === 'up' && inBounds) {
						dragging = false;
					}
					// scroll = clamp(
						// dragStartY + lastDelta,
						// -(this.selectedTab.clientHeight - this.contentEl.clientHeight),
						// 0
					// );
					break;
			}
		});

		this.tickEventId = AppCore.instance.ticker.events.on('game_tick', () => {
			if (!dragging && Math.abs(scrollVector) > 0.1) {
				this.scrollTab(scroll + scrollVector);
				scrollVector *= 0.95;				
			}
		});

		this.scrollTab = function(s) {
			scroll = clamp(s, -(this.selectedTab.clientHeight - this.contentEl.clientHeight), 0);
			this.selectedTab.style.marginTop = scroll + 'px';
			TabsManager.selectedTabScroll = scroll;
		};
	}

	/**
	 * selectTab.
	 *
	 * @param {string} id .
	 */
	selectTab(id) {
		let keys = Object.keys(this.buttons);
		if (keys.indexOf(id) < 0) id = keys[0];

		this.restoreInitial();
		this.buttons[id].classList.add('selected');
		this.tabs[id].classList.remove('hidden');
		this.selectedTab = this.tabs[id];
	}

	/**
	 * hideAllContent.
	 */
	restoreInitial() {
		for (const k in this.buttons) {
			this.tabs[k].classList.add('hidden');
			this.buttons[k].classList.remove('selected');
		}
	}

	/**
	 * restoreSelectedTab.
	 */
	 restoreSelectedTab() {
		if (TabsManager.selectedTabId != null) {
			this.selectTab(TabsManager.selectedTabId);
			this.selectedTab.style.marginTop = TabsManager.selectedTabScroll + 'px';
		}
	}

	/**
	 * restoreSelectedTab.
	 */
	 resetSelectedTab() {
		TabsManager.selectedTabId = null;
		TabsManager.selectedTabScroll = 0;
		const ids = Object.keys(this.buttons);
		this.selectTab(ids[0]);
		this.selectedTab.style.marginTop = 0 + 'px';
	}

	dispose() {
		if (this.scrollDragEventId != null) {
			AppCore.instance.inputs.events.off('game_input_mouse', this.scrollDragEventId.func);
			this.scrollDragEventId = null;
			AppCore.instance.inputs.events.off('game_tick', this.tickEventId.func);
			this.tickEventId = null;
		}
	}
}
