/* eslint-disable max-statements, complexity, max-lines-per-function */
import Decimal from 'decimal.js';
import logger from '../core/logger.js';

export const SAVE_SERIALIZE_FILTER = {
	"newstats": {
		maxFloorLevel: true,
		resetsTriggered: true,
		sprintsLaunchedAtSameTime: true,
		totalProfitPerSec: true,
		totalSprintsCompleted: true,
		lootpacksPurchased: true,
		relicsPurchased: true
	},
	"office_": {
		id: true,
		active: true,
		level: true,
		timestamp: true,
		employee: true,
		gadget: true,
		thresholdLevel: true,
		threshold: true,
		rankLevel: true
	},
	"location_": {
		active: true,
		auditsCompleted: true,
		auditsAvailable: true,
		id: true,
		pactive: true
	},
	"eduard_uspehow": {
		active: true,
		assigned: true,
		dust_assignedPvp: true,
		id: true,
		amount: true,
		injuries: true,
		attackTimespamp: true,
		storylinesCompleted: true,
		threshold_bonus: true,
		weight: true
	},
	"dima_nadeshni": "eduard_uspehow",
	"vika_tvorcheskaya": "eduard_uspehow",
	"alena_smehova": "eduard_uspehow",
	"ivan_prodavcov": "eduard_uspehow",
	"emp": "eduard_uspehow",
	"gd": {
		id: true,
		active: true,
		assigned: true,
		dust_assignedPvp: true,
		amount: true,
		chargesUsed: true,
		weight: true
	},
	"relic_": {
		id: true,
		active: true,
		level: true
	},
	"gold": {
		id: true,
		weight: true,
		amount: true
	},
	"gems": "gold",
	"coffee": "gold",
	"story_frame_": {
		id: true,
		completed: true,
		started: true,
		status: true
	},
	"story_task_": {
		id: true,
		completed: true,
		collected: true,
		status: true
	},
	"pvp_": {
		id: true,
		index: true,
		employee: true,
		gadget: true
	}
};
/**
 * Propagetes config data into inventory
 *
 * @param {object} inventory .
 * @param {object} config .
 */
export function constructInventoryData(inventory, config) {
	// { --- validation --------------------------------

	if (config.game['-']) {
		logger.group('GAME_RES_ERROR', `Ошибка загрузки конфига #1.1: ищи инструкцию в документации`);
		return;
	}

	if (typeof config.game.dev_test_array_export !== 'object') {
		logger.group('GAME_RES_ERROR', `Ошибка загрузки конфига #1.2: ищи инструкцию в документации`);
	}

	// } --- validation --------------------------------

	inventory.getSerialized = function() {		
		let serialized = {};
		const fkeys = Object.keys(SAVE_SERIALIZE_FILTER);
		for (const k in this) {
			const index = fkeys.findIndex(fk => k.indexOf(fk) === 0);
			if (index !== -1) {
				serialized[k] = {};
				let key = fkeys[index];
				if (typeof SAVE_SERIALIZE_FILTER[key] === 'string') {
					key = SAVE_SERIALIZE_FILTER[key];
				}
				const fields = SAVE_SERIALIZE_FILTER[key];
				for (const f in fields) {
					serialized[k][f] = this[k][f];
				}
			}
		}

		return serialized;
	};

	// 220608. Статы для триггера новых нарративных сценариев
	inventory.newstats = {
		maxFloorLevel: 0,
		resetsTriggered: 0,
		sprintsLaunchedAtSameTime: 0,
		totalProfitPerSec: 0,
		totalSprintsCompleted: 0,
		lootpacksPurchased: 0,
		relicsPurchased: 0
	}

	let psourcesIndices = 0;
	for (const k in config.psources) {
		const ps = config.psources[k];
		inventory[k] = Object.assign(
			{
				id: k,
				type: 'psource',
				active: false,
				level: 0,
				thresholdLevel: 0,
				rankLevel: 0,
				index: psourcesIndices++,
				timestamp: -1,
				employee: null,
				gadget: null,
				npc: []
			},
			ps
		);

		inventory[k].profitBase = new Decimal(ps.profitBase);
		inventory[k].costBase = new Decimal(ps.costBase);
	}

	let stagesIndices = 0;
	for (const k in config.stages) {
		const stage = config.stages[k];
		inventory[k] = Object.assign(
			{
				type: 'stage',
				active: false,
				auditsCompleted: 0,
				auditsAvailable: 5,
				sprite: stage.sprite,
				id: k,
				pactive: false
			},
			stage
		);

		for (const i in stage.psources) {
			const id = stage.psources[i];
			if (!inventory[id]) {
				logger.group(
					'GAME_RES_ERROR',
					`Ошибка загрузки конфига: в stages ${stage.id} указан psource ${id}. Такого нет в psources`
				);
				continue;
			}
			inventory[id].stage = stagesIndices;
			inventory[id].stageId = k;
		}

		stagesIndices += 1;
	}

	for (const k in config.employees) {
		const employee = config.employees[k];
		inventory[k] = Object.assign(
			{
				type: 'employee',
				active: false,
				assigned: null,
				dust_assignedPvp: null,
				id: k,
				amount: 0,
				injuries: 0,
				level: '@fn: itd_item_level_calc()',
				// Bug #6987. bignumber здесь лишний, но спасает от ошибки
				health: '@fn: max(0, healthMax - bignumber(injuries))',
				attackTimespamp: 0,
				storylinesCompleted: 0
			},
			employee
		);
	}

	for (const k in config.gadgets) {
		const gadget = config.gadgets[k];
		inventory[k] = Object.assign(
			{
				id: k,
				type: 'gadget',
				active: false,
				assigned: null,
				dust_assignedPvp: null,
				amount: 0,
				level: '@fn: itd_item_level_calc()',
				// Bug #6987. bignumber здесь лишний, но спасает от ошибки
				charges: '@fn: max(0, chargesMax - bignumber(chargesUsed))',
				chargesUsed: 0
			},
			gadget
		);
	}

	for (const k in config.npcs) {
		const npc = config.npcs[k];
		inventory[k] = Object.assign(
			{
				id: k,
				type: 'npc'
			},
			npc
		);
	}

	for (const k in config.relics) {
		const relic = config.relics[k];
		inventory[k] = Object.assign(
			{
				id: k,
				type: 'relic',
				active: false,
				level: 0
			},
			relic
		);
	}

	for (const k in config.currencies) {
		const currency = config.currencies[k];
		inventory[k] = Object.assign(
			{
				id: k,
				type: 'currency'
			},
			currency
		);
		inventory[k].amount = new Decimal(currency.amount);
	}

	for (const k in config.pools) {
		const pool = config.pools[k];
		inventory[k] = Object.assign(
			{
				id: k,
				type: 'pool'
			},
			pool
		);
	}

	for (const k in config.lootpacks) {
		const lootpack = config.lootpacks[k];
		inventory[k] = Object.assign(
			{
				id: k,
				type: 'lootpack',
				cost: lootpack.cost || 0
			},
			lootpack
		);
	}

	for (const k in config.storylines) {
		inventory[k] = { id: k, completed: false, started: false, status: 0 };
	}

	for (const k in config.achievements) {
		// NOT_COMPLETED: 0
		// COMPLETED: 1
		// COLLECTED: 2
		inventory[k] = { id: k, completed: false, collected: false, status: 0 };
	}

	const pvp_floor_names = ['Склад', 'Кафетерий', 'Спортзал', 'Зал славы', 'Комната отдыха', 'Отдел кадров', 'Конференц-зал', 'Кабинет шефа']
	for (let i = 0; i < config.game.pvp_floors_amount; i++) {
		const id = 'pvp_' + i;
		const prototype = inventory['office_1_' + (i + 1)];
		inventory[id] = {
			id,
			index: i,
			type: 'pvp_floor',
			employee: null,
			gadget: null,
			name: pvp_floor_names[i] || 'Офис ' + (i + 1),
			clickDamage: 10,
			sprite: prototype.sprite
		};
	}
}
