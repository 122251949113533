/**
 * @File ItdItemCard.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */

import ItemCard from '../generic/ItemCard.js';
import { StandardRawData } from '../../core/standart_data_helpers';

const ITD_ITEM_FUNCTIONS = {
	itd_item_level_calc() {
		let level = 0;
		const levels = this.property('levels');
		const amount = this.property('amount');
		for (; level < levels.length; level++) {
			const l = levels[level];
			if (l > amount) {
				break;
			}
		}

		return level;
	}
};

/**
 * Class for itd items
 */
export default class ItdItemCard extends ItemCard {
	/**
	 * @param {StandardRawData} raw raw item data
	 * @param {GameCore} core game core instance
	 */
	constructor(raw, core) {
		super(raw, ITD_ITEM_FUNCTIONS);
		this.core = core;
	}

	/**
	 * Returns static or calculated value
	 * Multiplied on factor from 'relics' mechanics
	 *
	 * @param {string} key value key
	 * @returns {number|string|*} value stored in raw.content
	 */
	propertyGet(key) {
		const value = super.propertyGet(key);
		if (typeof value === 'number') {
			const factor = this.core.actions._relics.calc(this.id, key);

			return value * factor;
		}

		return value;
	}

	/**
	 * @returns {number} current item level
	 */
	get level() {
		return this.property('level');
	}

	/**
	 * @returns {number} current item amount
	 */
	get amount() {
		return this.property('amount');
	}
}
