import Decimal from 'decimal.js';
import logger from '../../core/logger.js';
import ItdRelicsModifiers from './ItdRelicsModifiers.js';
import math from '../../core/mathjs';
import AppCore from '../AppCore.js';
import { storage } from '../../js-client-utils/lib/storage.js';
import { arg } from 'mathjs';

export const ACTION_CODES = {
	SUCCESS: 0,
	FAIL: 1
};

export const MAX_COFFEE = 999;

// 220322. Draft. По ГДД 1.10
// https://docs.google.com/document/d/1RLvMIvd5woufQTq9CwOIIhLySp3LezsCi50GzKTzWiI/edit#heading=h.k5zohaz39hro

/**
 * Handles used and other actions
 */
export default class GameActions {
	/**
	 * @param {object} raw
	 * @param {GameCore} core .
	 */
	constructor(raw, core) {
		this.timescale = 1;
		this.profitscale = 1;

		this.raw = raw;
		this.core = core;
		this._relics = new ItdRelicsModifiers(this.raw, this.core);
	}

	/**
	 * @returns {GameActions} this
	 */
	init() {
		this._relics.init();

		return this;
	}

	/* eslint-disable max-lines-per-function, max-statements, complexity */
	/**
	 * Major function for changing game state
	 *
	 * @param {string} type action type
	 * @param {object} [args] action arguments
	 * @param {boolean} [silent=false] make action without events emitting
	 * @returns {ACTION_CODES} .
	 */
	action(type, args = {}, silent = false) {
		try {
			switch (type) {
				case 'purchase_psource':
					this._purchasePSoruce();
					AppCore.instance.saveGameDataOnServer(true);
					break;
				case 'upgrade_psource':
					this._upgradePSource(args.id);
					AppCore.instance.saveGameDataOnServer();
					break;
				case 'reset_psources':
					this._resetPSources(args.id);
					this.raw.content.inventory.content.newstats.resetsTriggered += 1;
					AppCore.instance.ticker.events.emit('redraw_offices');
					AppCore.instance.saveGameDataOnServer(true);
					break;
				case 'collect_profit':
					this._collectProfit(args.time);
					break;
				case 'set_achievements_status': {
					const item = this.core.inventory.get(args.id);
					item.status = args.status;

					item.completed = item.status > 0;
					item.collected = item.status === 2;
					AppCore.instance.saveGameDataOnServer(true);
					break;
				}
				case 'set_storyline_status': {
					const item = this.core.inventory.get(args.id);
					item.status = args.status;

					item.completed = item.status === 2;
					item.started = item.status > 0;
					AppCore.instance.saveGameDataOnServer(true);
					break;
				}
				case 'assign_item':
					this._assignItem(args.type, args.itemid, args.roomid);
					AppCore.instance.saveGameDataOnServer(true);
					break;
				case 'claim_reward': {
					const item = this.core.inventory.get(args.id);
					if (item.type === 'lootpack') {
						this._openLootpack(args.id, false);
						AppCore.instance.saveGameDataOnServer(true);
					}
					break;
				}
				case 'open_lootpack':
					this._openLootpack(args.id, args.paid ?? false);
					AppCore.instance.logOnServer("user_buy_item", {id: args.id}, true);
					AppCore.instance.saveGameDataOnServer(true);
					break;
				case 'buy_coffee':
					this._buyCoffee(args.amount);
					AppCore.instance.logOnServer("user_buy_item", {id: 'coffee'}, true);
					AppCore.instance.saveGameDataOnServer(true);
					break;
				case 'buy_relic':
					this._buyRelic(args.id);
					AppCore.instance.logOnServer("user_buy_item", {id: args.id}, true);
					AppCore.instance.saveGameDataOnServer(true);
					break;
				case 'upgrade_relic': {
					AppCore.instance.logOnServer("user_upgrade_relic", {id: args.id}, true);
					const item = this.core.inventory.getItdItem(args.id);
					this.spend('gems', item.property('cost'));
					item.raw.content.level += 1;
					AppCore.instance.saveGameDataOnServer(true);
					break;
				}
				case 'recharge_employee': {
					const roomdata = this.core.inventory.get(args.roomid);
					const itemdata = this.core.inventory.getItdItem(roomdata.employee);
					if (!itemdata) {
						break;
					}
					itemdata.property('injuries', 0);

					break;
				}
				case 'recharge_gadget': {
					const roomdata = this.core.inventory.get(args.roomid);
					const itemdata = this.core.inventory.getItdItem(roomdata.gadget);
					if (!itemdata) {
						break;
					}
					itemdata.property('chargesUsed', 0);

					break;
				}
				case 'battle_user_click': {
					const battle = this.core.battles[args.battleid];
					if (battle) {
						battle.applyDamage(args.room, this.conf('battle_user_click', args));
					}
					break;
				}
				case 'use_coffee': {
					const battle = this.core.battles[args.battleid];
					const cost = this.conf('use_coffee_cost', args);
					if (this.testSpend('coffee', cost))
					{
						battle.coffeeUsed += 1;
						battle.cache.coffeeUseTimestamp = Date.now();
						const coffee = AppCore.instance.game.inventory.get('coffee');
						AppCore.instance.game.inventory.set('coffee', coffee.amount - cost);
						const new_coffee = AppCore.instance.game.inventory.getFormattedAmount('coffee');
						AppCore.instance.itd5ka.shell.print(new_coffee, 'coffee');
					}
					const topview = AppCore.instance.views.top();
					if (topview._loop) {
						topview._loop(null, null, Date.now());
					}
					AppCore.instance.saveGameDataOnServer(true);
					break;
				}
				case 'register_completed_audit': {
					if (!args.win) {
						break;
					}

					const reward = this.conf('audit_reward', args);

					this.raw.content.stats.content.auditsReward += reward;
					this.raw.content.stats.content.auditsCompleted += args.audits;
					this.raw.content.inventory.content.newstats.totalSprintsCompleted += args.audits;

					break;
				}
				case 'register_completed_pvp': {
					this.raw.content.stats.content.pvpsCompleted += args.win ? 1 : 0;
					this.raw.content.stats.content.pvpGlory += args.win ? 1 : -1;
					this.raw.content.stats.content.pvpGlory = Math.max(
						0,
						this.raw.content.stats.content.pvpGlory
					);
					break;
				}
				case 'add_currency': {
					this.core.inventory.add(args.type, args.amount);
					AppCore.instance.saveGameDataOnServer(true);
					break;
				}
				default:
					break;
			}
		} catch (err) {
			if (!silent) {
				logger.group('GAMEPLAY_WARN', 'GameActions.action: error', err);
			}

			return ACTION_CODES.FAIL;
		} finally {
			if (!silent) {
				AppCore.instance.events.emit('itd5ka_user_action', { key: type, args });
			}
		}

		return ACTION_CODES.SUCCESS;
	}

	/* eslint-disable no-magic-numbers, complexity */
	/**
	 * Returns some config value
	 *
	 * @param {string} type .
	 * @param {object} [args] custom args
	 * @returns {number} .
	 */
	conf(type, args) {
		try {
			switch (type) {
				case 'psources_purchased':
					return this.raw.content.stats.content.psources;
				case 'stages_purchased':
					return Math.floor(this.conf('psources_purchased') / this.conf('rooms_amount')) + 1;
				case 'purchase_psource': {
					const psources = this.core.inventory.psources;
					const purchased = this.conf('psources_purchased');
					const psource = psources[purchased];
					if (psource) {
						const id = psource.id;
						const ps = this.core.inventory.getItdItem(id);
	
						return math.multiply(ps.property('costBase'), this._relics.calc(ps.id, 'upgrade_bonus'));
					}
					console.trace(psources);
					const psources_s = JSON.stringify(psources);
					AppCore.instance.logOnServer("error", {
						errorMessage:
						`psource not exist in GameAction::conf('purchase_psource') ${psources_s} ${purchased}`
					});
					console.error(`psource not exist in GameAction::conf('purchase_psource') ${psources_s} ${purchased}`);
					return 1;
				}
				case 'psource_upgrade':
				case 'upgrade_psource': {
					const ps = this.core.inventory.getItdItem(args.id);
					const psRaw = this.core.inventory.get(args.id);
					const bonus = this.conf('items_bonus_value', {
						psourceid: args.id,
						bonusname: 'upgrade_bonus'
					});

					let level = psRaw.level;
					if (args.level) {
						psRaw.level = args.level;
					}
					const cost = math.multiply(
						ps.property('cost'),
						this._relics.calc(ps.id, 'upgrade_bonus'),
						bonus
					);
					psRaw.level = level;

					return Math.round(cost * 100) / 100;
				}
				case 'psource_profit': {
					const ps = this.core.inventory.getItdItem(args.id);
					const profit = ps.property('profit');
					const bonus = this.conf('items_bonus_value', {
						psourceid: args.id,
						bonusname: 'profit_bonus'
					});

					const bonusProfit = math.multiply(profit, bonus);
					const scaledProfit = math.multiply(bonusProfit, this.profitscale);

					return scaledProfit;
				}
				case 'psource_threshold': {
					const ps = this.core.inventory.get(args.id);
					const threshold = ps.threshold;
					const bonus = this.conf('items_bonus_value', {
						psourceid: args.id,
						bonusname: 'threshold_bonus'
					});

					const bonusThreshold = Math.max(1, threshold * bonus);
					const scaledThreshold = bonusThreshold / this.timescale;

					return scaledThreshold;
				}
				case 'items_bonus_value': {
					// #draft
					const ps = this.core.inventory.get(args.psourceid);
					const items = ['employee', 'gadget'];

					let value = 1;

					for (const i in items) {
						const itemid = ps[items[i]];
						const item = this.core.inventory.getItdItem(itemid);
						if (!item) {
							continue;
						}

						value *= item.property(args.bonusname) || 1;
					}

					value *= this._relics.calc(ps.id, args.bonusname);

					return value;
				}
				case 'psource_rank_level_name': {
					const ps = this.core.inventory.get(args.id);

					return this.raw.content.config.content.game.floors_rank_names[ps.rankLevel];
				}
				/*case 'stage_available': {
					const prevStage = this.core.inventory.stages[args.stage - 1];
					if (prevStage) {
						const psources = this.raw.content.config.content.stages[prevStage.id].psources;
						for (const i in psources) {
							if (!this.core.inventory.get(psources[i]).active) {
								return false;
							}
						}
					}

					return true;
				}*/
				case 'itd_reset_reward': {
					let reward = 0;
					for (const i in this.core.inventory.psources) {
						const ps = this.core.inventory.psources[i];

						if (ps.active) {
							reward += ps.thresholdLevel * this.core.getConfig('game', 'psource_base_reward');
						}
					}

					reward +=
						Math.max(0, this.raw.content.stats.content.stages - 1 || 0) *
						this.core.getConfig('game', 'stage_base_reward');

					reward += this.raw.content.stats.content.auditsReward;

					reward +=
						this.raw.content.stats.content.pvpsCompleted *
						this.core.getConfig('game', 'pvp_base_reward');

					return reward;
				}
				case 'audit_weight': {
					const level = args.level;

					return level * 0.3 + 1;
				}
				case 'audit_reward': {
					let reward = 0;
					let mul = 4;
					const auditRewards = this.core.getConfig('game', 'audit_amount_rewards');
					const audits = Math.max(args.audits - 1, 0);
					for (let i = 0; i < args.audits; i++) {
						const auditLevel = Math.max(args.auditLevel + i || 0, 0);
						reward += auditLevel;
					}

					reward += args.auditLevel * mul;
					return Math.round(reward * auditRewards[Math.min(audits, auditRewards.length - 1)]);
				}
				case 'audits_completed':
					return this.raw.content.stats.content.auditsCompleted;
				case 'relic_cost': {
					const costs = this.core.getConfig('game', 'relics_cost');
					const pool = this.core.getConfig('pools', 'relics_pool').list;

					if (this.raw.content.stats.content.relicsPurchased >= pool.length) {
						return null;
					}

					const index = Math.min(costs.length - 1, this.raw.content.stats.content.relicsPurchased);
					const cost = costs[index];

					return cost;
				}
				case 'coffee_cost': {
					const costs = this.core.getConfig('game', 'coffee_cost');
					return costs;
				}
				case 'coffee_amount': {
					const amounts = this.core.getConfig('game', 'coffee_amount');
					return amounts;
				}
				case 'pvp_glory': {
					return this.raw.content.stats.content.pvpGlory;
				}
				case 'pvp_rank': {
					const glory = this.raw.content.stats.content.pvpGlory;
					const ranks = this.core.getConfig('game', 'pvp_ranks');
					for (const i in ranks) {
						if (glory < ranks[i]) {
							return i;
						}
					}
				}
				case 'battle_user_click': {
					const room = this.core.inventory.getItdItem(args.room);
					const damage = room.property('clickDamage');
					const bonus = this.conf('items_bonus_value', {
						psourceid: args.room,
						bonusname: 'click_bonus'
					});

					return damage * Number(bonus.toFixed(2));
				}
				case 'use_coffee_cost': {
					const battle = this.core.battles[args.battleid];
					if (battle) {
						const cost = Math.pow(battle.coffeeUsed, 2);

						return cost;
					}
					return -1;
				}
				default:
					break;
			}
		} catch (err) {
			logger.group('GAMEPLAY_WARN', 'GameActions.config: error', err);

			throw err;
		}

		return -1;
	}
	/* eslint-enable no-magic-numbers, complexity  */
	/* eslint-enable max-lines-per-function, max-statements, complexity */

	/**
	 * Check if lootpack has specific pool
	 *
	 * @param {string} id lootpack id
	 * @param {string} spec pool id element
	 * @returns {boolean} true if lootpack contains pool with specific id element
	 */
	 isLootpackHasPool(id, spec) {
		const lootpack = this.core.raw.content.config.content.lootpacks[id];
		for (const pid of lootpack.pool) {
			if (pid.includes(spec)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * isLootpackItemsMaxed. Check if whole lootpack specific items maxed
	 *
	 * @param {string} id lootpack id
	 * @param {string} spec pool id element
	 * @param {string} type type of pool elements
	 * @returns {boolean} true if all employees maxed
	 */
	 isLootpackItemsMaxed(id, spec, type) {
		let maxed = true;
		let managers = this.isLootpackHasPool(id, spec);
		if (!managers) return false;
		const lootpack = this.core.raw.content.config.content.lootpacks[id];
		for (const pid of lootpack.pool) {
			if (!pid.includes(spec)) {
				continue;
			}
			let pool = this.core.inventory.get(pid);
			if (pool.type !== 'pool') {
				continue;
			}

			for (const eid of pool.list) {
				const element = this.core.inventory.get(eid);
				if (element.type !== type) {
					continue;
				}

				const item = this.core.inventory.getItdItem(eid);
				const itemraw = this.core.inventory.getItdItem(eid).raw.content;
				const upgcost = itemraw.levels[item.level] ?? null;
				maxed &= upgcost === null;
				if (!maxed) break;
			}
			if (!maxed) break;
		}

		return maxed;
	}

	/**
	 * testSpend. Pure check.
	 *
	 * @param {string} type
	 * @param {number} amount
	 * @returns {boolean} true if has enough currency
	 */
	testSpend(type, amount) {
		const curramount = this.core.inventory.get(type).amount;
		let enough = false;
		if (curramount instanceof Decimal) {
			enough = curramount.gte(amount);
		} else {
			enough = curramount > amount;
		}

		return enough;
	}

	/**
	 * Spends currency
	 *
	 * @param {string} type currency name
	 * @param {number} amount amount to spend
	 */
	spend(type, amount) {
		const curramount = this.core.inventory.get(type).amount;

		if (!this.testSpend(type, amount)) {
			const msg = `GameActions.spend: not enough ${type} to spend. Needs ${amount} have ${curramount}`;
			AppCore.instance.logOnServer("error", { errorMessage: msg });
			console.error(msg);
			return;
			/*throw new Error(
				`GameActions.spend: not enough ${type} to spend. Needs ${amount} have ${curramount}`
			);*/
		}

		this.core.inventory.add(type, -amount);
	}

	/**
	 * Purcases new profit source
	 *
	 * @returns {object} profit source data
	 */
	_purchasePSoruce() {
		const purchased = this.raw.content.stats.content.psources;
		const psource = this.core.inventory.psources[purchased];

		const cost = this.conf('purchase_psource', { id: psource.id });

		this.spend('gold', cost);

		psource.active = true;
		psource.level = 1;
		psource.timestamp = Date.now();

		this.raw.content.stats.content.psources += 1;

		const stage = this.core.inventory.stages[psource.stage];
		if (!stage.active) {
			stage.active = true;
		}
		if (!stage.pactive) {
			this.raw.content.stats.content.stages += 1;
			stage.pactive = true;
		}

		return psource;
	}

	/**
	 * Upgrades profit source
	 *
	 * @param {string} id source to upgrade
	 */
	_upgradePSource(id) {
		if (!id) return;
		
		const ps = this.core.inventory.get(id);

		const maxLevel = this.core.getConfig('game', 'maxPsourceLevel');
		if (ps.level >= maxLevel) {
			return;
		}

		const cost = this.conf('upgrade_psource', { id });
		this.spend('gold', cost);

		//ps.profit = ps.profit.mul(2);
		ps.level += 1;
		this.raw.content.inventory.content.newstats.maxFloorLevel = Math.max(
			ps.level,
			this.raw.content.inventory.content.newstats.maxFloorLevel
		);

		const conf = this.raw.content.config.content.psources[id];

		// Upgrade threshold
		if (conf.thresholdLevels[ps.thresholdLevel] <= ps.level) {
			ps.thresholdLevel += 1;
			ps.threshold = Math.max(ps.threshold / 2, 1);
		}

		// Upgrade rank
		if (conf.rankLevels[ps.rankLevel] <= ps.level) {
			ps.rankLevel += 1;
		}
	}

	/**
	 * Gameplay mechanics
	 */
	_resetPSources() {
		const reward = this.conf('itd_reset_reward');

		// reset managers and gadgets
		for (const i in this.core.inventory.psources) {
			const ps = this.core.inventory.psources[i];

			if (ps.employee) {
				const item = this.core.inventory.get(ps.employee);
				const field_key = 'assigned';
				if (item[field_key]) {
					item[field_key] = null;
				}
				const field_key_pvp = 'dust_assignedPvp';
				if (item[field_key_pvp]) {
					item[field_key_pvp] = null;
				}
			}
			if (ps.gadget) {
				const item = this.core.inventory.get(ps.gadget);
				const field_key = 'assigned';
				if (item[field_key]) {
					item[field_key] = null;
				}
				const field_key_pvp = 'dust_assignedPvp';
				if (item[field_key_pvp]) {
					item[field_key_pvp] = null;
				}
			}
			ps.employee = null;
			ps.gadget = null;
		}

		AppCore.instance.resetRootView();

		for (const i in this.core.inventory.psources) {
			const ps = this.core.inventory.psources[i];
			const conf = this.core.getConfig('psources', ps.id);

			//ps.profit = new Decimal(conf.profit);
			ps.threshold = conf.threshold;
			ps.thresholdLevel = 0;
			ps.active = false;
			ps.level = 0;
		}

		// issue #158. Баг с открытием сундуков - не выпадают менеджеры, пока !stage.active. Просто отключаю сброс stages
		
		for (const i in this.core.inventory.stages) {
			const stage = this.core.inventory.stages[i];
			stage.pactive = false;
		}
		this.raw.content.stats.content.stages = 0;
		

		this.raw.content.stats.content.psources = 0;
		this.raw.content.stats.content.auditsReward = 0;
		this.raw.content.stats.content.auditsCompleted = 0;
		this.raw.content.stats.content.pvpsCompleted = 0;

		this.core.inventory.set('gold', this.core.getConfig('currencies', 'gold').amount);

		this.core.inventory.add('gems', reward);
	}

	/**
	 * Collects profit from all profit sources
	 *
	 * @param {number} [time=Date.now()] current time in ms
	 */
	_collectProfit(time = Date.now()) {
		const timeupdateOnly = time < AppCore.instance.lasttime_collect;
		if (storage.available()) {
			AppCore.instance.lasttime_collect = time;
			storage.save('itd_lasttime_collect', time);
		}

		const now = time;
		const psources = this.core.inventory.psources;

		let profitPerSec = 0;

		for (const i in psources) {
			const ps = psources[i];
			const dt = Math.max(0, now - ps.timestamp) / 1000;
			const threshold = this.conf('psource_threshold', { id: ps.id });
			const dtc = Math.floor(dt / threshold);
			const baseprofit = this.conf('psource_profit', { id: ps.id });

			profitPerSec += math.divide(baseprofit, threshold);
			if (ps.active && (dt >= threshold || timeupdateOnly)) {
				const profit = math.multiply(baseprofit, dtc);
				if (!timeupdateOnly) this.core.inventory.add('gold', profit);
				ps.timestamp = now;
			}
		}

		this.raw.content.inventory.content.newstats.totalProfitPerSec = Math.round(profitPerSec);
	}

	/**
	 * @param {string} type item type
	 * @param {string} itemid item id to assign
	 * @param {string} roomid opened room id
	 */
	_assignItem(type, itemid, roomid) {
		const item = this.core.inventory.get(itemid);
		const room = this.core.inventory.get(roomid);

		// #dust-220405-00
		// Ввел pvp, теперь менеджер может быть назначен в несколько разных комнат
		const field_key = !room || room.type === 'psource' ? 'assigned' : 'dust_assignedPvp';

		// cleanup old item position
		if (item[field_key]) {
			this.core.inventory.get(item[field_key])[type] = null;
		}

		// deassign
		if (!roomid || item[field_key] === roomid) {
			item[field_key] = null;

			return;
		}

		if (room[type] && room[type] !== itemid) {
			this.core.inventory.get(room[type])[field_key] = null;
		}

		item[field_key] = roomid;
		room[type] = itemid;
	}

	/**
	 * _getItemFromPool. Returns random available item in pool
	 *
	 * @param {string} id .
	 * @returns {object} rendom item or item by id
	 */
	_getItemFromPool(id) {
		let item = this.core.inventory.get(id);
		if (item.type !== 'pool') {
			return item;
		}

		const pool = item;
		const available = (index) => {
			const _id = pool.list[index];
			item = this.core.inventory.get(_id);

			// Stage locked

			if (item.stage && !this.core.inventory.get(item.stage).active) {
				return false;
			}

			if (item.levels && this.core.inventory.getItdItem(_id).level >= item.levels.length) {
				return false;
			}

			return true;
		};

		let index = Math.floor(pool.list.length * this.core.random());
		let safelock = 0;
		while (!available(index) && safelock++ < pool.list.length) {
			index = (index + 1) % pool.list.length;
		}

		if (safelock >= pool.list.length) {
			return null;
		}

		return item;
	}

	/* eslint-disable max-statements, complexity, max-lines-per-function */
	/**
	 * #govnocode
	 * Opens lootpack and adds content to inventory
	 *
	 * @param {string} id .
	 */
	_openLootpack(id, paid) {
		this.core.inventory.cleanDust();

		const parseValue = (string) => {
			let number = Number(string);

			// Converts values like "10-100" into randomized value in range [10, 100]
			if (isNaN(number)) {
				const args = string.split('-');
				const min = Number(args[0]);
				const max = Number(args[1]);
				const random = this.core.random();

				number = Math.round(min + (max - min) * random);
			}

			return number;
		};

		const lootpack = this.core.raw.content.config.content.lootpacks[id];
		let weight = parseValue(lootpack.weight);

		const constructDrop = (itemid) => {
			const item = this._getItemFromPool(itemid);
			if (!item) {
				return null;
			}

			const itemargs = lootpack[itemid];
			let chance = itemargs;
			let amount = 1;
			let variant = 0;

			// "0.5;10-100"
			if (typeof chance === 'undefined') {
				chance = 1;
				amount = 1;
				variant = 0;
			} else if (typeof chance !== 'number') {
				const args = itemargs.split(';');
				chance = parseValue(args[0]);
				amount = args[1] ? parseValue(args[1]) : amount;
				variant = args[2] ? parseValue(args[2]) : 0;
			}

			return { id: item.id, chance, amount, weight: item.weight, variant };
		};

		// A. Generate full drop list
		const droplist = {};
		for (const i in lootpack.pool) {
			const drop = constructDrop(lootpack.pool[i]);
			if (drop) {
				droplist[drop.id] = drop;
			}
		}

		if (Object.values(droplist).length === 0 && !lootpack.gold) {
			console.log(`Empty droplist on open lootpack attempt, lootpack id='${id}', paid='${paid}'`);
		}

		if (paid) {
			this.spend('gems', lootpack.cost || 0);
			this.raw.content.inventory.content.newstats.lootpacksPurchased += 1;			
		}

		const addDrop = (drop) => {
			const item = this.core.inventory.dust.content[drop.id] || { amount: 0 };
			this.core.inventory.addToDust(item, drop.id);

			item.amount += Math.floor(drop.amount);

			const w = drop.amount * drop.weight;
			weight -= w;
		};

		// B. Add all with 100% chance and Remove variants
		const variants = [];
		for (const k in droplist) {
			const drop = droplist[k];
			if (drop.chance === 1) {
				addDrop(drop);
				delete droplist[k];
			}
			if (drop.variant === 1) {
				variants.push(drop);
			}
		}
		const choosen = [];
		for (let i = 0; i < variants.length; i++) {
			const drop = variants[i];
			if (drop.chance < this.core.random()) {
				choosen.push(i);
			}
		}
		let vindex = -1;
		if (choosen.length > 0) {
			vindex = choosen[Math.floor(choosen.length * this.core.random())];
		} else {
			vindex = Math.floor(variants.length * this.core.random());
		}
		for (let i = 0; i < variants.length; i++) {
			const drop = variants[i];
			if (i != vindex && drop.variant === 1) {
				delete droplist[drop.id];
			}
		}

		// C. Generate what left

		let safe = 0;
		while (weight > 0 && safe++ < 1000) {
			let chanceMax = 0;
			let chanceIterated = 0;
			const chanceResult = this.core.random();

			for (const k in droplist) {
				const drop = droplist[k];
				chanceMax += drop.chance;
			}

			if (!chanceMax) {
				break;
			}

			for (const k in droplist) {
				const drop = droplist[k];
				chanceIterated += drop.chance;
				if (chanceIterated >= chanceResult) {
					addDrop(drop);
					delete droplist[k];
					break;
				}
			}
		}

		// 220624
		// Добавляю в сундук доход за одну минуту игры
		if (lootpack.gold) {
			if (this.raw.content.inventory.content.newstats.totalProfitPerSec > 100) {
				addDrop({
					id: 'gold',
					amount: this.raw.content.inventory.content.newstats.totalProfitPerSec * 60 * lootpack.gold,
					weight: 1
				});
			}
		}

		// D. Добавление всего этого в инвентарь

		for (const k in this.core.inventory.dust.content) {
			const ditem = this.core.inventory.dust.content[k];
			this.core.inventory.add(k, ditem.amount);
			const item = this.core.inventory.get(k);

			if (item.hasOwnProperty('active') && item.active === false) {
				item.active = true;
			}

			AppCore.instance.events.emit('td_item_dropped', { id: item.id });
		}
	}
	/* eslint-enable max-statements, complexity, max-lines-per-function */

	/**
	 * _buyCoffee.
	 *
	 * @param {number} amount .
	 */
	_buyCoffee(amount) {
		const costs = this.conf('coffee_cost');
		const variants = this.conf('coffee_amount');
		const coffee = AppCore.instance.game.inventory.get('coffee');
		for (let i = 0; i < variants.length; i++) {
			if (amount == variants[i] && coffee.amount < MAX_COFFEE) {
				this.spend('gems', costs[i]);
				this.core.inventory.add('coffee', Math.min(MAX_COFFEE - coffee.amount, amount));
				return;
			}
		}
		throw  new Error(`Wrong amount (${amount}) for buying coffee.`);
	}

	/**
	 * Activates new relic
	 */
	_buyRelic() {
		const pool = this.core.getConfig('pools', 'relics_pool').list;

		if (this.raw.content.stats.content.relicsPurchased > pool.length) {
			return;
		}

		const id = pool[this.raw.content.stats.content.relicsPurchased];

		const rawitem = this.core.inventory.get(id);

		this.spend('gems', this.conf('relic_cost', { id }) || 0);
		if (!rawitem.active) {
			this.raw.content.stats.content.relicsPurchased += 1;
			this.raw.content.inventory.content.newstats.relicsPurchased += 1;
		}
		rawitem.active = true;
		rawitem.level += 1;
	}

	// --- dta

	_getMostProfitPsource() {}
}
