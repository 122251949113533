/**
 * @File BattleResultsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { inventoryGet, action } from '../shorthands.js';
import TutorialView from './TutorialView.js';

/**
 * Game root view
 */
export default class SettingsView extends ViewUiBase {
	constructor(disableAnimation = false) {
		super();
		this.disableAnimation = disableAnimation;
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				AppCore.instance.views.pop();
				break;
			case 'sound':
				this.toggleSetting('sound');
				this.show();
				break;
			case 'music':
				this.toggleSetting('music');
				this.show();
				break;
			case 'help':
				AppCore.instance.views.pop();
				AppCore.instance.views.push(new TutorialView(true));
				break;
		}
	}

	show() {
		const container = AppCore.instance.itd5ka.shell.popup('settings', false, this.disableAnimation);
		container
			.querySelector('#music')
			.classList[this.getSetting('music') ? 'remove' : 'add']('muted');
		container
			.querySelector('#sound')
			.classList[this.getSetting('sound') ? 'remove' : 'add']('muted');
	}

	toggleSetting(setting) {
		localStorage.setItem('settings.' + setting, 1 - this.getSetting(setting));
		switch (setting) {
			case 'music':
				AppCore.instance.sounds.setThemeState(this.getSetting(setting));
		}
	}

	getSetting(setting) {
		return Number(localStorage.getItem('settings.' + setting) ?? 1);
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
