/**
 * @File HeadOfficeView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { setHeaderTitle, action, sound } from '../shorthands.js';
import OfficeView from './OfficeView.js';
import SignPaperView from './SignPaperView.js';
import HintView from './HintView.js';

/**
 * Game root view
 */
export default class HeadOfficeView extends ViewUiBase {
	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'hint':
				AppCore.instance.views.push(new HintView('hint_reset'));
				break;
			case 'confirm':
				//action('reset_psources'); // 220724 - this called from SignPaperView
				AppCore.instance.logOnServer("user_wipe_offices", null, true);
				AppCore.instance.views._views[0].selectedLocation = 0;
				AppCore.instance.views.pop();
				AppCore.instance.views.push(new OfficeView());
				AppCore.instance.views.push(new SignPaperView(this.reward));
				AppCore.instance.itd5ka.shell.resetMenuElements();
				sound('new_floor', 1, 0.8);
				break;
			case 'close_popup':
				if ((AppCore.instance.views.top() instanceof HeadOfficeView)) {
					e.stopPropagation();
					AppCore.instance.restoreRootView();
				}
				break;
			default:
				break;
		}
	}

	/**
	 * show.
	 */
	show() {
		this.reward = AppCore.instance.game.actions.conf('itd_reset_reward');
		const shell = AppCore.instance.itd5ka.shell;
		shell.backgorundStyle('default');
		const element = shell.popup('itd_reset', false);

		const button = element.querySelector('button#confirm');
		button.classList[this.reward ? 'remove' : 'add']('disabled');
		element.querySelector('label#description').innerHTML = this.reward;

		setHeaderTitle('Обнуление');
	}

	/**
	 * hide.
	 */
	hide() {
		//document.getElementById('head_office_btn').classList.remove('selected');
		const shell = AppCore.instance.itd5ka.shell;
		shell.popupHide();
	}
}
