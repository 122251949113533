/**
 * @File QuestsListView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import EmployeeDetailsView from './EmployeeDetailsView.js';
import { setHeaderTitle } from '../shorthands.js';
import TabsManager from '../dust/TabsManager.js';
import HintView from './HintView.js';
import { formatAssignedName } from '../dust/ui_utils.js';

const ITEM_RARITY_RATE = {
	"unique,Уникальный": 40, 
	"rare, Редкий": 30,
	"uncommon,Необычный" : 20,
	"common,Обычный": 10
};

/**
 * Game root view
 */
export default class InventoryView extends ViewUiBase {
	static elements = {
		employee: {
			tab: undefined,
			el: []
		},
		gadget: {
			tab: undefined,
			el: []
		}
	};

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		const item = AppCore.instance.game.inventory.get(e.target.dataset.itemid);

		if (e.target.id.length > 0) {
			const data = {
				name: e.target.id,
				itemid: e.target.dataset.itemid
			};

			AppCore.instance.logOnServer("user_tap", data, true);
		}

		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				if ((AppCore.instance.views.top() instanceof InventoryView)) {
					AppCore.instance.restoreRootView();
				}
				/*if (!(AppCore.instance.views.top() instanceof InventoryView)) {
					//console.log('InventoryView close_popup');
					//InventoryView.tabs.resetSelectedTab();
				}*/
				break;
			case 'hint':
				AppCore.instance.views.push(new HintView('hint_inventory'));
				break;
			case 'item_entry':
				e.preventDefault();
				if (item && item.active) {
					AppCore.instance.views.push(
						new EmployeeDetailsView(e.target.dataset.itemid, this.roomid)
					);
				}
				break;
			default:
				break;
		}
	}

	static getItemRarityRate(rarity) {
		if (!(rarity in ITEM_RARITY_RATE)) return 0;
		return ITEM_RARITY_RATE[rarity];
	}

	static sortByItemRarityRate(a, b) {
		if (a.active && !b.active) return -1;
		if (b.active && !a.active) return 1;
		return InventoryView.getItemRarityRate(b.rarity) - InventoryView.getItemRarityRate(a.rarity);
	}

	/* eslint-disable complexity */
	/**
	 * Draws list with staff
	 *
	 * @param {HTMLElement} container .
	 * @param {string} type .
	 */
	static renderTab(container, type) {
		const elements = InventoryView.elements[type];
		const tab = container.querySelector(`tab#${type}s`); // evil me
		if (elements.tab) {
			for (const e in elements.el) {
				const element = elements.el[e];
				elements.tab.removeChild(element);
			}
			elements.el.splice(0);
		}
		elements.tab = tab;
		const entry_template = tab.querySelector('entry#item_entry.inv_template');
		//tab.innerHTML = '';

		const inventory =
			Object.values(AppCore.instance.game.getData('inventory').content)
			.sort(InventoryView.sortByItemRarityRate);

		for (const index in inventory) {
			const item = inventory[index];
			if (item.type !== type) {
				continue;
			}

			const itemWrapper = AppCore.instance.game.inventory.getItdItem(item.id);

			const imgClass = item.active ? `textures-${item.icon}` : 'empty';

			let pbarWidth = 0;
			let amount = 0;
			let cost = 0;
			if (itemWrapper.level) {
				amount = item.amount;
				cost = item.levels[itemWrapper.level] ?? null;
				pbarWidth = cost !== null ? (amount / cost) * 100 : 100;
			}

			const progressbarText = cost !== null ? `${item.amount}/${cost}` : 'MAX';

			const assigned = item.assigned;
			let assignedName = '';
			if (assigned) {
				assignedName = formatAssignedName(AppCore.instance.game.inventory.get(assigned).name);
			}
			const assigned_office = assigned ? assigned.split('_').splice(0, 2).join('-') : '';
			let rarity = itemWrapper.property('rarity');
			let rarity_value = 'default';
			if (rarity) {
				const args = rarity.split(',');
				rarity_value = args[0] == '' ? rarity_value : args[0];
				rarity = `rarity ${rarity_value}`;
			} else {
				rarity = `rarity ${rarity_value}`;
			}
			let el_id = '';
			if (rarity == '') el_id = `id='level'`;

			const eclass = `item_rarity ${rarity_value} ${item.active ? '' : 'disabled'}`;

			const entry = entry_template.cloneNode(true);
			elements.el.push(entry);
			tab.appendChild(entry);
			entry.classList.remove('inv_template', 'hidden');
			entry.classList.value = eclass;
			const level = entry.querySelector('label#level');
			const floor = entry.querySelector('label#floor');
			const icon = entry.querySelector('icon');
			const exp = entry.querySelector('progressbar#exp_bar');
			const rar = entry.querySelector('label#rarity');

			entry.setAttribute('data-itemid', item.id);
			level.innerText = `ур.${itemWrapper.property('level')}`;
			floor.classList.value = `assigned-floor-${assigned_office} ${assigned ? '' : 'hidden'}`;
			floor.innerText = assignedName;
			icon.classList.value = `${imgClass} ${el_id}`;
			exp.innerText = `★${progressbarText}`;
			exp.style.setProperty('--progress', `${pbarWidth}%`);
			rar.classList.value = rarity;
			rar.innerText = `${item.active ? item.name : '???'}`;
		}
	}
	/* eslint-enable complexity */

	/**
	 * show.
	 */
	show() {
		setHeaderTitle('Сотрудники');

		const shell = AppCore.instance.itd5ka.shell;
		shell.backgorundStyle('default');

		const element = shell.popup('inventory', false);
		InventoryView.render(element);

		InventoryView.tabs.restoreSelectedTab();
	}

	/**
	 * Draws list with staff
	 *
	 * @param {HTMLElement} container .
	 */
	static render(container) {
		InventoryView.renderTab(container, 'employee');
		InventoryView.renderTab(container, 'gadget');

		InventoryView.tabs = new TabsManager();
		InventoryView.tabs.init(
			container.querySelector('tabs#buttons'),
			container.querySelector('tabs#content')
		);
	}

	/**
	 * hide.
	 */
	hide() {
		const shell = AppCore.instance.itd5ka.shell;
		shell.popupHide();
		InventoryView.tabs.dispose();
	}
}
