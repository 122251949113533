/* eslint-disable max-classes-per-file */
/**
 * @File views.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Core
 */

/**
 * Class manages views stack
 */
class ViewManager {
	/**
	 * constructor.
	 */
	constructor() {
		this._views = [];
	}

	/**
	 * init.
	 *
	 * @returns {ViewManager} this
	 */
	init() {
		return this;
	}

	/**
	 * Adds view in top of stack
	 *
	 * @param {ViewBase} view to push
	 */
	push(view) {
		if (this._views.length > 0) {
			const top = this.top();

			if (view.constructor === top.constructor) return;

			top.hide();
			top.state = VIEW_STATES.HIDDEN;
		}

		this._views.push(view);
		view.init();
		view.state = VIEW_STATES.INITIALIZED;
		view.show();
		view.state = VIEW_STATES.DISPLAYED;
	}

	/**
	 * Pops view from stack
	 */
	pop() {
		if (this._views.length === 0) {
			return;
		}

		const popped = this._views.pop();
		popped.hide();
		popped.dispose();
		popped.state = VIEW_STATES.DISPOSED;

		app.sounds.play("click");

		if (this._views.length > 0) {
			const top = this.top();
			top.show();
			top.state = VIEW_STATES.DISPLAYED;
		}
	}

	/**
	 * Disposes all views
	 */
	dispose() {
		while (this._views.length) {
			const top = this._views.pop();
			if (top.state === VIEW_STATES.DISPLAYED) {
				top.hide();
			}
			top.dispose();
			top.state = VIEW_STATES.DISPOSED;
		}
	}

	/**
	 * @returns {ViewBase} top view in stack
	 */
	top() {
		return this._views[this._views.length - 1];
	}

	/**
	 * @returns {ViewBase} root view
	 */
	root() {
		return this._views[0];
	}

	/**
	 * Removes all views to match stack length
	 *
	 * @param {number} length .
	 */
	cut(length) {
		while (this._views.length > length) {
			this.pop();
		}
	}
}

const VIEW_STATES = {
	CREATED: 0,
	INITIALIZED: 1,
	DISPLAYED: 2,
	HIDDEN: 3,
	DISPOSED: 4
};

/**
 * ViewBase.
 *
 * @abstract
 */
class ViewBase {
	/**
	 * constructor.
	 */
	constructor() {
		this.state = VIEW_STATES.CREATED;
	}

	/**
	 * Called when view pushed to stack first time
	 *
	 * @abstract
	 */
	init() {
		//...
	}

	/**
	 * Called when view popped
	 *
	 * @abstract
	 */
	dispose() {
		//...
	}

	/**
	 * Called when view overlayed with other view
	 *
	 * @abstract
	 */
	hide() {
		//...
	}

	/**
	 * Called when view appears on top of stack
	 *
	 * @abstract
	 */
	show() {
		//...
	}
}

export { ViewBase, ViewManager, VIEW_STATES };
