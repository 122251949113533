/**
 * @File RoomDetailsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import { VIEW_STATES } from '../../core/views.js';
import AppCore from '../AppCore.js';
import EmployeeDetailsView from './EmployeeDetailsView.js';
import InventoryView from './InventoryView.js';

/**
 * Game root view
 */
export default class RoomDetailsView extends ViewUiBase {
	/**
	 * @param {String} roomid romm id to display
	 */
	constructor(roomid) {
		super();

		this.roomid = roomid;
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				if (this.state === VIEW_STATES.DISPLAYED) {
					e.stopPropagation();
					AppCore.instance.views.pop();
					InventoryView.tabs.resetSelectedTab();
				}
				break;
			case 'item_entry':
				AppCore.instance.views.push(new EmployeeDetailsView(e.target.dataset.itemid, this.roomid));
				break;
			default:
				break;
		}
	}

	/**
	 * show.
	 */
	show() {
		const room = AppCore.instance.game.inventory.get(this.roomid);

		const shell = AppCore.instance.itd5ka.shell;

		const element = shell.popup('itd_room_details', false);
		InventoryView.render(element);
		element.querySelector('label#header').innerHTML = room.name;

		InventoryView.tabs.restoreSelectedTab();
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
		InventoryView.tabs.dispose();
	}
}
