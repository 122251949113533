/**
 * @File VideoManager.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import logger from '../core/logger.js';
import AppCore from '../game/AppCore.js';

/**
 * Manages assets
 */
export default class VideoManager {

	/**
	 * constructor.
	 */
	 constructor() {
		this.panel = document.querySelector('div#videopanel');
		this.container = document.querySelector('div#videocontainer');
		this.pass = document.querySelector('button#pass_video');
		this.tip = document.querySelector('div#reward_tip');
		this.reward = document.querySelector('div#reward_value');
		this.video = document.querySelector('video#fullscreenvideo');
		this.video_timeline = document.querySelector('div#video_timeline');
		
		this.tip.innerHTML = 'НАГРАДА ЗА ПОЛНЫЙ ПРОСМОТР';

		this.video.type = 'video/mp4';
		this.video.preload = 'auto';
		this.video.autoplay = false;
		this.video.poster = `./res/video/poster.jpg`;
		this.video.setAttribute('webkit-playsinline', true);
		this.video.setAttribute('playsinline', true);
		
		this.end_callback = null;
		this.src = null;
	}

	end_handler() {
		if (this.end_callback) this.end_callback();
		this.end_callback = null;
		this.stop();
	}

	timeupdate_handler() {
		if (this.is_playing() && this.video.currentTime > 0 && this.video.duration > 0) {
			this.video_timeline.style.width = `${(this.video.currentTime / this.video.duration) * 100}%`;
		}
	}

	click_handler() {
		this.stop();
	}

	is_playing() {
		return this.is_preloaded() &&
			this.video.currentTime > 0 &&
			!this.video.paused;
	}

	is_preloaded() {
		return this.src !== null;
	}

	/**
	 * .
	 */
	stop() {
		this.panel.classList.add('hidden');
		this.video.removeEventListener("ended", this.end_handler);
		this.video.removeEventListener("timeupdate", this.timeupdate_handler);
		this.pass.removeEventListener('click', this.click_handler);
		this.video.pause();
		this.end_callback = null;
		this.src = null;

		AppCore.instance.sounds.onWindowFocus();
	}

	/**
	 * .
	 *
	 * @param {string} name .
	 * @param {string} width .
	 * @param {number} gems_reward .
	 * @param {function} callback .
	 */
	play(name, width, gems_reward, callback) {
		this.panel.classList.remove('hidden');
		this.container.style.width = width;
		this.pass.addEventListener('click', this.click_handler.bind(this));

		this.reward.innerHTML = gems_reward.toString();

		this.src = name;
		this.video.src = name;
		this.video.autoplay = true;
		this.video.currentTime = 0;
		this.end_callback = callback;
		this.video_timeline.style.width = "0";

		this.video.addEventListener("timeupdate", this.timeupdate_handler.bind(this), false);
		this.video.addEventListener("ended", this.end_handler.bind(this), false);

		AppCore.instance.sounds.onWindowBlur();
	}

	/**
	 * .
	 *
	 * @param {number} gems_reward .
	 * @param {function} callback .
	 */
	playPreloaded(gems_reward, callback) {
		if (this.src === null) return;

		this.panel.classList.remove('hidden');
		this.pass.addEventListener('click', this.click_handler.bind(this));

		this.reward.innerHTML = gems_reward.toString();

		this.end_callback = callback;

		this.video.autoplay = true;
		this.video.currentTime = 0;
		this.video_timeline.style.width = "0";
		this.video.addEventListener("timeupdate", this.timeupdate_handler.bind(this), false);
		this.video.addEventListener("ended", this.end_handler.bind(this), false);
		this.video.play();
	}

	/**
	 * .
	 *
	 * @param {string} name .
	 * @param {string} width .
	 */
	preload(name, width) {
		if (this.src !== null) return;
		this.container.style.width = width;

		this.src = name;
		this.video.src = name;
		this.video.autoplay = false;
		this.video.currentTime = 0;
		this.video.load();
	}

	/**
	 * .
	 *
	 */
	unload() {
		this.end_callback = null;
		this.src = null;
	}
}
