/**
 * @File GameCore.js
 * @Copyright Tamashi Games
 * @Module Idle Tovar Defense
 */
import GameActions from './tovdef/GameActions.js';
import Inventory from './tovdef/Inventory.js';
import {
	constructStandardRawData as csrd,
	StandardRawData
} from '../core/standart_data_helpers.js';
import Random from '../core/Random.js';
import Decimal from 'decimal.js';
import Config from './dust/config.js';
import {
	constructInventoryData,
	SAVE_SERIALIZE_FILTER
} from '../itd5ka/constructInventoryData.js';
import math from '../core/mathjs';

/**
 * @typedef {StandardRawData} GameCoreRawData
 * @property {object} content .
 * @property {StandardRawData} content.stats game stats
 * @property {StandardRawData} content.inventory game inventory
 * @property {StandardRawData} content.config game config
 */

const SEED_RANDOM = 0;

// Используется для сериализации
const BIGNUMBER_FIELDS_NAMES = ['amount', 'profit'];
const BIGNUMBER_NUMBER_LETTERS = [
	'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 
	'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 
	'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'X'
];

/**
 * @class
 * @param {GameCore} core .
 * @returns {Itd} .
 */
function Itd(core) {
	/**
	 * @param {number} index stage index
	 * @returns {string} stage id
	 */
	this.stageIndexToId = (index) => {
		return core.inventory.stages[index].id;
	};
}

/**
 * @class
 * @param {GameCore} core .
 * @returns {Achievements} .
 */
function Achievements(core) {
	const itemsCache = {};
	function getNewWrapperForItem(item) {
		let wrapper = itemsCache[item.id];
		if (wrapper) {
			return wrapper;
		}

		wrapper = {};
		const keys = Object.keys(item.raw.content);
		for (const i in keys) {
			const key = keys[i];
			Object.defineProperty(wrapper, key, {
				get() {
					return item.property(key);
				}
			});
		}

		itemsCache[item.id] = wrapper;

		return wrapper;
	}

	// Simplify
	// `core.inventory.getItdItem(itemid).property(prop)`
	// to
	// `inventory.itemid.prop`
	function genInventoryScope() {
		this.get = function get(key) {
			if (!core.inventory.raw.content.hasOwnProperty(key)) {
				return null;
			}
			const item = core.inventory.getItdItem(key);

			return getNewWrapperForItem(item);
		};
		this.has = function has(key) {
			return core.inventory.raw.content.hasOwnProperty(key);
		};
		this.keys = function keys() {
			return new Set(Object.keys(core.inventory.raw.content));
		};
		this.set = function set(key, value) {
			return false;
		};
	}

	const inventoryScope = new genInventoryScope();

	const cache = {};

	this.test = (code, cacheId) => {
		if (!code) {
			return null;
		}

		let fn = null;
		if (cacheId) {
			fn = cache[cacheId];
		}

		if (!fn) fn = math.compile(code);

		cache[cacheId] = fn;
		try {
			const result = fn.evaluate(inventoryScope);

			return result;
		} catch (e) {
			console.error(`Trigger ${code} evaluating error: `, e);
		}

		return null;
	};
}

/**
 * tovar defense game logic core
 */
export default class GameCore {
	/**
	 * .
	 */
	constructor() {
		this.raw = this.constructRawData(Config.get());
		this._random = new Random();
	}

	/**
	 * @returns {number} seeded random 0-1
	 */
	random() {
		return this._random.get(SEED_RANDOM);
	}

	/**
	 * @returns {GameCore} this
	 */
	init() {
		constructInventoryData(this.raw.content.inventory.content, this.raw.content.config.content);

		this.inventory = new Inventory(this.raw.content.inventory, this);
		this.actions = new GameActions(this.raw, this);
		this.achievements = new Achievements(this);

		this.inventory.init();
		this.actions.init();

		this.itd = new Itd(this);
		this.battles = {};

		this.tutor = 0;

		return this;
	}

	/**
	 * @param {object} config configureation object
	 * @returns {GameCoreRawData} .
	 */
	constructRawData(config) {
		return csrd('gamedata_root', {
			stats: csrd('stats', {
				psources: 0,
				stages: 0,
				auditsReward: 0, // 220322. ГДД 1.10,
				relicsPurchased: 0,
				pvpGlory: 0, //220401. ГДД 1.14,
				auditsCompleted: 0, //220517. Просили сделать прогресс боев общим на всю игру
				pvpsCompleted: 0
			}),
			inventory: csrd('inventory'),
			config: csrd('config', config)
		});
	}

	/**
	 * @param {object} serialized inventory content from save
	 */
	deserializeInventoryData(serialized) {
		const inventory = this.raw.content.inventory.content;
		const fkeys = Object.keys(SAVE_SERIALIZE_FILTER);
		for (const k in serialized.inventory) {
			const index = fkeys.findIndex(fk => k.indexOf(fk) === 0);
			if (index !== -1) {
				let key = fkeys[index];
				if (typeof SAVE_SERIALIZE_FILTER[key] === 'string') {
					key = SAVE_SERIALIZE_FILTER[key];
				}
				const fields = SAVE_SERIALIZE_FILTER[key];

				const item = serialized.inventory[k];
				for (const i in BIGNUMBER_FIELDS_NAMES) {
					const fieldname = BIGNUMBER_FIELDS_NAMES[i];
					if (fields[fieldname] && typeof item[fieldname] === 'string' && !isNaN(Number(item[fieldname]))) {
						item[fieldname] = new Decimal(item[fieldname]);
					}
				}
	
				// TODO: где-то надо очищать, лучше конечно перед записью
				// Или хранить где-то в отдельном хранилище
				if (item.type === 'psource') item.npc = [];
	
				for (const kk in item) {
					if (fields[kk]) {
						inventory[k][kk] = item[kk];
					}
				}
			}
		}

		this.raw.content.stats.content = serialized.stats || this.raw.content.stats.content;
	}

	/**
	 * @returns {object} raw inventory content from save
	 */
	serializeInventoryData() {
		const s = this.raw.content.inventory.content.getSerialized();
		const serialized = {
			stats: this.raw.content.stats.content,
			inventory: s
		};

		return serialized;
	}

	/**
	 * @param {string} name .
	 * @returns {object} game data
	 */
	getData(name) {
		return this.raw.content[name];
	}

	/**
	 * .
	 *
	 * @param {string} type .
	 * @param {string} id .
	 * @returns {object} .
	 */
	getConfig(type, id) {
		const section = this.raw.content.config.content[type];
		if (!section) {
			throw new Error(`Config section ${type} wasn't found`);
		}
		const value = this.raw.content.config.content[type][id];
		const numberValue = Number(value);

		return isNaN(numberValue) ? value : numberValue;
	}

	/* eslint-disable no-magic-numbers */
	/**
	 * @param {number} number .
	 * @returns {string} .
	 */
	formatGameNumber(number) {
		// Хз насколько `new` миллион раз в такт полезно для памяти
		const str = new Decimal(number).toFixed(0);

		// Ммм.. Есть куда менее идиотский способ это посчитать:

		// < 999999
		const len = 3;
		if (str.length <= len) {
			return str;
		}

		const names = this.getConfig('game', 'scales_names');
		const zeroes = str.length - len - 1;
		const nameIndex = Math.floor(zeroes / 3);
		const numberLen = (zeroes % 3) + 1;
		let numberName = "";
		if (nameIndex <= 3) {
			numberName = names[nameIndex];
		} else {
			let index1 = Math.floor((nameIndex - 4) / BIGNUMBER_NUMBER_LETTERS.length);
			let index2 = (nameIndex - 4) % BIGNUMBER_NUMBER_LETTERS.length;
			numberName = BIGNUMBER_NUMBER_LETTERS[index1] + BIGNUMBER_NUMBER_LETTERS[index2];
		}

		const text = `${str.substr(0, numberLen)}.${str.substr(numberLen, 2)} ${numberName}`;

		return text;
	}
	/* eslint-enable no-magic-numbers */
}
