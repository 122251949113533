/**
 * @File TutorialView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import SettingsView from './SettingsView.js';
import { storage } from '../../js-client-utils/lib/storage.js';

const STORAGE_PAGE_KEY = 'itd_tutorial_page_index';
const TUTORIAL_PAGES_COUNT = 6;

export default class TutorialView extends ViewUiBase {
	static getCurrentPageIndex() {
		let currentPageIndex = null;
		if (storage.available()) currentPageIndex = storage.get(STORAGE_PAGE_KEY);
		return Number(currentPageIndex);
	}

	static isTutorialInProgress() {
		let pageIndex = TutorialView.getCurrentPageIndex();
		return 1 <= pageIndex && pageIndex <= TUTORIAL_PAGES_COUNT;
	}

	constructor(isOpenedFromSettings = false) {
		super();

		this.currentPageIndex = TutorialView.isTutorialInProgress() ? TutorialView.getCurrentPageIndex() : 1;
		this.container = document.querySelector('#tutorial');
		this.page = document.querySelector('#tutorial_page');
		this.isOpenedFromSettings = isOpenedFromSettings;
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'tutorial_button_prev':
				this.scrollTutorialPage(-1);
				break;
			case 'tutorial_button_next':
				this.scrollTutorialPage(+1);
				break;
		}
	}

	/**
	 * @override
	 */
	show() {
		this.container.classList.remove('hidden');
		this.setTutorialPageImage();
	}

	/**
	 * @override
	 */
	hide() {
		this.container.classList.add('hidden');
		if (this.isOpenedFromSettings) {
			AppCore.instance.views.push(new SettingsView(true));
		}
	}

	scrollTutorialPage(scrollDelta) {
		if (this.currentPageIndex + scrollDelta > TUTORIAL_PAGES_COUNT) {
			if (storage.available()) storage.remove(STORAGE_PAGE_KEY);
			AppCore.instance.views.pop();
			return;
		}

		if (this.currentPageIndex + scrollDelta <= 0) {
			return;
		}

		this.currentPageIndex += scrollDelta;
		this.setTutorialPageImage();
	}

	setTutorialPageImage() {
		if (storage.available()) storage.save(STORAGE_PAGE_KEY, this.currentPageIndex);
		this.page.style.backgroundImage = `url(./res/img/tutorial/tutorial_page_${this.currentPageIndex}.jpg)`;
	}
}
