import effectsPos from '../styles/dust/effects-pos.json';

export function generateFloorEffects(element, name) {
	//element.innerHTML = '';
	if (0 === element.innerHTML.length) return;
	for (const k in effectsPos) {
		const eff = effectsPos[k];
		if (eff.parent !== name) {
			continue;
		}

		element.innerHTML += `<sprite class='${eff.name}'></sprite>`
	}
}
