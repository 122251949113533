/**
 * @File QuestsListView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
/* eslint-disable max-classes-per-file */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { getDustDataElements } from '../dust/ui_utils.js';
import { action, setHeaderTitle, config, sound, inventoryGet } from '../shorthands.js';
import TabsManager from '../dust/TabsManager.js';
import ItemCard from '../generic/ItemCard.js';
import RewardView from './RewardView.js';
import HintView from './HintView.js';
import { ACTION_CODES } from '../tovdef/GameActions.js';

const _cachedRelicItdItemCard = new ItemCard({ content: {} });

/**
 * Game root view
 */
export default class ShopView extends ViewUiBase {
	/* eslint-disable complexity */
	/**
	 * _click.
	 *
	 * @param {InputEvent} e .
	 */
	_click(e) {
		if (e.target.id.length > 0) {
			AppCore.instance.logOnServer("user_tap", { name: e.target.id }, true);
		}

		var actionData = {};

		switch (e.target.id) {
			case 'hint':
				AppCore.instance.views.push(new HintView('hint_shop'));
				break;
			case 'shop_buy_coffee_btn':
				action('buy_coffee', {
					amount: Number(e.target.dataset.amount)
				});
				this.renderTabs();
				sound('spend_coin_0');			
				break;
			case 'use_lootpack_btn':
				const activePsources = AppCore.instance.game.inventory.psources.filter(
					psource => psource.active
				);
				if (activePsources.length === 0) break; 

				let actionCode = action('open_lootpack', { id: e.target.dataset.lootpack, paid: true });
				this.renderTabs();
				if (actionCode === ACTION_CODES.FAIL) break;
				AppCore.instance.views.push(new RewardView(inventoryGet(e.target.dataset.lootpack).icon));
				break;
			case 'buy_relic':
				action('buy_relic');
				this.renderTabs();
				sound('spend_coin_0');
				break;
			case 'upgrade_relic':
				action('upgrade_relic', { id: e.target.dataset.itemid });
				this.renderTabs();
				sound('spend_coin_1');
				break;
			case 'close_popup':
				if ((AppCore.instance.views.top() instanceof ShopView)) {
					e.stopPropagation();
					AppCore.instance.restoreRootView();
				}
				break;
			default:
				break;
		}
	}
	/* eslint-enable complexity */

	/**
	 * show.
	 */
	show() {
		setHeaderTitle('Магазин');

		const shell = AppCore.instance.itd5ka.shell;
		shell.backgorundStyle('default');
		const element = shell.popup('shop', false);
		this.container = element;
		//document.getElementById('shop_btn').classList.add('selected');
		//setHeaderTitle('Магазин');

		this.tabs = new TabsManager();
		this.tabs.init(element.querySelector('tabs#buttons'), element.querySelector('tabs#content'));

		if (this.tabsSelectedTabId) {
			this.tabs.selectTab(this.tabsSelectedTabId);
		}

		this.renderTabs();
		
		this.subscribe();
	}

	renderTabs() {
		this.renderChestsTab(this.container);
		this.renderRelicsTab(this.container);
		this.renderCoffeeTab(this.container);
	}

	subscribe() {
		this.unSubscribe();
		this.redraw_offices = AppCore.instance.events.on('redraw_offices', () => {
			console.log('update shop on redraw_offices');
			renderTabs();
		}, this).func;
	}

	unSubscribe() {
		if (this.redraw_offices) AppCore.instance.events.off('redraw_offices', this.redraw_offices);
		this.redraw_offices = null;
	}

	/**
	 * renderCoffeeTab.
	 *
	 * @param {HTMLElement} container .
	 */
	renderCoffeeTab(container) {
		const tab = container.querySelector('tab#coffee');
		tab.innerHTML = '';

		const variants = config('coffee_amount');
		const costs = config('coffee_cost');
		for (const i in variants) {
			const amount = variants[i];
			const enough = AppCore.instance.game.actions.testSpend('gems', costs[i]);
			tab.innerHTML += `
					<entry>
						<div class="shop_entry_icon_wrapper">
							<icon class='sprite-textures textures-icon-pointer_disabled textures-icon-shop_item textures-coffee_${i}'></icon>
						</div>
						<button id='shop_buy_coffee_btn' data-amount='${amount}' class='${enough ? '' : 'disabled'} buy'>
							<a>${costs[i]}</a>
						</button>
						<label id='shop_buy_coffee_btn' class="shop_entry_label">${amount} кофе</label>
					</entry>
				`;
		}
	}

	/**
	 * renderChestsTab.
	 *
	 * @param {HTMLElement} container .
	 */
	renderChestsTab(container) {
		const tab = container.querySelector('tab#chests');
		tab.innerHTML = '';
		const inventory = AppCore.instance.game.getData('inventory').content;

		for (const k in inventory) {
			const item = inventory[k];
			if (item.type === 'lootpack' && !item.internal) {
				const enough = AppCore.instance.game.actions.testSpend('gems', item.cost);
				let spec = 'managers';
				let etype = 'employee';
				let etitle = 'Все менеджеры достигли максимального уровня';
				if (AppCore.instance.game.actions.isLootpackHasPool(item.id, 'gadgets')) {
					spec = 'gadgets';
					etype = 'gadget';
					etitle = 'Всё оборудование достигло максимального уровня';
				}
				const maxed = AppCore.instance.game.actions.isLootpackItemsMaxed(item.id, spec, etype);
				tab.innerHTML += `
					<entry${maxed ? ' hover-label=\'' + etitle + '\'' : ''}>
						<div class="shop_entry_icon_wrapper">
							<icon class='sprite-textures textures-icon-pointer_disabled textures-icon-shop_item textures-${item.icon}-128px ${maxed ? 'disabled' : ''}'></icon>
						</div>
						<button id='use_lootpack_btn' data-lootpack='${k}' class='${enough && !maxed ? '' : 'disabled'} buy'>
							<a>${item.cost}</a>
						</button>
						<label class='shop_entry_label ${item.rarity ? 'rarity ' + item.rarity : ''}'>${item.name}</label>
					</entry>
				`;
			}
		}
	}

	/**
	 * renderRelicsTab.
	 *
	 * @param {HTMLElement} container .
	 */
	renderRelicsTab(container) {
		const tab = container.querySelector('tab#relics');
		tab.innerHTML = '';

		this.relics = [];
		const make = (item, cost, act = 'upgrade_relic') => {
			const enough = AppCore.instance.game.actions.testSpend('gems', cost);

			let upgText = '';
			let hoverText = item.description;
			if (item.level) {
				// a.
				_cachedRelicItdItemCard.clearCache();
				_cachedRelicItdItemCard.raw.content.level = item.level;
				_cachedRelicItdItemCard.raw.content.effectValue = item.effectValue;
				let vala = _cachedRelicItdItemCard.property('effectValue');
				_cachedRelicItdItemCard.clearCache();
				_cachedRelicItdItemCard.raw.content.level += 1;
				let valb = _cachedRelicItdItemCard.property('effectValue');
				if (vala < 1) {
					vala = 1 / vala;
				}
				if (valb < 1) {
					valb = 1 / valb;
				}

				// b.

				hoverText += `: ×${AppCore.instance.game.formatGameNumber(vala)}.\n\nСледующий уровень: ×${AppCore.instance.game.formatGameNumber(valb)}`;
				hoverText += enough ? '' : '\n\nДля покупки не хватает кристаллов';
			}

			const level = item.level ? `<label id='level'>ур.${item.level}</label>` : "";
			const entry = `
					${level}
					<div class="shop_entry_icon_wrapper">
						<icon class='sprite-textures textures-icon-pointer_disabled textures-icon-shop_item textures-${item.icon}'></icon>
					</div>
					<button id='${act}' data-itemid='${item.id}' class='${enough ? '' : 'disabled'} buy'>
						<a>${AppCore.instance.game.formatGameNumber(cost)}</a>
					</button>
					<label class="shop_entry_label" id='name'>${item.name ? item.name : 'Заказать новый апгрейд'}</label>
			`;
			const el = document.createElement('entry');
			el.classList.add('disabled');
			el.innerHTML = entry;
			el.setAttribute('hover-label', hoverText);
			tab.appendChild(el);
			this.relics.push(el);
		};

		// TODO: crap
		setTimeout((() => {
			for (const k in this.relics) {
				const entry = this.relics[k];
				entry.classList.remove('disabled');
			}
		}).bind(this), 100);

		const buycost = config('relic_cost');
		if (buycost !== null) {
			make(
				{ description: 'Заказать новый апгрейд', icon: 'dice_question-green', id: '' },
				buycost,
				'buy_relic'
			);
		}

		const inventory = AppCore.instance.game.getData('inventory').content;
		for (const k in inventory) {
			const item = inventory[k];
			if (item.type === 'relic' && item.active) {
				const ibcost = AppCore.instance.game.inventory.getItdItem(k).property('cost');
				make(item, ibcost);
			}
		}
	}

	/**
	 * hide.
	 */
	hide() {
		this.unSubscribe();
		const shell = AppCore.instance.itd5ka.shell;
		shell.popupHide();
		this.tabsSelectedTabId = this.tabs.selectedTab.id;
		this.tabs.dispose();
		this.tabs = null;
	}
}
