const SERVER_PATH = 'https://client.hr5.metacorp.gg/';
const SERVER_API = 'api/v1/';
const STORAGE_PATH = 'https://9c8384ef-4d61-47ed-862b-ed2b08ecff50.selcdn.net/res/video/';

const DEVPANEL = false;

export default {
    SERVER_PATH: SERVER_PATH,
    SERVER_API: SERVER_API,
    STORAGE_PATH: STORAGE_PATH,

    DEVPANEL: DEVPANEL
};