/**
 * @File Itd5kaShell.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Itd5ka
 */

import markdownIt from 'markdown-it';
import InputsManager from '../game/InputsManager.js';
import { EventsManager } from '../core/events.js';
import Itd5kaJournalEntry from './Itd5kaJournalEntry.js';
import DebugDashboard from '../dust/debugdashboard.js';
import AppCore from '../game/AppCore.js';
import Vk from '../thirdparty/Vk.js';
import settings from '../game/settings.js';

const markdownit = markdownIt({
	linkify: false
});
const METAM = true;

function sound(name, volume = 1, themeFade = 0) {
	app.sounds.play(name, volume, themeFade);
}

function soundStop(name) {
	app.sounds.stop(name);
}

/**
 * GUI logic for 5ka_elements.pug
 */
export default class Itd5kaShell {
	/**
	 * @param {HTMLElement} container shell HTML container
	 * @param {Ticker} ticker .
	 */
	init(container, ticker) {
		this.cache = {};

		this.ticker = ticker;

		this.scaleFactor = 1;
		this.iphoneNotch = 0.5;

		this.container = container;
		this.events = new EventsManager();
		this.inputs = InputsManager.instance;
		this.inputs.initOnce();
		this.effects = new GuiEffects(this);
		this.debugdashboard = new DebugDashboard().init(document.querySelector('dashboard#debug'));

		// Page elements
		this.html = {};
		this.mmenu = [];

		// Storyline queue
		this.cutscenes = [];

		this.sync();

		this.log(`✨ log start ${new Date().toLocaleString()}`);

		this.run();

		if (METAM) {
			this.print('One', 'gold');
			this.print('two', 'gems');
			this.print('3', 'coffee');
			this.print('Itd5kaShell', 'itd_main', 'button');
			this.print('init.', 'itd_status');
		}

		this.popup('itd_pvp_results', true); // shady popup init
	}

	_set_window_width() {
		this.window_width = window.devicePixelRatio * window.innerWidth;
		let width1 = 2400 / window.devicePixelRatio;
		let width2 = 1200 / window.devicePixelRatio;
		this.vw = window.innerWidth / 100;
		if (window.innerWidth < width1) {
			this.html.body.style.setProperty('--width', '45vw');
		}
		if (window.innerWidth < width2) {
			this.html.body.style.setProperty('--width', '90vw');
			this.html.body.style.setProperty('--font-size', (this.vw * 2) + 'px');
		}
		if (window.innerWidth > width2) {
			this.html.body.style.setProperty('--width', '70vw');
			this.html.body.style.setProperty('--font-size', (this.vw * 1) + 'px');
		}
		if (window.innerWidth > width1) {
			this.html.body.style.setProperty('--width', '35vw');
		}
	}

	_set_scale() {
		let scale = this.window_width / 1280 * 0.8;
		this.html.body.style.setProperty('--scale', scale);
		console.log(`set scale: ${scale}`);
		this.html.container_psource.style.setProperty('--decor-scale-js', Math.min(window.innerWidth / 1280 * 0.8, 1));
		this.html.menu.style.setProperty('--button-scale-js', scale);
		scale += 0.2;
		this.html.header.style.setProperty('--scale-js', scale > 1 ? 1 : scale);
		this.html.controls.style.setProperty('--scale-js', scale > 1 ? 1 : scale);

		scale = window.innerWidth / 1280;
		this.html.button_upgrade.style.setProperty('--button-scale-js', scale);
		this.html.psource_info_profit.style.setProperty('--button-scale-js', (2 - scale) / 5);

		this.html.popup.style.setProperty('--scale-js', scale);
	}

	set_platform_scale() {
		if (Vk.instance.available && Vk.instance.is_iphone || AppCore.instance.is_iphone()) {
			this.html.header.style.setProperty('--iphone-header', 2.4);
			this.html.header_background.style.setProperty('--iphone-header', 2.4);
			this.iphoneNotch = 1;
		} else {
			this.html.header.style.setProperty('--iphone-header', 1.4);
			this.html.header_background.style.setProperty('--iphone-header', 1.4);
		}
	}

	/* eslint-disable max-lines-per-function */
	/**
	 * Updates HTML elements
	 *
	 * ~Periodic calls not gonna hurt.
	 * But you did something wrong if you call it twice~
	 */
	sync() {
		const _qsearch = (query) => this.container.querySelector(query);
		const _docsearch = (query) => document.querySelector(query);

		this.html = {
			// Header
			// [
			label_gold: _qsearch('label#gold'),
			label_gems: _qsearch('label#gems'),
			label_coffee: _qsearch('label#coffee'),

			button_journal: _qsearch('button#journal'),
			button_settings: _qsearch('button#settings'),
			header: _qsearch('itd5ka#header.container'),
			header_background: _qsearch("decor#header_background"),
			controls: _qsearch('#controls.container'),
			/*
			button_minify: _qsearch('button#change_size_mode.minify'),
			button_maxify: document.querySelector('button#change_size_mode.maxify'),
			label_minified_gold: document.querySelector('itd5ka#minified label#gold'),
			label_minified_gems: document.querySelector('itd5ka#minified label#gems'),
			label_minified_coffee: document.querySelector('itd5ka#minified label#coffee'),
			*/
			// ]
			//
			// Window
			// [
			popup_container: _qsearch('popup.container'),
			popup_footer: _qsearch('popup_footer'),
			popup_inner: _qsearch('popup_inner'),
			button_close_popup: _qsearch('button#close_popup'),
			page_shell_log: _qsearch('page#shell_log'),
			page_settings: _qsearch('page#settings'),
			page_journal: _qsearch('page#journal'),
			page_shop: _qsearch('page#shop'),
			page_inventory: _qsearch('page#inventory'),
			page_itd_reset: _qsearch('page#itd_reset'),
			page_itd_employee_details: _qsearch('page#itd_employee_details'),
			page_itd_room_details: _qsearch('page#itd_room_details'),
			page_itd_battle_prepare: _qsearch('page#itd_battle_prepare'),
			page_itd_pvp_prepare: _qsearch('page#itd_pvp_prepare'),
			page_itd_pvp_results: _qsearch('page#itd_pvp_results'),
			page_itd_reward: _qsearch('page#itd_reward'),
			page_hint_battle: _qsearch('page#hint_battle'),
			page_hint_pvp: _qsearch('page#hint_pvp'),
			page_hint_inventory: _qsearch('page#hint_inventory'),
			page_hint_shop: _qsearch('page#hint_shop'),
			page_hint_reset: _qsearch('page#hint_reset'),
			page_hint_hello: _qsearch('page#hint_hello'),
			template_jentry: _qsearch('itd5ka.journal_entry.template'),
			page_message: _qsearch('page#message'),
			text_message: _qsearch('page#message text.container'),
			header_message: _qsearch('page#message label#header'),
			decor_sign_paper: _qsearch('decor#itd5ka_paper'),
			lable_sign_paper: _qsearch('decor#itd5ka_paper'),
			// ]
			//
			// Cutscene
			// [
			cutscene_container: _qsearch('cutscene#game_cutscene.container'),
			label_cutscene_title_a: _qsearch('label#storyline_title'),
			storyline_reward_container: _qsearch('div#storyline_reward_container'),
			button_storyline_reward: _qsearch('button#storyline_reward'),
			label_cutscene_title_b: _qsearch('label#storyline_title_right'),
			text_cutscene: _qsearch('cutscene#storyline_text.content'),
			sprite_cutscene_a: _qsearch('sprite#storyline_sprite'),
			sprite_cutscene_b: _qsearch('sprite#storyline_sprite_right'),
			button_cutscene_skip: _qsearch('button#storyline_skip'),
			// ]
			//
			// Profit source
			// [
			container_psource: _qsearch('itd5ka#psources.container'),
			template_psource: _qsearch('itd5ka#psource.template'),
			// ]
			// Menu
			// [
			container_menu: _qsearch('itd5ka#menu'),
			menu: _qsearch('itd5ka#menu.container'),
			button_inventory: _qsearch('button#inventory'),
			button_shop: _qsearch('button#shop'),
			button_itd_main: _qsearch('button#itd_main'),
			label_itd_status: _qsearch('label#itd_status'),
			button_itd_reset: _qsearch('button#itd_reset'),
			button_pvp: _qsearch('button#pvp'),
			// ]
			// Other
			// [
			button_location_next: _qsearch('button#location_next'),
			button_location_prev: _qsearch('button#location_prev'),
			button_battle: _qsearch('button#battle_start'),
			buy_all_prev_hint: _qsearch('label#buy_all_prev_hint'),
			decor_background: _qsearch('decor#game_background'),
			decor_foreground: _qsearch('decor#game_foreground'),
			decor_temporal_elements: _qsearch('decor#temporal_elements'),
			container_pvp_span: _qsearch('itd5ka#pvp_progress_span.container'),
			button_pvp_start: _qsearch('button#pvp_start'),
			button_pvp_stop: _qsearch('button#pvp_stop'),
			label_pvp_timer: _qsearch('label#pvp_timer'),

			button_upgrade: _qsearch('button#psource-upgrade'),
			psource_info_profit: _qsearch('decor#psource-info-profit'),
			popup: _qsearch('popup'),

			syserrorpanel: _docsearch('div#syserrorpanel'),

			body: document.body
			//]
		};

		this.mmenu = [
			this.html.button_inventory,
			this.html.button_shop,
			this.html.button_itd_main,
			this.html.label_itd_status,
			this.html.button_itd_reset,
			this.html.button_pvp,
		];

		for (const key in this.html) {
			if (!this.html[key]) {
				throw new Error(`${key} element got lost`);
			}
		}

		this.cache.window_pages = this.html.popup_inner.querySelectorAll('page');
	}
	/* eslint-enable max-lines-per-function */

	/**
	 * Inits events for all elements on page
	 */
	run() {
		this.events.on('user_click', ({ detail, key, args }) => {
			switch (key) {
				/*
				case 'button_close_popup':
					//this.popupHide();
					break;
				case 'button_settings':
					//this.popup('shell_log');
					break;
				case 'button_minify':
					this.changeSizeMode(1);
					break;
				case 'button_maxify':
					this.changeSizeMode(0);
					break;
				*/
				default:
					break;
			}

			if (METAM) {
				this.logClick(`🖱️ user click: ${key}`, key, detail);
			}

			this.events.emit('user_click-' + key);

			// sound('click', 0.5);
		});

		for (const key in this.html) {
			const el = this.html[key];
			el.addEventListener('game_input_click', ({ detail }) => {
				this.events.emit('user_click', { detail, key });
			});
		}

		// css - resize decor elements
		{
			const refSize = window
				.getComputedStyle(this.html.container_psource)
				.getPropertyValue('--background_width_px');

			const resize = () => {
				const factor = this.html.container_psource.clientWidth / refSize;
				this.scaleFactor = factor;
				this._set_window_width();
				this._set_scale();
			};
			this.events.on('resize', resize);
			resize();
		}
	}

	deselectMenu() {
		for (const i in this.mmenu) {
			const b = this.mmenu[i];
			b.classList['remove']('selected');
		}
	}

	selectMenuElements(e) {
		this.deselectMenu();
		for (const i in e) {
			const b = e[i];
			b.classList['add']('selected');
		}
	}

	resetMenuElements() {
		this.deselectMenu();
		this.html.button_itd_main.classList['add']('selected');
		this.html.label_itd_status.classList['add']('selected');
	}

	/**
	 * Prints cutscene
	 *
	 * @param {object} opts .
	 * @param {Array<string>} opts.text storyline text.
	 * @param {string} opts.title storyline title
	 * @param {string} opts.sprite storyline sprite class
	 * @param {function} opts.callback triggers after cutscene finish
	 */
	cutscenePush(opts) {
		const nextFrame = () => {
			this.log(`Frame draw requested.`);

			if (!this.cutscenes.length) {
				this.log(`No cutscene in queue. Return..`);
				this.cutsceneHide();

				return;
			}

			const cutscene = this.cutscenes[0];

			this.log(
				`${cutscene.titleA || cutscene.titleB} says.. Frames: ${opts.text.length}. Queue: ${
					this.cutscenes.length
				}`
			);

			console.log('cutscene push', cutscene, AppCore.instance.video.is_playing());
			
			if (cutscene.video === null) {
				const video = AppCore.instance.views.root().cutscenesQueueVideo();
				if (video != null) {
					const vsrc = `${settings.STORAGE_PATH}${video}.mp4`;
					if (vsrc !== AppCore.instance.video.src) {
						//console.log('video reset', AppCore.instance.video.is_preloaded(), AppCore.instance.video.src);
						AppCore.instance.video.stop();
					}
					console.log('video set preload', AppCore.instance.video.is_preloaded(), AppCore.instance.video.src);
					AppCore.instance.video.preload(vsrc, '90%');
					console.log('video preloading', AppCore.instance.video.is_preloaded(), AppCore.instance.video.src);
				} else {
					//console.log('video reset', AppCore.instance.video.is_preloaded(), AppCore.instance.video.src);
					AppCore.instance.video.stop();
				}
			}

			this.cutsceneFrame(cutscene, () => {
				if (!cutscene.text.length) {
					this.log(`Cutscene frames list is empty. Switching to next cutscene.`);

					// Местами не менять
					cutscene.callback(); // Тут могут добавится новые катсцены
					this.cutscenes.shift();
				}

				nextFrame();
			}, () => {
				cutscene.video = null;
			});
		};

		this.cutscenes.push(opts);
		this.log(
			`Pushed cutscene with ${opts.text.length} frames. Queue length: ${this.cutscenes.length}`
		);
		if (this.cutscenes.length === 1) {
			this.log(`Only one cutscene in queue. Starting sequence..`);
			sound('ambience-0', 0.7, 0.5);

			nextFrame();
		}
	}

	/* eslint-disable complexity */
	/**
	 * @param {object} frame .
	 * @param {object} frame.text removes elements inplace
	 * @param {string} frame.titleA .
	 * @param {string} frame.titleB .
	 * @param {string} frame.spriteA .
	 * @param {string} frame.spriteB .
	 * @param {string} frame.spriteMuted .
	 * @param {Function} callback .
	 * @returns {string?} popped text string
	 */
	cutsceneFrame({ text, titleA, titleB, spriteA, spriteB, video, video_gems_reward, spriteMuted } = frame, callback, vcallback) {
		this.html.cutscene_container.classList.remove('hidden');

		const message = text.shift();
		const formatted = markdownit.render(message);

		// Произошел оверинженеринг на уровне понимания задачи. wasted
		this.html.label_cutscene_title_a.innerHTML = titleA || '';
		this.html.label_cutscene_title_b.innerHTML = titleB || '';
		this.html.label_cutscene_title_a.classList[titleA ? 'remove' : 'add']('hidden');
		this.html.label_cutscene_title_b.classList[titleB ? 'remove' : 'add']('hidden');
		this.html.sprite_cutscene_a.classList.value = '';
		if (spriteA) {
			this.html.sprite_cutscene_a.classList.add(`characters-${spriteA}`);
			this.html.sprite_cutscene_a.classList.add('left');
		}
		this.html.sprite_cutscene_b.classList.value = '';
		if (spriteB) {
			this.html.sprite_cutscene_b.classList.add(`characters-${spriteB}`);
			this.html.sprite_cutscene_b.classList.add('right');
		}

		this.print(formatted, 'cutscene', 'text');

		// Metalog 220316: Можно вписать сюда любой класс, не обязательно скрывать элементы
		if (spriteMuted) {
			this.html['sprite_cutscene_' + spriteMuted].classList.add('hidden');
			this.html['label_cutscene_title_' + spriteMuted].classList.add('hidden');
		}

		if (video) {
			this.html.storyline_reward_container.classList.remove(`hidden`);
			const reward_value = document.querySelector('div#video_reward_value');
			const reward_tip = document.querySelector('div#video_reward_tip');
			const play = this.html.button_storyline_reward;

			reward_value.innerHTML = `+${video_gems_reward}`;
			reward_tip.innerHTML = 'ПОСМОТРИ ВИДЕО И ПОЛУЧИ НАГРАДУ';
			
			let handler;
			/*play.addEventListener('click',  handler = () => {
				AppCore.instance.video.play(`./res/video/${video}.mp4`, '90%', video_gems_reward, () => {
					AppCore.instance.game.inventory.add('gems', video_gems_reward);
				});
				play.removeEventListener('click', handler);
			});*/
			play.addEventListener('click',  handler = (() => {
				console.log('video click to run', AppCore.instance.video.is_preloaded(), AppCore.instance.video.src, AppCore.instance.video.video.src);
				if (AppCore.instance.video.is_preloaded()) {
					AppCore.instance.video.playPreloaded(video_gems_reward, () => {
						AppCore.instance.game.inventory.add('gems', video_gems_reward);
					});
				} else {
					const vsrc = `${settings.STORAGE_PATH}${video}.mp4`;
					AppCore.instance.video.play(vsrc, '90%', video_gems_reward, () => {
						AppCore.instance.game.inventory.add('gems', video_gems_reward);
					});
				}
				play.removeEventListener('click', handler);
				vcallback();
				this.html.storyline_reward_container.classList.add(`hidden`);
			}).bind(this));
		} else {
			AppCore.instance.video.unload();
			this.html.storyline_reward_container.classList.add(`hidden`);
		}

		let click_rejector = (e) => {
			if (AppCore.instance.video.is_playing() || this.html.button_storyline_reward == e.target) {
				this.inputs.events.once('game_input_click', (e) => {
					if(click_rejector(e)) callback();
				});
				return false;
			}
			return true;
		};
		this.inputs.events.once('game_input_click', (e) => {
			if(click_rejector(e)) callback();
		});

		return text;
	}
	/* eslint-enable complexity */

	/**
	 * Hides cutscene
	 */
	cutsceneHide() {
		this.html.cutscene_container.classList.add('hidden');
		soundStop('ambience-0');
	}

	/**
	 * @param {label} label .
	 * @param {string} text .
	 */
	popupMessage(label, text) {
		this.popup('message', false);
		this.print(text, 'message', 'text');
		this.print(label, 'message', 'header');
	}

	/**
	 * Shows page#id
	 *
	 * @param {string} page page id
	 * @param {boolean} [toggle=true] .
	 * @returns {HTMLElement} page element
	 */
	popup(page, toggle = true, disableAnimation = false) {
		const pageKey = 'page_' + page;
		const containerKey = 'popup_container';

		if (
			toggle &&
			!this.html[pageKey].classList.contains('hidden') &&
			!this.html[containerKey].classList.contains('hidden')
		) {
			this.popupHide(page);
			this.html.popup_footer.classList['add']('hidden');

			return this.html[pageKey];
		}

		this.cache.window_pages.forEach((el) => {
			el.classList.add('hidden');
		});

		this.html.popup_container.classList[disableAnimation ? 'remove' : 'add']('animated');

		this.html[pageKey].classList.remove('hidden');
		this.html[containerKey].classList.remove('hidden');

		const noFooter = page === 'shop' ||
					   page === 'inventory' ||
					   page === 'itd_reset' ||
					   page === 'itd_pvp_prepare';
		this.html.popup_footer.classList[noFooter ? 'add' : 'remove']('hidden');

		const message = this.log(`💬 page open: ${page}`);

		this.html.popup_container.id = page;

		sound('click_strong');

		return this.html[pageKey];
	}

	/**
	 * Creates new achievement entry in journal
	 *
	 * @param {string} id .
	 * @returns {Itd5kaJournalEntry} .
	 */
	constructJournalEntry(id) {
		const el = this.html.template_jentry.cloneNode(true);
		el.classList.remove('template');
		el.id = id;

		this.html.template_jentry.parentElement.appendChild(el);

		const wrapper = new Itd5kaJournalEntry(el);

		el.addEventListener('game_input_click', ({ detail }) => {
			this.events.emit('user_click', { detail, key: 'journal_entry', args: { id: el.id } });
		});

		return wrapper;
	}

	/**
	 * Sets background style
	 *
	 * @param {string} style style name
	 */
	backgorundStyle(style) {
		// disabled but supported
		//this.html.decor_background.classList.value = '';
		//this.html.decor_background.classList.add('style-' + style);
	}

	/**
	 * @param {string} key element key
	 * @param {boolean} visible .
	 */
	elementVisible(key, visible) {
		this.html[key].classList[visible ? 'remove' : 'add']('hidden');

		return this.html[key];
	}

	/**
	 * Check popup.container visibility
	 *
	 */
	isPopupHidden() {
		const containerKey = 'popup_container';
		return this.html[containerKey].classList.contains('hidden');
	}

	/**
	 * Hides popup.container
	 *
	 */
	popupHide() {
		const containerKey = 'popup_container';
		this.elementVisible(containerKey, false);
		this.events.emit('popup_hidden');
	}

	/**
	 * @param {number} mode 0,1. 0 for maxified. 1 for minified
	 */
	/*
	changeSizeMode(mode) {
		const maxified = document.querySelector('itd5ka#main');
		const minified = document.querySelector('itd5ka#minified');

		minified.classList[mode === 0 ? 'add' : 'remove']('hidden');
		maxified.classList[mode === 1 ? 'add' : 'remove']('hidden');
	}
	*/

	/**
	 * Sets label#id innerHTML
	 *
	 * @param {string} text text to set as innerHTML
	 * @param {string} id id in game-itd_5ka.pug
	 * @param {string} [_scope='label'] element type
	 */
	print(text, id, _scope = 'label') {
		let t = text;
		if (typeof text === 'number') {
			t = text.toFixed(2);
		}
		this.html[_scope + '_' + id].innerHTML = t;
	}

	/**
	 * Logs user click
	 * @param {string} text text to log
	 * @param {string} key click element id
	 * @param {object} detail click event
	 */
	logClick(text, key, detail) {
		const message = this.log(text);
		// message.addEventListener('game_input_hoverstart', () => {
		// 	detail.target.classList.add('highlighted');
		// });
		// message.addEventListener('game_input_hoverend', () => {
		// 	detail.target.classList.remove('highlighted');
		// });
		message.addEventListener('game_input_click', (opts) => {
			this.events.emit('user_click', { detail: opts.detail, key: `${key} message ... ` });
		});
	}

	/**
	 * Prints session log
	 *
	 * @param {string} text text to log
	 * @returns {HTMLElement} message .
	 */
	log(text) {
		const message = document.createElement('p');
		message.classList.add('log');
		message.innerHTML = text;
		this.html.page_shell_log.insertBefore(message, this.html.page_shell_log.firstChild);

		this.debugdashboard.log(text);

		return message;
	}
}

function GuiEffects(shell) {
	this.shell = shell;

	let lastTransitionAnimationId = null;
	this.animateTransition = (forwards, callback, callbackAnimation) => {
		const animation = `animate-${forwards ? 'forwards' : 'backwards'}`;
		shell.html.decor_foreground.classList.add(animation);

		shell.ticker.timeout(callback, 500);

		if (lastTransitionAnimationId) {
			delete shell.ticker.timeouts[lastTransitionAnimationId];
			lastTransitionAnimationId = null;
		}

		lastTransitionAnimationId = shell.ticker.timeout(() => {
			shell.html.decor_foreground.classList.remove(animation);
			delete shell.ticker.timeouts[lastTransitionAnimationId];
			lastTransitionAnimationId = null;
			if (callbackAnimation) callbackAnimation();
		}, 1000);
	};

	this.spawnDamageNumbers = (pos, text, obj) => {
		const classEl = obj != null ? obj : "";
		const el = document.createElement('damage_numbers');
		el.innerHTML = text;
		el.style.left = pos.x + 'px';
		el.style.top = pos.y + 'px';
		el.className = classEl;
		shell.html.decor_temporal_elements.appendChild(el);
		shell.ticker.timeout(() => {
			shell.html.decor_temporal_elements.removeChild(el);
		}, 700);
	};

	/**
	 *
	 * @param {object} pos .
	 * @param {string} type heal,attack,poison,boost,slowdown,freeze,notification
	 */
	this.spawnAreaEffect = (pos, type) => {
		const el = document.createElement('area_effect');
		el.classList.add(type);
		el.style.left = pos.x + 'px';
		el.style.top = pos.y + 'px';
		shell.html.decor_temporal_elements.appendChild(el);
		shell.ticker.timeout(() => {
			shell.html.decor_temporal_elements.removeChild(el);
		}, 1500);
	};
}
