/**
 * @File NotificationView.js
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { inventoryGet, action } from '../shorthands.js';

/**
 * Game root view
 */
export default class NotificationView extends ViewUiBase {
	constructor(data) {
		super();

		// TODO: move to config
		const achievements_config = [
			{
				id: 1,
				title: '"Сто обнулений"'
			}
		];

		if (data.id === 'achievements' && data.subid === 'achievements_inc') {
			const ach_cfg = achievements_config.find(a => a.id === data.data.id);
			this.message = `Достижение ${ach_cfg.title} улучшено!<br>Новое значение ${data.data.value}`;
		} else {
			this.message = 'unknown notification';
		}
	}
	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				AppCore.instance.views.pop();
				break;
		}
	}

	show() {
		AppCore.instance.itd5ka.shell.popupMessage(
			'Уведомление',
			this.message
		);
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
