import dbconf from '../../../res/game91de9fdd.json';
import HttpRequest from '../../core/HttpRequest.js';

let alternativeConfig = null;

/**
 * @returns {object} configuration file
 */
function get() {
	return alternativeConfig || dbconf;
}

/**
 * Sets new alternative config for game
 * @param {object} object object to use as config
 */
function set(object) {
	alternativeConfig = object;
}

/**
 * Downloads config from remote and sets as default
 *
 * @param {string} url url path
 * @returns {Promise} .
 */
function download(url) {
	return HttpRequest.request({ url }).then((data) => {
		alternativeConfig = data;
	})
}

/**
 * Discards alternative config
 */
function discard() {
	alternativeConfig = null;
}

export default {
	get,
	set,
	download,
	discard
};
