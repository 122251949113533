/**
 * #typedef
 */
export const StandardRawData = {
	content: {},
	meta: { guids: 0, id: '' }
};

/**
 * Makes standard data holding object
 *
 * @param {string} id object id
 * @param {object} content object content
 * @returns {StandardRawData} standard object
 */
export function constructStandardRawData(id = 'i0', content = {}) {
	return {
		content,
		meta: { guids: 0, id }
	};
}
