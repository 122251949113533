{
  "storylines": {
    "": {
      "titleA": null,
      "titleB": null,
      "text": null,
      "spriteA": null,
      "spriteB": null,
      "triggers": null,
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_1_01": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Сейчас ты познакомишься с первым менеджером, которому сможешь доверить управление этажом. В дальнейшем ты сможешь дополнить свою команду и другими менеджерами.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "office_1_1.active",
      "reward": "story_reward_00",
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_1_02": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Привет! Я – Эдуард Успехов, менеджер категории «Бакалея». Занимаюсь поиском поставщиков товаров для наших магазинов. Пойдём, сейчас всё тебе тут покажу.;;;Мы находимся в центральном офисе «Пятёрочки» в Москве, но это не единственный офис компании. У нас ещё есть офисы в крупных городах страны, например, в Санкт-Петербурге, Новосибирске, Краснодаре и других.;;;Молодые амбициозные сотрудники нужны всегда и везде. Начать свой карьерный путь можно в любом направлении, например, в маркетинге, HR, цепочках поставок, коммерции, аналитике, финансах.;;;Я выбрал коммерцию. Надеюсь, у меня всё получится!",
      "spriteA": "eduard-a",
      "spriteB": null,
      "triggers": "eduard_uspehow.level == 1",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_1_03": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Теперь давай расширим офис и откроем ещё два этажа. Для этого нажми на кнопку «Купить» в правом верхнем углу каждого из этажей. Кнопка становится активна, если заработанной валюты достаточно для покупки этажа.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_1_02.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_1_04": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Обрати внимание, в журнале 📋 появилась запись «Больше простора».",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_1_03.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_reward_notification_00": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Отлично, ты справился!\n Для продолжения нужно нажать кнопку журнала, затем выбрать задачу «Больше простора».",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_task_01.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_2_01": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Я никогда не сомневаюсь, куда идти за покупками.;;;У меня есть корпоративная скидка – 5% на все товары в «Пятёрочке», а также много разных выгодных предложений от партнёров.",
      "spriteA": "eduard-a",
      "spriteB": null,
      "triggers": "eduard_uspehow.level == 2",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_2_02": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Теперь, когда ты освоился с открытием этажей, настало время полностью отстроить первый офис и открыть второй. Для этого набери достаточное количество валюты (стоимость указана на первом этаже следующего офиса) и открой первый этаж второго офиса.;;;В журнале задач это задание называется «Расширение».",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_2_01.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_reward_notification_01": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Отлично, ты справился!\n Для продолжения нужно нажать на кнопку журнала, затем на задачу «Расширение».",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_task_02.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_3_01": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Изначально я пришёл в компанию на должность специалиста. Меньше чем за год получил первое повышение до менеджера.;;;«Пятёрочка» помогает расти и развиваться. Это является одним из приоритетов компании!;;;Около 80% директоров магазинов начинали свою карьеру с должности продавца-кассира или администратора.",
      "spriteA": "eduard-a",
      "spriteB": null,
      "triggers": "eduard_uspehow.level == 3",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_3_02": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Не забывай, что качество так же важно, как количество: повышая уровень открытых этажей, ты увеличиваешь их доходность.;;;Попробуй повысить уровень всех этажей второго офиса до 10. Для этого зарабатывай валюту, развивай все доступные этажи и нажимай на кнопку «Улучшить» на каждом из этажей (становится активна, когда валюты достаточно для развития этажа). \n Эта задача уже появилась в журнале и называется «Количество и качество».;;;И не забудь забрать награду!",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_3_01.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_reward_notification_02": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Ты отлично справился!\nДля продолжения нужно нажать на кнопку журнала, затем на задачу «Количество и качество».",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_task_03.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_4_01": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Кстати, в каждом городе можно построить целых пять офисов! Доходность каждого последующего офиса значительно выше предыдущего.;;;Это задача продвинутого уровня. Если ты с ней справишься, значит, у тебя хорошие шансы построить карьеру в большом бизнесе.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "eduard_uspehow.level == 4",
      "reward": null,
      "video": "3_fresh_perspectives",
      "video_gems_reward": 100
    },
    "story_frame_reward_notification_03": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Это потрясающе!\n Для продолжения нажми кнопку журнала, затем выбери задачу «Пять, как \"Пятёрочка\"».",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_task_04.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_5_01": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Вау, я только что получил предложение занять должность руководителя направления. Конечно, я его приму!",
      "spriteA": "eduard-a",
      "spriteB": null,
      "triggers": "eduard_uspehow.level == 5",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_5_02": {
      "titleA": "Дима Надёжный",
      "titleB": null,
      "text": "Эдуард, мы с командой поздравляем тебя с повышением!",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "story_frame_5_01.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_5_03": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Спасибо, друзья! По этому случаю угощаю всех потрясающе вкусными булочками и апельсиновым фрешем из «Пятёрочки».",
      "spriteA": "eduard-a",
      "spriteB": null,
      "triggers": "story_frame_5_02.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_01": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Ура! К команде управления цепочками поставок присоединился новый сотрудник – Дима Надёжный.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "office_2_2.active",
      "reward": "story_reward_01",
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_02": {
      "titleA": "Дима Надёжный",
      "titleB": null,
      "text": "Привет, ребята! Рад, что мне посчастливилось попасть в команду «Пятёрочки». Уже не терпится познакомиться со всеми.",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "dima_nadeshni.level == 1",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_03": {
      "titleA": null,
      "titleB": "Эдуард Успехов",
      "text": "Познакомиться со всеми – это очень амбициозная задача! В нашей команде 260 000 сотрудников.",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_dima_02.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_04": {
      "titleA": "Дима Надёжный",
      "titleB": null,
      "text": "Ого! Это же население целого города, такого как Великий Новгород или Таганрог.",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "story_frame_dima_03.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_05": {
      "titleA": null,
      "titleB": "Эдуард Успехов",
      "text": "Точно! Все мы обеспечиваем бесперебойную работу 19 тысяч магазинов по всей стране. Ежегодно в «Пятёрочке» совершается больше 5 миллиардов покупок.",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_dima_04.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_06": {
      "titleA": "Дима Надёжный",
      "titleB": null,
      "text": "Вот это масштаб!",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "story_frame_dima_05.completed",
      "reward": null,
      "video": "1_5ka_hr_brand_logistics",
      "video_gems_reward": 100
    },
    "story_frame_dima_07": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Менеджеры готовы начать свой первый спринт. Расставь их по этажам, запусти спринт и управляй задачами. Чтобы запустить спринт, нажми на кнопку «Начать спринты» внизу экрана и следуй инструкциям. Чтобы решить задачу из спринта, необходимо нажать на него несколько раз.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_dima_06.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_08": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Поздравляем с успешным выполнением спринта! Обрати внимание, что с каждым новым спринтом сложность возрастает, но увеличивается и награда.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "dima_nadeshni.level == 2",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_09": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Ты можешь запустить несколько спринтов одновременно: менеджерам будет непросто, но в случае успеха награда будет ещё ценнее!",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_dima_08.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_10": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Привет, Дима! По какому поводу ты сегодня в форме?",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_dima_09.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_11": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Привет! Я сегодня иду в магазин на стажировку в рамках программы адаптации. Мне сказали, что все офисные сотрудники должны знать бизнес-процессы магазина, чтобы правильно выстраивать свою работу.",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "story_frame_dima_10.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_12": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Это верно! Я тоже на прошлой неделе проходил. А на экскурсию в распределительный центр поедешь?",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_dima_11.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_13": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Обязательно! Это мне особенно интересно, я ведь работаю в дирекции управления цепочками поставок, которая отвечает за логистику.",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "story_frame_dima_12.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_14": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Удачной стажировки!",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_dima_13.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_15": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Пришло время испытать менеджеров: запусти три спринта одновременно. Но не забывай заботиться о своих сотрудниках: каждый клик по задачам спринта снижает их здоровье. Если уровень здоровья снизится до критической отметки, спринт будет завершён без получения бонусов и потребуется время, чтобы сотрудник набрал здоровье. Для повышения здоровья и защиты от задач используй кофе (кнопка «Защита» в верхнем левом углу экрана). Если она неактивна, необходимо купить кофе в магазине игры.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_dima_14.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_16": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "100 уровень – максимальное развитие этажа любого офиса. Инвестируй в развитие этажей, чтобы получить максимальную выгоду!",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "dima_nadeshni.level == 3",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_17": {
      "titleA": "Дима Надёжный",
      "titleB": null,
      "text": "Как странно. От одинаковых по формату магазинов поступили совершенно разные заказы. Интересно, с чем это может быть связано?",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "story_frame_dima_16.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_18": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Всё просто, Дима! Наша компания делает ставку на партнёрство: проект «Директор магазина-партнёр» наделяет директоров магазинов широкими полномочиями. По сути, они управляют магазином, как собственным бизнесом.",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_dima_17.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_19": {
      "titleA": "Дима Надёжный",
      "titleB": null,
      "text": "То есть они самостоятельно планируют развитие магазина?",
      "spriteA": "dima-a",
      "spriteB": null,
      "triggers": "story_frame_dima_18.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_20": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Да, они могут расширять штат сотрудников, управлять ассортиментом и даже менять график работы магазина. Это полноценное партнёрство!",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_dima_19.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_21": {
      "titleA": "Радио «Голос Пятёрочки»",
      "titleB": null,
      "text": "Самое время позаботиться не только об одном этаже, а о целом офисе! Попробуй улучшить все этажи второго офиса до 50 уровня, и Дима Надёжный перейдёт на следующий этап карьерного пути.",
      "spriteA": "mascot",
      "spriteB": null,
      "triggers": "story_frame_dima_20.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_22": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Дима, что ты делаешь сегодня вечером?",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "dima_nadeshni.level == 4",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_23": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Ещё не думал, а есть предложение?",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_dima_22.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_24": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Хочу пригласить тебя на репетицию творческого номера, который мы готовим к корпоративному мероприятию. Нам нужен парень, который умеет играть на электрогитаре. А я знаю, что ты в школе играл в музыкальной группе.",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_dima_23.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_25": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "С удовольствием присоединюсь к вам! Давно не выступал на сцене, буду рад вспомнить это чувство. Особенно приятно выступать в кругу коллег.",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_dima_24.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_26": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Дима, привет! Я как раз собирался зайти к тебе, только выкину мусор.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "dima_nadeshni.level == 5",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_27": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Постой, не выбрасывай всё в один контейнер! Разные виды отходов нужно выбрасывать в разные контейнеры.",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_dima_26.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_28": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Конечно, ты прав! Я просто ещё не успел к этому привыкнуть.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_dima_27.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_29": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Понимаю, но над экопривычками нужно активно работать. Забота об экологии – одно из важных направлений стратегии устойчивого развития нашей компании.",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_dima_28.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_30": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Зайди на сайт eco.5ka.ru и почитай, как просто заботиться о природе.",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_dima_29.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_dima_31": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Спасибо, обязательно сделаю это!",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_dima_30.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_01": {
      "titleA": "Виктория Творческая",
      "titleB": null,
      "text": "Привет! Я Вика, новый маркетолог, и у меня отличные новости!",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "newstats.resetsTriggered >= 1 and vika_tvorcheskaya.level == 1",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_02": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Добро пожаловать на борт, Вика! Делись скорее новостями!",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_vika_01.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_03": {
      "titleA": "Виктория Творческая",
      "titleB": null,
      "text": "«Пятёрочка» получила очередную награду в престижной премии за маркетинговую кампанию.",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "story_frame_vika_02.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_04": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Круто работать в компании, которая является одним из лидеров российского ритейла и чьи достижения так высоко оцениваются экспертами!",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_vika_03.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_05": {
      "titleA": "Виктория Творческая",
      "titleB": null,
      "text": "Я всегда с гордостью говорю, что работаю в «Пятёрочке».",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "story_frame_vika_04.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_06": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Алёна, привет! Хочу поделиться с тобой: вчера общалась с директором-партнёром одной «Пятёрочки» и оказалось, что ему всего 27 лет!",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "newstats.relicsPurchased == 1 and vika_tvorcheskaya.level == 2",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_07": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Да! Для участия в программе «Директор магазина-партнёр» нет возрастных ограничений. Это доступно в том числе молодым амбициозным управленцам.",
      "spriteA": null,
      "spriteB": "alena-a",
      "triggers": "story_frame_vika_06.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_08": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Круто! То есть партнёром может стать любой директор магазина?",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "story_frame_vika_07.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_09": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Мы проводим качественный отбор на основании необходимых компетенций. Если кандидат успешно себя проявил в ходе отбора, то мы готовы предложить ему эту роль.",
      "spriteA": null,
      "spriteB": "alena-a",
      "triggers": "story_frame_vika_08.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_10": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Здорово!",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "story_frame_vika_09.completed",
      "reward": null,
      "video": "2_5ka_hr_brand_retail",
      "video_gems_reward": 100
    },
    "story_frame_vika_11": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Вика, привет! Что читаешь?",
      "spriteA": "eduard-a",
      "spriteB": null,
      "triggers": "vika_tvorcheskaya.level == 3",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_12": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Новости компании в приложении «Моя работа».",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_vika_11.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_13": {
      "titleA": "Эдуард Успехов",
      "titleB": null,
      "text": "Я тоже читал на обеде. Удобно, что есть мобильное приложение для сотрудников, где можно и новости почитать, и отпуск перенести, и справки заказать, и ещё кучу всего сделать.",
      "spriteA": "eduard-a",
      "spriteB": null,
      "triggers": "story_frame_vika_12.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_14": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Это точно! Я каждое утро начинаю с чтения новостей.",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_vika_13.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_15": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Какая прекрасная погода сегодня! Так и хочется сделать что-нибудь доброе.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "vika_tvorcheskaya.level == 4",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_16": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Какое совпадение! Мне тоже. Я даже записалась волонтёром на «Корзину доброты».",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_vika_15.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_17": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Что ты будешь делать?",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_vika_16.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_18": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Рассказывать гостям наших магазинов, как они могут пожертвовать продукты нуждающимся, и собирать пожертвования в корзину.",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_vika_17.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_19": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Потом мы расфасуем собранное по наборам и передадим малообеспеченным семьям, одиноким бабушкам и дедушкам, а также животным в приютах.",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_vika_18.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_vika_20": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Здорово, что наша компания реализует такой социально значимый проект!",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_vika_19.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_01": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Всем привет! Меня зовут Алёна, сегодня мой первый день в «Пятёрочке».",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "alena_smehova.level == 1",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_02": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Добро пожаловать в команду! Мы как раз делимся историями, кто как устроился в компанию. Расскажешь свою?",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_alena_01.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_03": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Я нашла вакансию в интернете, откликнулась, на следующий день прошла собеседование с HR и руководителем – и вот, я тут! Так быстро.",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_alena_02.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_04": {
      "titleA": null,
      "titleB": "Виктория Творческая",
      "text": "Это классика! В магазины почти половина сотрудников устраиваются в день обращения.",
      "spriteA": null,
      "spriteB": "vika-a",
      "triggers": "story_frame_alena_03.completed",
      "reward": null,
      "video": "3_5ka_hr_brand_retail_intern",
      "video_gems_reward": 100
    },
    "story_frame_alena_05": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Вот это да! Чувствую, в HR «Пятёрочки» мне предстоит интересная работа.",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_alena_04.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_06": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Дима, ты неважно выглядишь. Что-то случилось?",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "alena_smehova.level == 2",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_07": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Да, в последнее время что-то нездоровится. Надо бы записаться на обследование, но боюсь, что в этом месяце не смогу записаться в свою поликлинику.",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_alena_06.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_08": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Не переживай! Воспользуйся полисом ДМС или сервисом телемедицины, если не хочешь тратить время.",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_alena_07.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_09": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Здорово, что компания предоставляет такие возможности! Пойду запишусь.",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_alena_08.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_10": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "«Пятёрочка» также предоставляет бесплатные и безлимитные онлайн-консультации с психологом, ведь ментальное здоровье ничуть не менее важно, чем физическое!",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_alena_09.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_11": {
      "titleA": null,
      "titleB": "Эдуард Успехов",
      "text": "Так, коллеги, было приятно с вами поработать, но, кажется, меня сейчас уволят...",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "alena_smehova.level == 3",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_12": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Так, спокойно! Что произошло?",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_alena_11.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_13": {
      "titleA": null,
      "titleB": "Эдуард Успехов",
      "text": "Я допустил ошибку в расчётах! Хорошо, что её вовремя заметили и исправили, но делать такие ошибки - непозволительно!",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_alena_12.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_14": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Вдохни, выдохни, успокойся. В «Пятёрочке» царит культура открытости и партнёрства. За рабочие ошибки не увольняют, и даже есть конкурс «Премия за ошибку».",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_alena_13.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_15": {
      "titleA": null,
      "titleB": "Эдуард Успехов",
      "text": "Как это – премия за ошибку?",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_alena_14.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_16": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Суть конкурса заключается в том, чтобы сотрудники делились историями своих рабочих ошибок и примерами их исправления с пользой для компании. Истории оценивают лично топ-менеджеры, а авторы лучших историй награждаются денежными призами.",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "story_frame_alena_15.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_17": {
      "titleA": null,
      "titleB": "Эдуард Успехов",
      "text": "Кажется, я понял: эти истории помогают другим сотрудникам не повторять ошибки, а главное – не бояться принимать решения!",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_alena_16.completed",
      "reward": null,
      "video": "5_5ka_hr_band_managers",
      "video_gems_reward": 100
    },
    "story_frame_alena_18": {
      "titleA": "Алёна Смехова",
      "titleB": null,
      "text": "Ребята, давайте проведём небольшую викторину! Помните ли вы пять ценностей «Пятёрочки»? Давайте я начну. Первая ценность – честность: проявление нулевой толерантности к коррупции и воровству, незлоупотребление служебным положением и следование принятым обязательствам.",
      "spriteA": "alena-a",
      "spriteB": null,
      "triggers": "alena_smehova.level == 4",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_19": {
      "titleA": null,
      "titleB": "Эдуард Успехов",
      "text": "Вторая – клиентоориентированность. Это когда мы ставим клиента и его интересы в центр всех решений.",
      "spriteA": null,
      "spriteB": "eduard-a",
      "triggers": "story_frame_alena_18.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_20": {
      "titleA": "Виктория Творческая",
      "titleB": null,
      "text": "Следующая – стремление к достижениям! В компании принято ставить амбициозные цели и проявлять настойчивость в их достижении даже при столкновении с трудностями.",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "story_frame_alena_19.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_21": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Уважение! Мы всегда вежливо общаемся друг с другом, гостями и партнёрами независимо от должности и положения, учитываем мнения, интересы и эмоции коллег и гостей.",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_alena_20.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_alena_22": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Моя любимая – командность. Ставить интересы команды выше своих личных, а интересы компании – выше интересов своего отдела, управления, департамента.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_alena_21.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_01": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Привет, команда! Я – Иван Продавцов, сотрудник дирекции поддержки бизнеса. Вы готовитесь к фотоконкурсу?",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "ivan_prodavcov.level == 1",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_02": {
      "titleA": null,
      "titleB": "Алёна Смехова",
      "text": "Какому ещё фотоконкурсу?",
      "spriteA": null,
      "spriteB": "alena-a",
      "triggers": "story_frame_ivan_01.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_03": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Который сейчас проходит в группе «Я в Пятёрочке» во «ВКонтакте». Там постоянно проходят разные конкурсы для сотрудников.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_ivan_02.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_04": {
      "titleA": null,
      "titleB": "Алёна Смехова",
      "text": "Это интересно! А кинь ссылку на группу.",
      "spriteA": null,
      "spriteB": "alena-a",
      "triggers": "story_frame_ivan_03.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_05": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Ловите: https://vk.com/turbofive",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_ivan_04.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_06": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Дима, ты же работаешь в цепочках поставок, верно? И тебе сейчас 24 года, я ничего не путаю?",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "ivan_prodavcov.level == 2",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_07": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Всё верно, а почему тебя это заинтересовало?",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_ivan_06.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_08": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Хочу предложить тебе интересную идею! Ты знал, что в «Пятёрочке» есть «Совет молодёжи»? Благодаря проекту можно одновременно выполнять свои основные задачи и стать, например, ведущим подкастов.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_ivan_07.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_09": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Сотрудники компании до 25 лет прокачивают лидерские, управленческие, проектные компетенции, реализуют проекты по разным направлениям, активно участвуют в корпоративной жизни. А ещё регулярно собираются на неформальные встречи.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_ivan_08.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_10": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Спасибо, очень интересная информация! Надо будет попробовать себя в этой роли!",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_ivan_09.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_11": {
      "titleA": "Виктория Творческая",
      "titleB": null,
      "text": "Коллеги, поздравьте, я решила заняться саморазвитием! Со следующего месяца запишусь на большой курс по маркетингу.",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "ivan_prodavcov.level == 3",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_12": {
      "titleA": null,
      "titleB": "Иван Продавцов",
      "text": "Вика, я надеюсь, что ты воспользовалась внутренними курсами компании?",
      "spriteA": null,
      "spriteB": "ivan-a",
      "triggers": "story_frame_ivan_11.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_13": {
      "titleA": "Виктория Творческая",
      "titleB": null,
      "text": "Конечно, за кого ты меня принимаешь?! Я прекрасно знаю, что «Пятёрочка» предоставляет сотрудникам широкие возможности для обучения и развития.",
      "spriteA": "vika-a",
      "spriteB": null,
      "triggers": "story_frame_ivan_12.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_14": {
      "titleA": null,
      "titleB": "Алёна Смехова",
      "text": "Верно!",
      "spriteA": null,
      "spriteB": "alena-a",
      "triggers": "story_frame_ivan_13.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_15": {
      "titleA": null,
      "titleB": "Иван Продавцов",
      "text": "А я постоянно пользуюсь корпоративной электронной библиотекой. Не нужно тратиться на покупку книг.",
      "spriteA": null,
      "spriteB": "ivan-a",
      "triggers": "story_frame_ivan_14.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_16": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Дима, помнишь, ты рассказывал мне про роботов-кладовщиков?",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "ivan_prodavcov.level == 4",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_17": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Конечно, помню. А что, ты до сих пор под впечатлением?",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_ivan_16.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_18": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Нет, я хотел рассказать тебе ещё более интересную историю об использовании технологий в «Пятёрочке»! Ты знал, что магазины «Пятёрочка #Налету» работают вообще без продавцов?",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_ivan_17.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_19": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "А кто же обслуживает покупателей? Роботы?",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_ivan_18.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_20": {
      "titleA": "Иван Продавцов",
      "titleB": null,
      "text": "Нет. Достаточно мобильного приложения и касс самообслуживания. А за наполнением полок через 15 камер следит специальная система, электронные ценники меняются автоматически.",
      "spriteA": "ivan-a",
      "spriteB": null,
      "triggers": "story_frame_ivan_19.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    },
    "story_frame_ivan_21": {
      "titleA": null,
      "titleB": "Дима Надёжный",
      "text": "Красота! Действительно, ритейл стал совершенно современным. Горжусь своей компанией!",
      "spriteA": null,
      "spriteB": "dima-a",
      "triggers": "story_frame_ivan_20.completed",
      "reward": null,
      "video": null,
      "video_gems_reward": null
    }
  },
  "achievements": {
    "story_task_01": {
      "instant": null,
      "title": "Больше простора",
      "description": "Купить второй и третий этажи первого офиса",
      "sprite": "develop",
      "triggers": "office_1_3.active",
      "displayOn": "story_frame_1_03.completed",
      "reward": "story_reward_00"
    },
    "story_task_02": {
      "instant": null,
      "title": "Расширение",
      "description": "Купить первый этаж второго офиса",
      "sprite": "develop",
      "triggers": "office_2_1.active",
      "displayOn": "story_frame_2_02.completed",
      "reward": "story_reward_00"
    },
    "story_task_03": {
      "instant": null,
      "title": "Количество и качество",
      "description": "Улучшить все этажи второго офиса до 10 уровня",
      "sprite": "develop",
      "triggers": "office_2_1.level >= 10 and\noffice_2_2.level >= 10 and\noffice_2_3.level >= 10 and\noffice_2_4.level >= 10 and\noffice_2_5.level >= 10 and\noffice_2_6.level >= 10 and\noffice_2_7.level >= 10 and\noffice_2_8.level >= 10",
      "displayOn": "story_frame_3_02.completed",
      "reward": "story_reward_00"
    },
    "story_task_04": {
      "instant": null,
      "title": "Пять как Пятёрочка",
      "description": "Купить первый этаж пятого офиса",
      "sprite": "develop",
      "triggers": "office_5_1.active",
      "displayOn": "story_frame_4_01.completed",
      "reward": "story_reward_00"
    },
    "story_task_dima_01": {
      "instant": null,
      "title": "Первый спринт",
      "description": "Успешно выполнить один спринт",
      "sprite": "develop",
      "triggers": "newstats.totalSprintsCompleted >= 1",
      "displayOn": "story_frame_dima_07.completed",
      "reward": "story_reward_01"
    },
    "story_task_dima_02": {
      "instant": null,
      "title": "Предел совершенства",
      "description": "Повысить уровень любого этажа до 100",
      "sprite": "develop",
      "triggers": "newstats.maxFloorLevel >= 100",
      "displayOn": "story_frame_dima_15.completed",
      "reward": "story_reward_01"
    },
    "story_task_dima_03": {
      "instant": null,
      "title": "Хорошая работа",
      "description": "Повысить уровни всех этажей второго офиса до 50",
      "sprite": "develop",
      "triggers": "office_2_1.level >= 50 and\noffice_2_2.level >= 50 and\noffice_2_3.level >= 50 and\noffice_2_4.level >= 50 and\noffice_2_5.level >= 50 and\noffice_2_6.level >= 50 and\noffice_2_7.level >= 50 and\noffice_2_8.level >= 50",
      "displayOn": "story_frame_dima_21.completed",
      "reward": "story_reward_01"
    },
    "story_task_dima_04": {
      "instant": null,
      "title": "Путь к успеху",
      "description": "Повысить уровни всех этажей четвёртого офиса до 50",
      "sprite": "develop",
      "triggers": "office_4_1.level >= 50 and\noffice_4_2.level >= 50 and\noffice_4_3.level >= 50 and\noffice_4_4.level >= 50 and\noffice_4_5.level >= 50 and\noffice_4_6.level >= 50 and\noffice_4_7.level >= 50 and\noffice_4_8.level >= 50",
      "displayOn": "story_frame_dima_25.completed",
      "reward": "story_reward_01"
    },
    "story_task_vika_01": {
      "instant": null,
      "title": "Первое обнуление",
      "description": "Обнулить прогресс офисов и этажей: для этого нажмите на кнопку обнуления и подтвердите действие",
      "sprite": "develop",
      "triggers": "newstats.resetsTriggered >= 1",
      "displayOn": "story_task_03.completed",
      "reward": "story_reward_02"
    },
    "story_task_vika_02": {
      "instant": null,
      "title": "Драгоценный сундук",
      "description": "Купить любой сундук в Магазине",
      "sprite": "develop",
      "triggers": "newstats.lootpacksPurchased >= 1",
      "displayOn": "story_frame_vika_05.completed",
      "reward": "story_reward_02"
    },
    "story_task_vika_03": {
      "instant": null,
      "title": "Крепкий фундамент",
      "description": "Повысить уровни всех этажей первого офиса до 50",
      "sprite": "develop",
      "triggers": "office_1_1.level >= 50 and\noffice_1_2.level >= 50 and\noffice_1_3.level >= 50 and\noffice_1_4.level >= 50 and\noffice_1_5.level >= 50 and\noffice_1_6.level >= 50 and\noffice_1_7.level >= 50 and\noffice_1_8.level >= 50",
      "displayOn": "story_frame_vika_10.completed",
      "reward": "story_reward_02"
    },
    "story_task_vika_04": {
      "instant": null,
      "title": "Отличный профессионализм",
      "description": "Выполнить четыре спринта одновременно",
      "sprite": "develop",
      "triggers": "newstats.sprintsLaunchedAtSameTime == 4",
      "displayOn": "story_frame_vika_14.completed",
      "reward": "story_reward_02"
    },
    "story_task_vika_05": {
      "instant": null,
      "title": "Идеальная работа",
      "description": "Повысить уровни всех этажей второго офиса до 100",
      "sprite": "develop",
      "triggers": "office_2_1.level >= 100 and\noffice_2_2.level >= 100 and\noffice_2_3.level >= 100 and\noffice_2_4.level >= 100 and\noffice_2_5.level >= 100 and\noffice_2_6.level >= 100 and\noffice_2_7.level >= 100 and\noffice_2_8.level >= 100",
      "displayOn": "story_frame_vika_20.completed",
      "reward": "story_reward_02"
    },
    "story_task_alena_01": {
      "instant": null,
      "title": "Непростой вызов",
      "description": "Выполнить три спринта одновременно",
      "sprite": "develop",
      "triggers": "newstats.sprintsLaunchedAtSameTime == 3",
      "displayOn": "story_frame_dima_15.completed",
      "reward": "story_reward_03"
    },
    "story_task_alena_02": {
      "instant": null,
      "title": "Четвёртое испытание",
      "description": "Купить первый этаж четвёртого офиса",
      "sprite": "develop",
      "triggers": "office_4_1.active",
      "displayOn": "story_frame_alena_05.completed",
      "reward": "story_reward_03"
    },
    "story_task_alena_03": {
      "instant": null,
      "title": "Стабильность и уверенность",
      "description": "Повысить уровни всех этажей третьего офиса до 50",
      "sprite": "develop",
      "triggers": "office_3_1.level >= 50 and\noffice_3_2.level >= 50 and\noffice_3_3.level >= 50 and\noffice_3_4.level >= 50 and\noffice_3_5.level >= 50 and\noffice_3_6.level >= 50 and\noffice_3_7.level >= 50 and\noffice_3_8.level >= 50",
      "displayOn": "story_frame_alena_10.completed",
      "reward": "story_reward_03"
    },
    "story_task_alena_04": {
      "instant": null,
      "title": "Прекрасный результат",
      "description": "Повысить уровни всех этажей третьего офиса до 100",
      "sprite": "develop",
      "triggers": "office_3_1.level >= 100 and\noffice_3_2.level >= 100 and\noffice_3_3.level >= 100 and\noffice_3_4.level >= 100 and\noffice_3_5.level >= 100 and\noffice_3_6.level >= 100 and\noffice_3_7.level >= 100 and\noffice_3_8.level >= 100",
      "displayOn": "story_frame_alena_17.completed",
      "reward": "story_reward_03"
    },
    "story_task_alena_05": {
      "instant": null,
      "title": "Безупречная техника",
      "description": "Выполнить пять спринтов одновременно",
      "sprite": "develop",
      "triggers": "newstats.sprintsLaunchedAtSameTime == 5",
      "displayOn": "story_frame_alena_22.completed",
      "reward": "story_reward_03"
    },
    "story_task_ivan_01": {
      "instant": null,
      "title": "Новое начало",
      "description": "Купить второй этаж пятого офиса",
      "sprite": "develop",
      "triggers": "office_5_2.active",
      "displayOn": "story_frame_5_01.completed",
      "reward": "story_reward_04"
    },
    "story_task_ivan_02": {
      "instant": null,
      "title": "Предпоследний рубеж",
      "description": "Повысить уровни всех этажей четвертого офиса до 100",
      "sprite": "develop",
      "triggers": "office_4_1.level >= 100 and\noffice_4_2.level >= 100 and\noffice_4_3.level >= 100 and\noffice_4_4.level >= 100 and\noffice_4_5.level >= 100 and\noffice_4_6.level >= 100 and\noffice_4_7.level >= 100 and\noffice_4_8.level >= 100",
      "displayOn": "story_frame_ivan_05.completed",
      "reward": "story_reward_04"
    },
    "story_task_ivan_03": {
      "instant": null,
      "title": "Финишная прямая",
      "description": "Повысить уровни всех этажей пятого офиса до 50",
      "sprite": "develop",
      "triggers": "office_5_1.level >= 50 and\noffice_5_2.level >= 50 and\noffice_5_3.level >= 50 and\noffice_5_4.level >= 50 and\noffice_5_5.level >= 50 and\noffice_5_6.level >= 50 and\noffice_5_7.level >= 50 and\noffice_5_8.level >= 50",
      "displayOn": "story_frame_ivan_10.completed",
      "reward": "story_reward_04"
    },
    "story_task_ivan_04": {
      "instant": null,
      "title": "Абсолютное совершенство",
      "description": "Повысить уровни всех этажей пятого офиса до 100",
      "sprite": "develop",
      "triggers": "office_5_1.level >= 100 and\noffice_5_2.level >= 100 and\noffice_5_3.level >= 100 and\noffice_5_4.level >= 100 and\noffice_5_5.level >= 100 and\noffice_5_6.level >= 100 and\noffice_5_7.level >= 100 and\noffice_5_8.level >= 100",
      "displayOn": "story_frame_ivan_15.completed",
      "reward": "story_reward_04"
    },
    "story_task_ivan_05": {
      "instant": null,
      "title": "Несметное богатство",
      "description": "Заработать суммарно 10,000 алмазов",
      "sprite": "develop",
      "triggers": "gems.amount >= 10000",
      "displayOn": "story_frame_ivan_21.completed",
      "reward": "story_reward_04"
    }
  },
  "lootpacks": {
    "story_reward_00": {
      "name": null,
      "internal": true,
      "pool": [
        "eduard_uspehow"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": "1",
      "gold": null,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "story_reward_01": {
      "name": null,
      "internal": true,
      "pool": [
        "dima_nadeshni"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": "1",
      "gold": null,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "story_reward_02": {
      "name": null,
      "internal": true,
      "pool": [
        "vika_tvorcheskaya"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": "1",
      "gold": null,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "story_reward_03": {
      "name": null,
      "internal": true,
      "pool": [
        "alena_smehova"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": "1",
      "gold": null,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "story_reward_04": {
      "name": null,
      "internal": true,
      "pool": [
        "ivan_prodavcov"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": "1",
      "gold": null,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "lootpack_managers_common": {
      "name": "Менеджеры",
      "internal": null,
      "pool": [
        "pool_managers_common",
        "pool_managers_uncommon"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": 50,
      "weight": "4",
      "gold": 2,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": "0.75;2;1",
      "pool_managers_uncommon": "0.25;1;1",
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "lootpack_managers_rare": {
      "name": "Редкие менеджеры",
      "internal": null,
      "pool": [
        "pool_managers_rare"
      ],
      "icon": "lootpack-1",
      "rarity": "rare",
      "cost": 100,
      "weight": "5",
      "gold": 2,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": 1,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "lootpack_gadgets_common": {
      "name": "Оборудование",
      "internal": null,
      "pool": [
        "pool_common_gadgets"
      ],
      "icon": "lootpack-3",
      "rarity": null,
      "cost": 50,
      "weight": "6",
      "gold": 2,
      "pool0": null,
      "pool1": "1",
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": 1,
      "pool_rare_gadgets": null
    },
    "lootpack_gadgets_rare": {
      "name": "Редкое оборудование",
      "internal": null,
      "pool": [
        "pool_rare_gadgets"
      ],
      "icon": "lootpack-3",
      "rarity": "rare",
      "cost": 150,
      "weight": "6",
      "gold": 2,
      "pool0": null,
      "pool1": "1",
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": 1
    },
    "pvp_reward_00": {
      "name": null,
      "internal": true,
      "pool": [
        "pool_managers_common"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": 1,
      "gold": 2,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": "0.75;2",
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    },
    "lp0": {
      "name": "Награда за спринт 1-2",
      "internal": true,
      "pool": [],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": 1,
      "gold": 1,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": null,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": 1,
      "pool_rare_gadgets": null
    },
    "lp1": {
      "name": "Награда за спринт 3-5",
      "internal": true,
      "pool": [
        "pool_managers_common"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": 1,
      "gold": 1,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": 1,
      "pool_managers_uncommon": null,
      "pool_managers_rare": null,
      "pool_common_gadgets": 1,
      "pool_rare_gadgets": null
    },
    "lp2": {
      "name": "Награда за спринт (не используется)",
      "internal": true,
      "pool": [
        "pool_managers_common"
      ],
      "icon": "lootpack-1",
      "rarity": null,
      "cost": null,
      "weight": 1,
      "gold": 1,
      "pool0": null,
      "pool1": null,
      "pool2": null,
      "pool_managers_common": 1,
      "pool_managers_uncommon": null,
      "pool_managers_rare": 1,
      "pool_common_gadgets": null,
      "pool_rare_gadgets": null
    }
  },
  "pools": {
    "pool0": {
      "list": [
        "emp1",
        "emp2",
        "emp3",
        "emp4",
        "emp5",
        "emp6",
        "emp10",
        "emp11",
        "emp12",
        "emp13",
        "emp14",
        "emp15",
        "emp16",
        "emp17",
        "emp18",
        "emp19"
      ]
    },
    "pool1": {
      "list": [
        "gd0",
        "gd1"
      ]
    },
    "pool_managers_common": {
      "list": [
        "emp12",
        "emp13",
        "emp14",
        "emp15",
        "emp16",
        "emp18",
        "emp19"
      ]
    },
    "pool_managers_uncommon": {
      "list": [
        "emp3",
        "emp4",
        "emp6",
        "emp11"
      ]
    },
    "pool_managers_rare": {
      "list": [
        "emp1",
        "emp2",
        "emp5",
        "emp10",
        "emp17"
      ]
    },
    "pool_common_gadgets": {
      "list": [
        "gd0",
        "gd1",
        "gd2",
        "gd3",
        "gd4"
      ]
    },
    "pool_rare_gadgets": {
      "list": [
        "gd5",
        "gd6",
        "gd7",
        "gd8"
      ]
    },
    "sprints_pool": {
      "list": [
        "sprint_0",
        "sprint_1",
        "sprint_2",
        "sprint_3",
        "sprint_4",
        "sprint_5"
      ]
    },
    "sprint_0": {
      "list": [
        "ts0",
        "ts1",
        "ts2"
      ]
    },
    "sprint_1": {
      "list": [
        "ts1",
        "ts2",
        "ts3"
      ]
    },
    "sprint_2": {
      "list": [
        "ts2",
        "ts3",
        "ts4"
      ]
    },
    "sprint_3": {
      "list": [
        "ts3",
        "ts4",
        "ts5"
      ]
    },
    "sprint_4": {
      "list": [
        "ts4",
        "ts5",
        "ts6"
      ]
    },
    "sprint_5": {
      "list": [
        "ts6",
        "ts7",
        "ts8"
      ]
    },
    "sprints_pvp_pool": {
      "list": [
        "sprint_pvp"
      ]
    },
    "sprint_pvp": {
      "list": [
        "ts9",
        "ts10",
        "ts11"
      ]
    },
    "relics_pool": {
      "list": [
        "relic_1",
        "relic_2",
        "relic_3"
      ]
    }
  },
  "stages": {
    "location_1": {
      "psources": [
        "office_1_1",
        "office_1_2",
        "office_1_3",
        "office_1_4",
        "office_1_5",
        "office_1_6",
        "office_1_7",
        "office_1_8"
      ],
      "cost": 0,
      "name": "Офис \"Базовый\"",
      "sprite": "reception_1"
    },
    "location_2": {
      "psources": [
        "office_2_1",
        "office_2_2",
        "office_2_3",
        "office_2_4",
        "office_2_5",
        "office_2_6",
        "office_2_7",
        "office_2_8"
      ],
      "cost": 10,
      "name": "Офис \"Надёжный\"",
      "sprite": "reception_2"
    },
    "location_3": {
      "psources": [
        "office_3_1",
        "office_3_2",
        "office_3_3",
        "office_3_4",
        "office_3_5",
        "office_3_6",
        "office_3_7",
        "office_3_8"
      ],
      "cost": 100,
      "name": "Офис \"Успешный\"",
      "sprite": "reception_3"
    },
    "location_4": {
      "psources": [
        "office_4_1",
        "office_4_2",
        "office_4_3",
        "office_4_4",
        "office_4_5",
        "office_4_6",
        "office_4_7",
        "office_4_8"
      ],
      "cost": 1000,
      "name": "Офис \"Карьерный\"",
      "sprite": "reception_4"
    },
    "location_5": {
      "psources": [
        "office_5_1",
        "office_5_2",
        "office_5_3",
        "office_5_4",
        "office_5_5",
        "office_5_6",
        "office_5_7",
        "office_5_8"
      ],
      "cost": 10000,
      "name": "Офис \"Оптимальный\"",
      "sprite": "reception_5"
    }
  },
  "psources": {
    "office_1_1": {
      "profitBase": 2,
      "costBase": 1,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: level < 10 ? level + 1 : (costBase * 1.15^level + 10)",
      "threshold": 4,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1500
      ],
      "name": "Склад",
      "sprite": "sklad",
      "npc_slot": [
        "30vw;13vw",
        "93vw;12vw"
      ]
    },
    "office_1_2": {
      "profitBase": 16,
      "costBase": 8,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 8,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1501
      ],
      "name": "Кафетерий",
      "sprite": "kafe",
      "npc_slot": [
        "70vw;10vw",
        "91vw;10vw"
      ]
    },
    "office_1_3": {
      "profitBase": 480,
      "costBase": 240,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 12,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1502
      ],
      "name": "Спортзал",
      "sprite": "sportzal",
      "npc_slot": [
        "60vw;15vw",
        "74vw;10vw"
      ]
    },
    "office_1_4": {
      "profitBase": 14400,
      "costBase": 9600,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 16,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1503
      ],
      "name": "Зал славы",
      "sprite": "zal_gordosti",
      "npc_slot": [
        "23vw;6vw",
        "50vw;10vw"
      ]
    },
    "office_1_5": {
      "profitBase": 960000,
      "costBase": 480000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 32,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1504
      ],
      "name": "Комната отдыха",
      "sprite": "restroom",
      "npc_slot": [
        "69vw;7vw"
      ]
    },
    "office_1_6": {
      "profitBase": 172800000,
      "costBase": 28800000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 64,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1505
      ],
      "name": "Отдел кадров",
      "sprite": "hr",
      "npc_slot": [
        "28vw;5vw",
        "75vw;5vw"
      ]
    },
    "office_1_7": {
      "profitBase": 17110000000,
      "costBase": 2592000000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 96,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1506
      ],
      "name": "Конференц-зал",
      "sprite": "konferenz_zal",
      "npc_slot": [
        "27vw;4vw",
        "70vw;5vw"
      ]
    },
    "office_1_8": {
      "profitBase": 3500000000000,
      "costBase": 466560000000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 128,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1507
      ],
      "name": "Кабинет шефа",
      "sprite": "kabinet_glavnogo",
      "npc_slot": [
        "22vw;8vw",
        "88vw;8vw"
      ]
    },
    "office_2_1": {
      "profitBase": 280000000000000,
      "costBase": 419904000000000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 12,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1508
      ],
      "name": "Склад",
      "sprite": "sklad",
      "npc_slot": [
        "30vw;13vw",
        "93vw;12vw"
      ]
    },
    "office_2_2": {
      "profitBase": 5600000000000000,
      "costBase": 12597120000000000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 16,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1509
      ],
      "name": "Кафетерий",
      "sprite": "kafe",
      "npc_slot": [
        "70vw;10vw",
        "91vw;10vw"
      ]
    },
    "office_2_3": {
      "profitBase": 112000000000000000,
      "costBase": 503884800000000000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 32,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1510
      ],
      "name": "Спортзал",
      "sprite": "sportzal",
      "npc_slot": [
        "60vw;15vw",
        "74vw;10vw"
      ]
    },
    "office_2_4": {
      "profitBase": 3360000000000000000,
      "costBase": 20155392000000000000,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 64,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1511
      ],
      "name": "Зал славы",
      "sprite": "zal_gordosti",
      "npc_slot": [
        "23vw;6vw",
        "50vw;10vw"
      ]
    },
    "office_2_5": {
      "profitBase": 134400000000000000000,
      "costBase": 1.0077696e+21,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 96,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1512
      ],
      "name": "Комната отдыха",
      "sprite": "restroom",
      "npc_slot": [
        "69vw;7vw"
      ]
    },
    "office_2_6": {
      "profitBase": 5.376e+21,
      "costBase": 5.038848e+22,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 128,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1513
      ],
      "name": "Отдел кадров",
      "sprite": "hr",
      "npc_slot": [
        "28vw;5vw",
        "75vw;5vw"
      ]
    },
    "office_2_7": {
      "profitBase": 2.1504e+23,
      "costBase": 2.0155392e+24,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 224,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1514
      ],
      "name": "Конференц-зал",
      "sprite": "konferenz_zal",
      "npc_slot": [
        "27vw;4vw",
        "70vw;5vw"
      ]
    },
    "office_2_8": {
      "profitBase": 1.93536e+25,
      "costBase": 2.0155392000000002e+26,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 352,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1515
      ],
      "name": "Кабинет шефа",
      "sprite": "kabinet_glavnogo",
      "npc_slot": [
        "22vw;8vw",
        "88vw;8vw"
      ]
    },
    "office_3_1": {
      "profitBase": 1.7418239999999998e+27,
      "costBase": 1.0077696000000002e+29,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 32,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1516
      ],
      "name": "Склад",
      "sprite": "sklad",
      "npc_slot": [
        "30vw;13vw",
        "93vw;12vw"
      ]
    },
    "office_3_2": {
      "profitBase": 8.709119999999998e+28,
      "costBase": 4.031078400000001e+30,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 64,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1517
      ],
      "name": "Кафетерий",
      "sprite": "kafe",
      "npc_slot": [
        "70vw;10vw",
        "91vw;10vw"
      ]
    },
    "office_3_3": {
      "profitBase": 4.354559999999999e+30,
      "costBase": 1.6124313600000003e+32,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 96,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1518
      ],
      "name": "Спортзал",
      "sprite": "sportzal",
      "npc_slot": [
        "60vw;15vw",
        "74vw;10vw"
      ]
    },
    "office_3_4": {
      "profitBase": 2.1772799999999996e+32,
      "costBase": 8.062156800000001e+33,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 128,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1519
      ],
      "name": "Зал славы",
      "sprite": "zal_gordosti",
      "npc_slot": [
        "23vw;6vw",
        "50vw;10vw"
      ]
    },
    "office_3_5": {
      "profitBase": 1.0886399999999997e+34,
      "costBase": 4.031078400000001e+35,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 224,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1520
      ],
      "name": "Комната отдыха",
      "sprite": "restroom",
      "npc_slot": [
        "69vw;7vw"
      ]
    },
    "office_3_6": {
      "profitBase": 5.4431999999999984e+35,
      "costBase": 2.0155392000000004e+37,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 352,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1521
      ],
      "name": "Отдел кадров",
      "sprite": "hr",
      "npc_slot": [
        "28vw;5vw",
        "75vw;5vw"
      ]
    },
    "office_3_7": {
      "profitBase": 2.7215999999999993e+37,
      "costBase": 1.0077696000000002e+39,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 480,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1522
      ],
      "name": "Конференц-зал",
      "sprite": "konferenz_zal",
      "npc_slot": [
        "27vw;4vw",
        "70vw;5vw"
      ]
    },
    "office_3_8": {
      "profitBase": 1.6329599999999996e+39,
      "costBase": 6.046617600000001e+40,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 704,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1523
      ],
      "name": "Кабинет шефа",
      "sprite": "kabinet_glavnogo",
      "npc_slot": [
        "22vw;8vw",
        "88vw;8vw"
      ]
    },
    "office_4_1": {
      "profitBase": 1.6329599999999995e+41,
      "costBase": 4.232632320000001e+43,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 96,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1524
      ],
      "name": "Склад",
      "sprite": "sklad",
      "npc_slot": [
        "30vw;13vw",
        "93vw;12vw"
      ]
    },
    "office_4_2": {
      "profitBase": 8.164799999999998e+42,
      "costBase": 2.962842624000001e+45,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 128,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1525
      ],
      "name": "Кафетерий",
      "sprite": "kafe",
      "npc_slot": [
        "70vw;10vw",
        "91vw;10vw"
      ]
    },
    "office_4_3": {
      "profitBase": 4.082399999999999e+44,
      "costBase": 2.0739898368000004e+47,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 224,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1526
      ],
      "name": "Спортзал",
      "sprite": "sportzal",
      "npc_slot": [
        "60vw;15vw",
        "74vw;10vw"
      ]
    },
    "office_4_4": {
      "profitBase": 2.0411999999999994e+46,
      "costBase": 1.4517928857600003e+49,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 352,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1527
      ],
      "name": "Зал славы",
      "sprite": "zal_gordosti",
      "npc_slot": [
        "23vw;6vw",
        "50vw;10vw"
      ]
    },
    "office_4_5": {
      "profitBase": 1.0205999999999996e+48,
      "costBase": 1.0162550200320002e+51,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 480,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1528
      ],
      "name": "Комната отдыха",
      "sprite": "restroom",
      "npc_slot": [
        "69vw;7vw"
      ]
    },
    "office_4_6": {
      "profitBase": 5.102999999999998e+49,
      "costBase": 7.113785140224001e+52,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 704,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1529
      ],
      "name": "Отдел кадров",
      "sprite": "hr",
      "npc_slot": [
        "28vw;5vw",
        "75vw;5vw"
      ]
    },
    "office_4_7": {
      "profitBase": 2.551499999999999e+51,
      "costBase": 4.979649598156801e+54,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 1056,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1530
      ],
      "name": "Конференц-зал",
      "sprite": "konferenz_zal",
      "npc_slot": [
        "27vw;4vw",
        "70vw;5vw"
      ]
    },
    "office_4_8": {
      "profitBase": 1.2757499999999993e+53,
      "costBase": 3.4857547187097606e+56,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 1536,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1531
      ],
      "name": "Кабинет шефа",
      "sprite": "kabinet_glavnogo",
      "npc_slot": [
        "22vw;8vw",
        "88vw;8vw"
      ]
    },
    "office_5_1": {
      "profitBase": 3.827249999999998e+54,
      "costBase": 8.714386796774402e+58,
      "profit": "@fn: bignumber(profitBase) * level * 2",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 224,
      "thresholdLevels": [
        10,
        25,
        50,
        100
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1532
      ],
      "name": "Склад",
      "sprite": "sklad",
      "npc_slot": [
        "30vw;13vw",
        "93vw;12vw"
      ]
    },
    "office_5_2": {
      "profitBase": 1.913624999999999e+56,
      "costBase": 1.0457264156129283e+61,
      "profit": "@fn: bignumber(profitBase) * level * 3",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 352,
      "thresholdLevels": [
        10,
        25,
        50,
        101
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1533
      ],
      "name": "Кафетерий",
      "sprite": "kafe",
      "npc_slot": [
        "70vw;10vw",
        "91vw;10vw"
      ]
    },
    "office_5_3": {
      "profitBase": 1.5308999999999992e+58,
      "costBase": 1.0457264156129282e+63,
      "profit": "@fn: bignumber(profitBase) * level * 4",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 480,
      "thresholdLevels": [
        10,
        25,
        50,
        102
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1534
      ],
      "name": "Спортзал",
      "sprite": "sportzal",
      "npc_slot": [
        "60vw;15vw",
        "74vw;10vw"
      ]
    },
    "office_5_4": {
      "profitBase": 1.0716299999999995e+60,
      "costBase": 1.0457264156129282e+65,
      "profit": "@fn: bignumber(profitBase) * level * 5",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 704,
      "thresholdLevels": [
        10,
        25,
        50,
        103
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1535
      ],
      "name": "Зал славы",
      "sprite": "zal_gordosti",
      "npc_slot": [
        "23vw;6vw",
        "50vw;10vw"
      ]
    },
    "office_5_5": {
      "profitBase": 8.573039999999996e+61,
      "costBase": 1.0457264156129283e+67,
      "profit": "@fn: bignumber(profitBase) * level * 6",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 1056,
      "thresholdLevels": [
        10,
        25,
        50,
        104
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1536
      ],
      "name": "Комната отдыха",
      "sprite": "restroom",
      "npc_slot": [
        "69vw;7vw"
      ]
    },
    "office_5_6": {
      "profitBase": 5.1438239999999975e+63,
      "costBase": 1.0457264156129282e+69,
      "profit": "@fn: bignumber(profitBase) * level * 7",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 1536,
      "thresholdLevels": [
        10,
        25,
        50,
        105
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1537
      ],
      "name": "Отдел кадров",
      "sprite": "hr",
      "npc_slot": [
        "28vw;5vw",
        "75vw;5vw"
      ]
    },
    "office_5_7": {
      "profitBase": 3.0862943999999984e+65,
      "costBase": 1.0457264156129282e+71,
      "profit": "@fn: bignumber(profitBase) * level * 8",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 2240,
      "thresholdLevels": [
        10,
        25,
        50,
        106
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1538
      ],
      "name": "Конференц-зал",
      "sprite": "konferenz_zal",
      "npc_slot": [
        "27vw;4vw",
        "70vw;5vw"
      ]
    },
    "office_5_8": {
      "profitBase": 1.5431471999999993e+67,
      "costBase": 1.2548716987355139e+73,
      "profit": "@fn: bignumber(profitBase) * level * 9",
      "cost": "@fn: costBase * 1.15^level",
      "threshold": 3296,
      "thresholdLevels": [
        10,
        25,
        50,
        107
      ],
      "clickDamage": 3,
      "rankLevels": [
        15,
        800,
        1000,
        1539
      ],
      "name": "Кабинет шефа",
      "sprite": "kabinet_glavnogo",
      "npc_slot": [
        "22vw;8vw",
        "88vw;8vw"
      ]
    }
  },
  "game": {
    "scales_names": [
      "K",
      "M",
      "B",
      "T",
      "AA",
      "AB",
      "AC",
      "AD",
      "AE",
      "AF",
      "AG",
      "AH",
      "AI",
      "AJ",
      "AK",
      "AL",
      "AM",
      "AN",
      "AO",
      "AP",
      "AQ",
      "AR",
      "AS",
      "AT",
      "AU",
      "AV",
      "AW",
      "AX",
      "AY",
      "AZ",
      "BA"
    ],
    "floors_rank_names": [
      "бронза",
      "серебро",
      "золото",
      "легенда"
    ],
    "battle_rewards_lootpacks": [
      "lp0",
      "lp0",
      "lp1",
      "lp1",
      "lp1"
    ],
    "relics_cost": [
      250,
      500,
      900,
      3000,
      9000
    ],
    "audit_base_reward": "10",
    "psource_base_reward": "3",
    "pvp_base_reward": "10",
    "stage_base_reward": "300",
    "audit_amount_rewards": [
      1,
      1,
      1.15,
      1.2,
      1.25
    ],
    "audit_level_rewards": [
      "2:0",
      "10:2",
      "15:4",
      "50:6",
      "100:8",
      "99999:12"
    ],
    "maxPsourceLevel": "100",
    "coffee_effect_duration": "5",
    "pvp_ranks": [
      10,
      25,
      50,
      100,
      99999
    ],
    "pvp_time_to_task_defeat": "15",
    "pvp_floors_amount": "5",
    "dev_test_array_export": [
      1,
      2,
      3
    ],
    "coffee_cost": [
      3,
      15,
      30,
      150
    ],
    "coffee_amount": [
      1,
      5,
      10,
      50
    ]
  },
  "employees": {
    "eduard_uspehow": {
      "name": "Эдуард Успехов",
      "healthMax": "@fn: 250 + level * 30",
      "healthRegen": "@fn:10+ level *3",
      "attackRate": 1000,
      "attackDamage": "@fn: 12 + level * 7",
      "levels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksLevels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksNames": [
        "стажёр",
        "специалист",
        "менеджер",
        "старший менеджер",
        "руководитель направления"
      ],
      "rarity": "unique,Уникальный",
      "description": "Молодой специалист, выпускник престижного вуза, менеджер отдела коммерции. Мастерски ведёт переговоры с поставщиками. Его девиз по жизни: «Договориться можно обо всём!»",
      "icon": "manager_male9_cr",
      "animation": "manager_male9",
      "profit_bonus": "@fn: 1.75 + level * 0.05",
      "upgrade_bonus": "@fn: 0.9 - level * 0.01",
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "dima_nadeshni": {
      "name": "Дима Надёжный",
      "healthMax": "@fn: 250 + level * 40",
      "healthRegen": "@fn:10+ level *3",
      "attackRate": 1000,
      "attackDamage": "@fn: 14 + level * 7",
      "levels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksLevels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksNames": [
        "стажёр",
        "специалист",
        "менеджер",
        "старший менеджер",
        "руководитель направления"
      ],
      "rarity": "unique,Уникальный",
      "description": "Настоящий эксперт по цепочкам поставок! В школе больше всего любил задачки по расчёту времени движения машины из пункта «А» в пункт «В».",
      "icon": "manager_male4_cr",
      "animation": "manager_male4",
      "profit_bonus": "@fn: 1.75 + level * 0.05",
      "upgrade_bonus": "@fn: 0.9 - level * 0.01",
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "vika_tvorcheskaya": {
      "name": "Виктория Творческая",
      "healthMax": "@fn: 300 + level * 35",
      "healthRegen": "@fn:10+ level *3",
      "attackRate": 1000,
      "attackDamage": "@fn: 15 + level * 8",
      "levels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksLevels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksNames": [
        "стажёр",
        "специалист",
        "менеджер",
        "старший менеджер",
        "руководитель направления"
      ],
      "rarity": "unique,Уникальный",
      "description": "Богиня маркетинга, выпускница-краснодипломница и обладательница самой ослепительной улыбки. Никто не способен устоять перед её чарами, когда она продвигает бренд.",
      "icon": "manager_girl3_cr",
      "animation": "manager_girl3",
      "profit_bonus": "@fn: 1.75 + level * 0.05",
      "upgrade_bonus": "@fn: 0.9 - level * 0.01",
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "alena_smehova": {
      "name": "Алёна Смехова",
      "healthMax": "@fn: 300 + level * 40",
      "healthRegen": "@fn:10+ level *3",
      "attackRate": 1000,
      "attackDamage": "@fn: 20 + level * 7",
      "levels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksLevels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksNames": [
        "стажёр",
        "специалист",
        "менеджер",
        "старший менеджер",
        "руководитель направления"
      ],
      "rarity": "unique,Уникальный",
      "description": "Обладательница премии «Лучший кадр отдела кадров». Глубоко вникает в жизнь сотрудников, что характеризует её как прекрасного профессионала.",
      "icon": "manager_girl15_cr",
      "animation": "manager_girl15",
      "profit_bonus": "@fn: 1.75 + level * 0.05",
      "upgrade_bonus": "@fn: 0.9 - level * 0.01",
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "ivan_prodavcov": {
      "name": "Иван Продавцов",
      "healthMax": "@fn: 350 + level * 40",
      "healthRegen": "@fn:10+ level *3",
      "attackRate": 1000,
      "attackDamage": "@fn: 20 + level * 9",
      "levels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksLevels": [
        1,
        2,
        3,
        4,
        5
      ],
      "ranksNames": [
        "стажёр",
        "специалист",
        "менеджер",
        "старший менеджер",
        "руководитель направления"
      ],
      "rarity": "unique,Уникальный",
      "description": "Главный по операциям. Конечно, не медицинским, а тем, которые регулярно выполняются во всех магазинах торговой сети. Знает как устроен каждый бизнес-процесс и уже, наверное, мог бы написать инструкцию по эксплуатации всей компании.",
      "icon": "manager_male5_cr",
      "animation": "manager_male5",
      "profit_bonus": "@fn: 1.75 + level * 0.05",
      "upgrade_bonus": "@fn: 0.9 - level * 0.01",
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp1": {
      "name": "Герман Боровиковский",
      "healthMax": "@fn: 200 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 1500,
      "attackDamage": "@fn: 15 + level * 3",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "rare, Редкий",
      "description": "Менеджер высшего разряда. Обладает всеми необходимыми качествами для выполнения спринтов и повышения эффективности офисов.",
      "icon": "manager_male2_cr",
      "animation": "manager_male2",
      "profit_bonus": "@fn: 1.2 + level * 0.03",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp2": {
      "name": "Артём Круглов",
      "healthMax": "@fn: 200 + level * 15",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 750,
      "attackDamage": "@fn: 10 + level * 5",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "rare, Редкий",
      "description": "Менеджер высшего разряда. Обладает всеми необходимыми качествами для выполнения спринтов и повышения эффективности офисов.",
      "icon": "manager_male3_cr",
      "animation": "manager_male3",
      "profit_bonus": "@fn: 1.2 + level * 0.03",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp3": {
      "name": "Тихон Мельнышевский",
      "healthMax": "@fn: 300 + level * 9",
      "healthRegen": "@fn:10+ level *1",
      "attackRate": 1000,
      "attackDamage": "@fn: 20 + level * 4",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "uncommon,Необычный",
      "description": "Менеджер среднего звена. Работает не «за», работает «для». Для роста производительности офисов и эффективного выполнения плана.",
      "icon": "manager_male6_cr",
      "animation": "manager_male6",
      "profit_bonus": "@fn: 1.15 + level * 0.02",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp4": {
      "name": "Борис Питонов",
      "healthMax": "@fn: 300 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 500,
      "attackDamage": "@fn: 10 + level * 9",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "uncommon,Необычный",
      "description": "Менеджер среднего звена. Работает не «за», работает «для». Для роста производительности офисов и эффективного выполнения плана.",
      "icon": "manager_male7_cr",
      "animation": "manager_male7",
      "profit_bonus": "@fn: 1.15 + level * 0.02",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp5": {
      "name": "Константин Тарасько",
      "healthMax": "@fn: 300 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 1500,
      "attackDamage": "@fn: 15 + level * 3",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "rare, Редкий",
      "description": "Менеджер высшего разряда. Обладает всеми необходимыми качествами для выполнения спринтов и повышения эффективности офисов.",
      "icon": "manager_male8_cr",
      "animation": "manager_male8",
      "profit_bonus": "@fn: 1.2 + level * 0.03",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp6": {
      "name": "Владислав Лидеров",
      "healthMax": "@fn: 300 + level * 15",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 750,
      "attackDamage": "@fn: 10 + level * 5",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "uncommon,Необычный",
      "description": "Менеджер среднего звена. Работает не «за», работает «для». Для роста производительности офисов и эффективного выполнения плана.",
      "icon": "manager_male1_cr",
      "animation": "manager_male1",
      "profit_bonus": "@fn: 1.15 + level * 0.02",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp10": {
      "name": "Мэри Лин",
      "healthMax": "@fn: 300 + level * 15",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 750,
      "attackDamage": "@fn: 10 + level * 5",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "rare, Редкий",
      "description": "Менеджер высшего разряда. Обладает всеми необходимыми качествами для выполнения спринтов и повышения эффективности офисов.",
      "icon": "manager_girl1_cr",
      "animation": "manager_girl1",
      "profit_bonus": "@fn: 1.2 + level * 0.03",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp11": {
      "name": "София Гогаладзе",
      "healthMax": "@fn: 300 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 1500,
      "attackDamage": "@fn: 15 + level * 3",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "uncommon,Необычный",
      "description": "Менеджер среднего звена. Работает не «за», работает «для». Для роста производительности офисов и эффективного выполнения плана.",
      "icon": "manager_girl4_cr",
      "animation": "manager_girl4",
      "profit_bonus": "@fn: 1.15 + level * 0.02",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp12": {
      "name": "Ирина Франкова",
      "healthMax": "@fn: 300 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 500,
      "attackDamage": "@fn: 10 + level * 9",
      "levels": [
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        50
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "common,Обычный",
      "description": "Junior-менеджер. Прекрасно справится с любой работой, для которой не требуется большой опыт. Её энергичность и креативность помогают придумывать новые решения для повышения эффективности офиса и даже справляться со спринтами.",
      "icon": "manager_girl5_cr",
      "animation": "manager_girl5",
      "profit_bonus": "@fn: 1.1 + level * 0.01",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp13": {
      "name": "Лиля Караваева",
      "healthMax": "@fn: 300 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 1500,
      "attackDamage": "@fn: 15 + level * 3",
      "levels": [
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        50
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "common,Обычный",
      "description": "Junior-менеджер. Прекрасно справится с любой работой, для которой не требуется большой опыт. Её энергичность и креативность помогают придумывать новые решения для повышения эффективности офиса и даже справляться со спринтами.",
      "icon": "manager_girl6_cr",
      "animation": "manager_girl6",
      "profit_bonus": "@fn: 1.1 + level * 0.01",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp14": {
      "name": "Инесса Летучая",
      "healthMax": "@fn: 300 + level * 15",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 750,
      "attackDamage": "@fn: 10 + level * 5",
      "levels": [
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        50
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "common,Обычный",
      "description": "Junior-менеджер. Прекрасно справится с любой работой, для которой не требуется большой опыт. Её энергичность и креативность помогают придумывать новые решения для повышения эффективности офиса и даже справляться со спринтами.",
      "icon": "manager_girl7_cr",
      "animation": "manager_girl7",
      "profit_bonus": "@fn: 1.1 + level * 0.01",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp15": {
      "name": "Светлана Хорошева",
      "healthMax": "@fn: 300 + level * 9",
      "healthRegen": "@fn:10+ level *1",
      "attackRate": 1000,
      "attackDamage": "@fn: 20 + level * 4",
      "levels": [
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        50
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "common,Обычный",
      "description": "Junior-менеджер. Прекрасно справится с любой работой, для которой не требуется большой опыт. Её энергичность и креативность помогают придумывать новые решения для повышения эффективности офиса и даже справляться со спринтами.",
      "icon": "manager_girl8_cr",
      "animation": "manager_girl8",
      "profit_bonus": "@fn: 1.1 + level * 0.01",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp16": {
      "name": "Алла Рублёва",
      "healthMax": "@fn: 300 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 500,
      "attackDamage": "@fn: 10 + level * 9",
      "levels": [
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        50
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "common,Обычный",
      "description": "Junior-менеджер. Прекрасно справится с любой работой, для которой не требуется большой опыт. Её энергичность и креативность помогают придумывать новые решения для повышения эффективности офиса и даже справляться со спринтами.",
      "icon": "manager_girl9_cr",
      "animation": "manager_girl9",
      "profit_bonus": "@fn: 1.1 + level * 0.01",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp17": {
      "name": "Дарья Анимешная",
      "healthMax": "@fn: 300 + level * 8",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 1500,
      "attackDamage": "@fn: 15 + level * 3",
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "rare, Редкий",
      "description": "Менеджер высшего разряда. Обладает всеми необходимыми качествами для выполнения спринтов и повышения эффективности офисов.",
      "icon": "manager_girl10_cr",
      "animation": "manager_girl10",
      "profit_bonus": "@fn: 1.2 + level * 0.03",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp18": {
      "name": "Алиса Юсупова",
      "healthMax": "@fn: 300 + level * 15",
      "healthRegen": "@fn:10+ level *2",
      "attackRate": 750,
      "attackDamage": "@fn: 10 + level * 5",
      "levels": [
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        50
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "common,Обычный",
      "description": "Junior-менеджер. Прекрасно справится с любой работой, для которой не требуется большой опыт. Её энергичность и креативность помогают придумывать новые решения для повышения эффективности офиса и даже справляться со спринтами.",
      "icon": "manager_girl11_cr",
      "animation": "manager_girl11",
      "profit_bonus": "@fn: 1.1 + level * 0.01",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    },
    "emp19": {
      "name": "Оксана Удачкина",
      "healthMax": "@fn: 300 + level * 9",
      "healthRegen": "@fn:10+ level *1",
      "attackRate": 1000,
      "attackDamage": "@fn: 20 + level * 4",
      "levels": [
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        50
      ],
      "ranksLevels": [],
      "ranksNames": [],
      "rarity": "common,Обычный",
      "description": "Junior-менеджер. Прекрасно справится с любой работой, для которой не требуется большой опыт. Её энергичность и креативность помогают придумывать новые решения для повышения эффективности офиса и даже справляться со спринтами.",
      "icon": "manager_girl12_cr",
      "animation": "manager_girl12",
      "profit_bonus": "@fn: 1.1 + level * 0.01",
      "upgrade_bonus": null,
      "threshold_bonus": null,
      "stage": "location_1",
      "weight": 1
    }
  },
  "npcs": {
    "npc1": {
      "icon": "npc_boy1"
    },
    "npc2": {
      "icon": "npc_boy2"
    },
    "npc3": {
      "icon": "npc_boy3"
    },
    "npc4": {
      "icon": "npc_boy4"
    },
    "npc5": {
      "icon": "npc_girl1"
    },
    "npc6": {
      "icon": "npc_girl2"
    },
    "npc7": {
      "icon": "npc_girl3"
    },
    "npc8": {
      "icon": "npc_girl4"
    }
  },
  "tasks": {
    "ts0": {
      "healthMax": "@fn: 40 + level * 2",
      "attackDamage": "@fn: 3 + level * 2",
      "attackRate": 750,
      "name": "Легкая таска",
      "icon": "task_1"
    },
    "ts1": {
      "healthMax": "@fn: 50 + level * 3",
      "attackDamage": "@fn: 2 + level * 2",
      "attackRate": 1000,
      "name": "Средняя таска",
      "icon": "task_2"
    },
    "ts2": {
      "healthMax": "@fn: 60 + level * 3",
      "attackDamage": "@fn: 3 + level * 3",
      "attackRate": 750,
      "name": "Трудная таска",
      "icon": "task_3"
    },
    "ts3": {
      "healthMax": "@fn: 50 + level * 2",
      "attackDamage": "@fn: 3 + level * 2",
      "attackRate": 750,
      "name": "Легкая поддержка",
      "icon": "support_1"
    },
    "ts4": {
      "healthMax": "@fn: 40 + level * 3",
      "attackDamage": "@fn: 2 + level * 2",
      "attackRate": 1000,
      "name": "Средняя поддержка",
      "icon": "support_2"
    },
    "ts5": {
      "healthMax": "@fn: 60 + level * 3",
      "attackDamage": "@fn: 2 + level * 3",
      "attackRate": 750,
      "name": "Трудная поддержка",
      "icon": "support_3"
    },
    "ts6": {
      "healthMax": "@fn: 45 + level * 2",
      "attackDamage": "@fn: 3 + level * 2",
      "attackRate": 750,
      "name": "Легкий баг",
      "icon": "bag_1"
    },
    "ts7": {
      "healthMax": "@fn: 50 + level * 2",
      "attackDamage": "@fn: 2 + level * 2",
      "attackRate": 1000,
      "name": "Средний баг",
      "icon": "bag_2"
    },
    "ts8": {
      "healthMax": "@fn: 50 + level * 3",
      "attackDamage": "@fn: 3 + level * 3",
      "attackRate": 750,
      "name": "Трудный баг",
      "icon": "bag_3"
    },
    "ts9": {
      "healthMax": 300,
      "attackDamage": 3,
      "attackRate": 750,
      "name": "Легкая таска",
      "icon": "task_3"
    },
    "ts10": {
      "healthMax": 350,
      "attackDamage": 3,
      "attackRate": 900,
      "name": "Средняя поддержка",
      "icon": "support_3"
    },
    "ts11": {
      "healthMax": 400,
      "attackDamage": 4,
      "attackRate": 750,
      "name": "Трудный баг",
      "icon": "bag_3"
    }
  },
  "gadgets": {
    "gd0": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 60",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "stage": "location_1",
      "name": "Чайник",
      "description": "Электрочайник \"Момент\"",
      "description_stats": "Веселье приносит и вкус бодрящий. Чай - источник здоровья и энергии!",
      "icon": "gadget-a-icon",
      "animation": "gadget-a-0"
    },
    "gd1": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 60",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "stage": "location_1",
      "name": "Маркерная доска",
      "description": "Маркерная доска \"Самочистящаяся\"",
      "description_stats": "На ней остаются только позитивные записи, вдохновляющие менеджеров на эффективный труд.",
      "icon": "gadget-b-icon",
      "animation": "gadget-b-0"
    },
    "gd2": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 60",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "stage": "location_1",
      "name": "Кофемашина",
      "description": "Кофемашина \"Бодрячком\"",
      "description_stats": "Приготовленный в ней кофе помогает здесь и сейчас. Накопить его не выйдет!",
      "icon": "gadget-c-icon",
      "animation": "gadget-c-0"
    },
    "gd3": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 60",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "stage": "location_1",
      "name": "Настольная игра",
      "description": "Настолка \"Топ-менеджер\"",
      "description_stats": "Играя в эту настолку, менеджеры восстанавливают энергию и уверенность в себе!",
      "icon": "gadget-d-icon",
      "animation": "gadget-d-0"
    },
    "gd4": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 60",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        13,
        17,
        21,
        25,
        30
      ],
      "stage": "location_1",
      "name": "Приставка",
      "description": "Приставка \"Pony Slaystation\"",
      "description_stats": "Виртуальный тренажёр – путь к успеху в выполнении настоящих спринтов!",
      "icon": "gadget-e-icon",
      "animation": "gadget-e-0"
    },
    "gd5": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 80",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "stage": "location_1",
      "name": "Проектор",
      "description": "Проектор \"Слайдовый\"",
      "description_stats": "Лучше один раз увидеть, чем сто раз услышать!",
      "icon": "gadget-f-icon",
      "animation": "gadget-f-0"
    },
    "gd6": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 80",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "stage": "location_1",
      "name": "Кулер",
      "description": "Кулер \"Водный\"",
      "description_stats": "Нет ничего более бодрящего, чем стакан ледяной воды в сорокаградусную жару!",
      "icon": "gadget-g-icon",
      "animation": "gadget-g-0"
    },
    "gd7": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 80",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "stage": "location_1",
      "name": "Принтер",
      "description": "Принтер \"Матрица\"",
      "description_stats": "На таком принтере можно напечатать портрет самого успешного специалиста!",
      "icon": "gadget-h-icon",
      "animation": "gadget-h-0"
    },
    "gd8": {
      "triggers": "here.employeeHealth < 0.5",
      "effectType": "heal",
      "effectValue": "@fn: level * 80",
      "chargesMax": 10,
      "regenRate": 120,
      "weight": 1,
      "levels": [
        1,
        2,
        5,
        7,
        10,
        12,
        15,
        18,
        21,
        25
      ],
      "stage": "location_1",
      "name": "Шреддер",
      "description": "Шреддер \"Разрывной\"",
      "description_stats": "Оптимальный помощник в уничтожении документов, которые вам чем-то не угодили!",
      "icon": "gadget-i-icon",
      "animation": "gadget-i-0"
    }
  },
  "relics": {
    "relic_1": {
      "cost": "@fn: level * 500",
      "effectScope": "psource",
      "effectFilter": null,
      "effectType": "profit_bonus",
      "effectValue": "@fn: 10^level",
      "name": "Приветственный набор",
      "icon": "notebook",
      "description": "Умножает доход с этажей"
    },
    "relic_2": {
      "cost": "@fn: level * 500",
      "effectScope": "psource",
      "effectFilter": null,
      "effectType": "threshold_bonus",
      "effectValue": "@fn: 0.5^level",
      "name": "Толстовка \"Турбостарты\"",
      "icon": "hoodie",
      "description": "Ускоряет получение дохода с этажей"
    },
    "relic_3": {
      "cost": "@fn: level * 500",
      "effectScope": "psource",
      "effectFilter": null,
      "effectType": "click_bonus",
      "effectValue": "@fn: level*2",
      "name": "Лента для бейджика за стаж",
      "icon": "shnurok",
      "description": "Увеличивает урон нажатий по таскам спринта"
    }
  },
  "currencies": {
    "gold": {
      "weight": 0.0001,
      "amount": 1,
      "name": "Золото",
      "icon": "icon-gold"
    },
    "gems": {
      "weight": 1,
      "amount": 0,
      "name": "Кристаллы",
      "icon": "icon-gem"
    },
    "coffee": {
      "weight": 1,
      "amount": 0,
      "name": "Кофе",
      "icon": "icon-coffee"
    }
  }
}