/**
 * @File InputsManager.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Game/Core
 */
import { EventsManager } from '../core/events.js';
import { vec2 } from 'gl-matrix';
import AppCore from '../game/AppCore.js';

/**
 * InputsManager. User inputs wrapper
 */
export default class InputsManager {
	/**
	 * constructor.
	 */
	constructor() {
		this.events = new EventsManager();

		this.on = this.events.on.bind(this.events);
		this.off = this.events.off.bind(this.events);
		this.intouch = null;
	}

	/**
	 * Same as init but works only once
	 *
	 * @returns {InputsManager} this
	 */
	initOnce() {
		if (!this._initOnceTriggered) {
			this.init();
			this._initOnceTriggered = true;
		}

		return this;
	}

	/**
	 * init.
	 *
	 * @returns {InputsManager} this
	 */
	init() {
		this.events.init();
		this._initKeyboard();
		this._initMouse();
		this._initScroll();

		return this;
	}

	/**
	 * _initKeyboard.
	 */
	_initKeyboard() {
		document.addEventListener('keydown', (e) => {
			this.events.emit('game_input_key', { down: true, event: e });
		});
		document.addEventListener('keyup', (e) => {
			this.events.emit('game_input_key', { down: false, event: e });
		});
	}

	_handle_mousedown(e) {
		this.mousedown = { x: e.clientX, y: e.clientY };
		this.mousedownTime = Date.now();

		//console.log('_handle_mousedown', e);
		this.events.emit('game_input_mouse', { event: e, input: 'down' });
	}

	_handle_touchdown(e) {
		let touches = e.changedTouches;
		if (touches.length > 0) {
			e.clientX = touches[0].clientX;
			e.clientY = touches[0].clientY;
			this._handle_mousedown(e);
			this.intouch = e;
		}
	}

	_handle_mouseup(e) {
		//console.log('_handle_mouseup', e);
		this.events.emit('game_input_mouse', { event: e, input: 'up' });

		//console.log('_handle_mouseup', this.mousedownTime, Date.now() - this.mousedownTime, this.mousedown,
		//	vec2.length([e.clientX - this.mousedown.x, e.clientY - this.mousedown.y]), this.tapdistance);

		// let targetMoveDelta = 0;
		// if (this.hoveredBoundingRect) {
		// 	let targetBoundingRect = e.target.getBoundingClientRect();
		// 	targetMoveDelta = Math.max(
		// 		Math.abs(targetBoundingRect.x - this.hoveredBoundingRect.x),
		// 		Math.abs(targetBoundingRect.y - this.hoveredBoundingRect.y)
		// 	);
		// }

		// let tapdistance = targetMoveDelta > this.targetMoveDeltaMax ?
		// 	vec2.length([e.clientX - this.mousedown.x, e.clientY - this.mousedown.y]) :
		// 	0;

		if (this.mousedown && !this.moved) {
			//console.log('_handle_mouseup click', e, this.intouch);
			this.events.emit('game_input_mouse', { event: e, input: 'click' });
			this.events.emit('game_input_click', e);

			const event = new CustomEvent('game_input_click', {
				detail: (this.intouch == null ? e : this.intouch)
			});

			e.target.dispatchEvent(event);
		}

		this.moved = false;
		this.mousedown = null;
	}

	_handle_touchup(e) {
		let touches = e.changedTouches;
		//console.log('_handle_touchup', touches);
		// let timestamp = Date.now();
		// const dragDuration = timestamp - this.mousedownTime;
		if (touches.length > 0) {
			e.clientX = touches[0].clientX;
			e.clientY = touches[0].clientY;
			
			// this.hovered = e.target;
			// this.hoveredBoundingRect = this.hovered.getBoundingClientRect();
			this._handle_mouseup(e);
			//this.events.emit('game_input_mouse', { event: e, input: 'up' });
		}
	}

	_handle_mousemove(e) {
		let timestamp = Date.now();

		// if (this.hovered !== e.target) {
		// 	// --- game_input_hoverstart

		// 	this.events.emit('game_input_hoverstart', e.target);

		// 	let event = new CustomEvent('game_input_hoverstart', {
		// 		detail: e
		// 	});
		// 	e.target.dispatchEvent(event);

		// 	if (this.hovered) {
		// 		// --- game_input_hoverend

		// 		this.events.emit('game_input_hoverend', this.hovered);

		// 		event = new CustomEvent('game_input_hoverend', {
		// 			detail: e
		// 		});
		// 		this.hovered.dispatchEvent(event);
		// 	}

			// this.hovered = e.target;
			// this.hoveredBoundingRect = this.hovered.getBoundingClientRect();		
		// }

		// --- game_input_hovermoved

		// this.events.emit('game_input_hovermoved', this.hovered);  // unused

		// const event = new CustomEvent('game_input_hovermoved', {
		// 	detail: e
		// });

		// this.hovered.dispatchEvent(event);

		// --- game_input_drag
		if (this.mousedown) {
			const dragDuration = timestamp - this.mousedownTime;
			let delta;
			if (e.delta) {
				delta = e.delta;
			} else {
				delta = { x: e.clientX - this.mousedown.x, y: e.clientY - this.mousedown.y };
			}
			// const delta = { x: e.clientX - this.mousedown.x, y: e.clientY - this.mousedown.y };
			const velocity = { x: e.movementX, y: e.movementY };
			if (
				Math.abs(delta.y) > this.dragDistance ||
				Math.abs(delta.x) > this.dragDistance ||
				dragDuration > this.dragThreshold
			) {
				this.moved = true;
				//console.log('dragDuration', dragDuration, this.dragThreshold, delta, this.dragDistance);
				this.events.emit('game_input_mouse', {
					event: e,
					input: 'drag',
					// start: this.mousedown,
					delta,
					velocity,
					dragDuration
				});
				// this.events.emit('game_input_drag', {  // unused
				// 	start: this.mousedown,
				// 	delta,
				// 	velocity,
				// 	dragDuration
				// });
			}
		}
	}

	_handle_touchmove(e) {
		let touches = e.changedTouches;
		if (touches.length > 0 && this.mousedown) {
			e.clientX = touches[0].clientX;
			e.clientY = touches[0].clientY;
			e.wheelDeltaY = (e.clientY - this.mousedown.y);
			e.wheelDeltaX = (e.clientX - this.mousedown.x);
			e.delta = { y: e.wheelDeltaY, x: e.wheelDeltaX };
			const dt = 1 / (Date.now() - this.mousedownTime)
			e.movementX = e.wheelDeltaX * dt * 10;
			e.movementY = e.wheelDeltaY * dt * 10;
			//console.log('touch delta', e.wheelDeltaY);
			this._handle_mousemove(e);
		}
	}

	_handle_mousewheel(e) {
		if (!this.ticking) {
			requestAnimationFrame(() => {
				this.events.emit('game_input_mouse', { event: e, input: 'wheel' });
				this.events.emit('game_input_wheel', e);
				this.ticking = false;
			});

			this.ticking = true;
		}
	}

	_handle_touchwheel(e) {
		let touches = e.changedTouches;
		if (touches.length > 0 && this.mousedown) {
			e.clientX = touches[0].clientX;
			e.clientY = touches[0].clientY;
			e.wheelDeltaY =  e.clientY - this.mousedown.y;
			e.wheelDeltaX = e.clientX - this.mousedown.x;
			this._handle_mousewheel(e);
		}
	}

	/**
	 * _initMouse.
	 */
	_initMouse() {
		// this.hovered = null;
		// this.hoveredBoundingRect = null;
		this.mousedown = null;
		this.mousedownTime = 0;
		this.moved = false;
		if (!AppCore.instance.is_mobile()) {
			document.addEventListener('mousedown', (e) => {
				if (this.intouch) return;
				this._handle_mousedown(e);
			});
		}

		if (AppCore.instance.is_mobile()) {
			document.addEventListener('touchstart', (e) => this._handle_touchdown(e));
		}

		this.taptime = 300;
		this.dragThreshold = this.taptime / 2;
		this.tapdistance = 0;
		this.dragDistance = this.tapdistance / 3;
		this.targetMoveDeltaMax = 10;

		if (!AppCore.instance.is_mobile()) {
			document.addEventListener('mouseup', (e) => {
				this._handle_mouseup(e);
			});
			//document.addEventListener('touchend', (e) => this._handle_touchup(e));
	
			document.addEventListener('mousemove', (e) => this._handle_mousemove(e));
			// document.addEventListener('touchmove', (e) => this._handle_touchmove(e));
		}
	}

	/**
	 * .
	 */
	_initScroll() {
		this.ticking = false;

		if (AppCore.instance.is_mobile()) {
			/*document.addEventListener('touchmove', (e) => this._handle_touchmove(e));
			document.addEventListener('touchend', (e) => this._handle_touchup(e));*/
			this.assignTouchListeners();
		} else {
			document.addEventListener('wheel', (e) => this._handle_mousewheel(e));
		}
	}

	assignTouchListeners() {
		this.unAssignTouchListeners();
		document.addEventListener('touchmove', this._handle_touchmove.bind(this));
		document.addEventListener('touchend', this._handle_touchup.bind(this));
	}

	unAssignTouchListeners() {
		document.removeEventListener('touchmove', this._handle_touchmove);
		document.removeEventListener('touchend', this._handle_touchup);
	}

	/**
	 * @returns {AppCore} instance
	 */
	static get instance() {
		if (!InputsManager._instance) {
			InputsManager._instance = new InputsManager();
		}

		return InputsManager._instance;
	}
}
