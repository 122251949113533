/**
 * @File ViewRoot.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 *
 * Весь файл давно пора рефакторить, разбивать на отдельные модули и чистить
 */

import AppCore from '../AppCore.js';
import HeadOfficeView from './HeadOfficeView.js';
import OfficeView from './OfficeView.js';
import ShopView from './ShopView.js';
import InventoryView from './InventoryView.js';
import PvpPrepareView from './PvpPrepareView.js';
import RewardView from './RewardView.js';
import BattleResultsView from './BattleResultsView.js';
import logger from '../../core/logger.js';
import { ViewBase } from '../../core/views.js';
import mathjs from '../../core/mathjs.js';
import JournalView from './JournalView.js';
import SettingsView from './SettingsView.js';
import PvpView from './PvpView.js';
import {
	setScrollPos,
	getScrollPos
} from '../dust/ui_utils.js';

import { action, config, sound, inventoryGet } from '../shorthands.js';

const VIEWS = {
	INVENTORY: 0,
	SHOP: 1,
	OFFICE: 2,
	ITD_RESET: 3,
	PVP_PREPARE: 4
};

/**
 * Game root view
 */
export default class ViewRoot extends ViewBase {
	/* eslint-disable complexity */
	/**
	 * _click.
	 *
	 * @param {string} key click key
	 * @param {object} args custom events arguments
	 */
	_click(opts) {
		let key = opts.key;
		let args = opts.args;
		if (!this.button_enabled) return;
		const shell = AppCore.instance.itd5ka.shell;
		AppCore.instance.logOnServer("user_tap", { name: key });

		switch (key) {
			case 'button_inventory':
				shell.selectMenuElements([shell.html.button_inventory]);
				this.view = VIEWS.INVENTORY;
				AppCore.instance.views.cut(1);
				AppCore.instance.views.push(new InventoryView());
				InventoryView.tabs.resetSelectedTab();
				break;
			case 'button_shop':
				shell.selectMenuElements([shell.html.button_shop]);
				if (this.view === VIEWS.SHOP) {
					break;
				}

				this.view = VIEWS.SHOP;
				AppCore.instance.views.cut(1);
				AppCore.instance.views.push(new ShopView());
				break;
			case 'button_journal':
				AppCore.instance.views.push(new JournalView());
				break;
			case 'button_settings':
				AppCore.instance.views.push(new SettingsView());
				break;
			case 'button_itd_main':
				shell.selectMenuElements([shell.html.button_itd_main, shell.html.label_itd_status]);
				if (this.view === VIEWS.OFFICE) {
					break;
				}
				this.reset(true, -1);
				break;
			case 'button_itd_reset':
				shell.selectMenuElements([shell.html.button_itd_reset]);
				if (this.view === VIEWS.ITD_RESET) {
					break;
				}

				this.view = VIEWS.ITD_RESET;
				AppCore.instance.views.cut(1);
				AppCore.instance.views.push(new HeadOfficeView());
				break;
			case 'button_pvp':
				shell.selectMenuElements([shell.html.button_pvp]);
				// #bugfix 220510 - когда после окончания pvp перезаходишь в основной цикл игры, this.view все еще == VIEWS.PVP_PREPARE
				/*
				if (this.view === VIEWS.PVP_PREPARE) {
					break;
				}
				*/

				this.view = VIEWS.PVP_PREPARE;

				AppCore.instance.views.cut(1);
				AppCore.instance.views.push(new PvpPrepareView());
				break;
			case 'button_location_next': {
				if (this.isLocationTransitionActive) return;
				this.isLocationTransitionActive = true;
				
				shell.effects.animateTransition(true, () => {
					this.selectedLocation = Math.min(
						this.selectedLocation + 1,
						AppCore.instance.game.inventory.stages.length - 1
					);
					AppCore.instance.views.cut(1);
					this.pushNewOfficeView();
					setScrollPos(-10000);
				}, () => { this.isLocationTransitionActive = false; });
				sound('click_strong_1');
				break;
			}
			case 'button_location_prev': {
				if (this.isLocationTransitionActive) return;
				this.isLocationTransitionActive = true;

				shell.effects.animateTransition(false, () => {
					this.selectedLocation = Math.max(this.selectedLocation - 1, 0);
					AppCore.instance.views.cut(1);
					this.pushNewOfficeView();
					setScrollPos(-10000);
				}, () => { this.isLocationTransitionActive = false; });
				sound('click_strong_1');
				break;
			}
			case 'journal_entry': {
				const item = AppCore.instance.game.inventory.get(args.id);
				AppCore.instance.game.actions.action('set_achievements_status', {
					id: item.id,
					status: 2
				});
				const config = AppCore.instance.game.raw.content.config.content;
				const ach = config.achievements[args.id];
				this.claimReward(ach.reward, ach.description);
				sound('applause');

				break;
			}
			case 'button_pvp_start': {
				const topView = AppCore.instance.views.top();
				if (topView.constructor === PvpView) topView.startBattle();
				break;
			}
			case 'button_pvp_stop': {
				AppCore.instance.views.pop();
				const topView = AppCore.instance.views.top();
				if (topView.constructor === PvpView) AppCore.instance.views.pop();
				this.pushNewOfficeView();
				break;
			}
			case 'syserrorpanel': {
				AppCore.instance.hideSysError();
				break;
			}
			default:
				break;
		}
	}
	/* eslint-enable complexity */

	/**
	 * .
	 */
	init() {
		this.view = VIEWS.OFFICE;
		this._currentlyInBattle = false;

		// Disables storylines display
		this._muteStorylineTriggres = false;

		// Stage indext to display
		this.selectedLocation = 0;
		this.isLocationTransitionActive = false;
		this._storylinesQueue = [];

		this.pushNewOfficeView();
		AppCore.instance.itd5ka.shell.events.on('user_click', this._click, this);
		this.button_enabled = true;

		this.listenTriggerEvents();
		this.registerTimers();
		this.registerStorylineEvents();
		this.registerAchievementsDisplay();

		this.cache = {
			gold: -1,
			gems: -1,
			coffee: -1,
			last_time_profit_collect: 0,
			last_time_profit_update: 0
		};
	}

	reset(restoreScrollPosition = false, uIndex = -1) {
		let scrollPosition = getScrollPos();
		this.view = VIEWS.OFFICE;
		AppCore.instance.views.cut(1);
		this.pushNewOfficeView(uIndex);
		if (restoreScrollPosition) setScrollPos(scrollPosition);
	}

	pushNewOfficeView(uIndex = -1) {
		let upgradeMulIndex = uIndex;
		if (upgradeMulIndex < 0) {
			if (this.officeView && this.officeView.upgradeMulIndex) {
				upgradeMulIndex = this.officeView.upgradeMulIndex;
			} else {
				upgradeMulIndex = 0;
			}
		}
		this.officeView = new OfficeView(upgradeMulIndex);		
		AppCore.instance.views.push(this.officeView);
	}

	/**
	 * .
	 */
	listenTriggerEvents() {
		AppCore.instance.events.on('itd5ka_user_action', ({ key, args }) => {
			//logger.group('GAMEPLAY_ACTION', 'itd5ka_user_action, ' + key, args);
			AppCore.instance.events.emit('time_to_check_triggers');
			
			const currTime = Date.now();
			if (this.lastTime === undefined) this.lastTime = currTime;
			const dt = currTime - this.lastTime;
			if (dt > 10 * 1000) {
				AppCore.instance.saveGameDataOnServer();
				this.lastTime = currTime;
			}
		});

		AppCore.instance.events.on('td_battle_finished', () => {
			AppCore.instance.itd5ka.shell.resetMenuElements();
			AppCore.instance.events.emit('time_to_check_triggers');
		});
	}

	/**
	 * Starts drawing achievements on journal page
	 */
	registerAchievementsDisplay() {
		this.achievementsElements = {};
		const shell = AppCore.instance.itd5ka.shell;

		const config = AppCore.instance.game.raw.content.config.content;
		const achievements = config.achievements;

		// #draft 220724
		// --- notification
		const button = shell.html.button_journal;
		const buttonRect = button.getBoundingClientRect();
		const buttonPos = {
			x: buttonRect.x + buttonRect.width / 2,
			y: buttonRect.y + buttonRect.height / 2
		};
		const notification = () => {
			if (!(AppCore.instance.views.top() instanceof JournalView)) button.classList.add('notification');
			AppCore.instance.itd5ka.shell.effects.spawnAreaEffect(buttonPos, 'notification');
		};
		// ---

		const test = () => {
			for (const k in achievements) {
				const conf = achievements[k];
				const item = AppCore.instance.game.inventory.get(k);
				const entry = this.achievementsElements[k];
				//console.log('test achievement', item, entry);

				if (conf.displayOn && conf.displayOn.length) {
					const visible = AppCore.instance.game.achievements.test(conf.displayOn, 'd' + k);
					const toggled = entry.visible(visible);
					if (!visible) {
						continue;
					}

					if (toggled) {
						notification();
					}
				}

				if (item.status !== 0) {
					entry.setStatus(item.status);
					continue;
				}

				if (!conf.triggers) {
					continue;
				}

				const completed = AppCore.instance.game.achievements.test(conf.triggers, 't' + k);
				if (completed) {
					AppCore.instance.logOnServer("user_complete_quest", { id: item.id });
					AppCore.instance.game.actions.action('set_achievements_status', {
						id: item.id,
						status: 1
					});
					const toggled = entry.setStatus(item.status);
					if (toggled) {
						notification();
					}
				}
			}
		};

		for (const k in achievements) {
			const conf = achievements[k];
			const entry = shell.constructJournalEntry(k);
			this.achievementsElements[k] = entry;
			logger.group('GAME_RES_OPERATION', 'achievements entry created #' + k);

			if (conf.sprite) {
				entry.setIcon(conf.sprite);
			}
			entry.setLabel(conf.title);
			entry.setDescription(conf.description);
		}

		AppCore.instance.events.on('time_to_check_triggers', () => {
			test();
		});
		test();
	}

	switchButton(on) {
		this.button_enabled = on;
	}

	/**
	 * Starts storyline if possible
	 */
	_storylinesTrigger() {
		if (this._currentlyInBattle || !this._storylinesQueue.length) {
			return;
		}

		const id = this._storylinesQueue.shift();

		const config = AppCore.instance.game.raw.content.config.content;
		const storylineConf = config.storylines[id];
		const strl = AppCore.instance.game.inventory.get(id);

		if (strl.status > 0) {
			return;
		}

		AppCore.instance.game.actions.action('set_storyline_status', {
			id,
			status: 1
		});

		const queue = storylineConf.text.split(';;;');

		const callback = () => {
			AppCore.instance.game.actions.action('set_storyline_status', {
				id,
				status: 2
			});

			if (storylineConf.reward) {
				this.claimReward(storylineConf.reward);
			}
		};

		if (window.globalsettings.devmode) {
			callback();
		} else {
			AppCore.instance.itd5ka.shell.log(`Storyline '${id}' triggered on [${storylineConf.triggers}]`);
			AppCore.instance.itd5ka.shell.cutscenePush({
				text: queue,
				titleA: storylineConf.titleA,
				titleB: storylineConf.titleB,
				spriteA: storylineConf.spriteA,
				spriteB: storylineConf.spriteB,
				spriteMuted: storylineConf.sprite_muted,
				video: storylineConf.video,
				video_gems_reward: storylineConf.video_gems_reward,
				callback
			});
		}
	}

	/**
	 * Search cutscene in the cutscenes queue
	 *
	 */
	cutscenesQueueVideo() {
		const config = AppCore.instance.game.raw.content.config.content;
		const storylines = config.storylines;
		let count = 0;
		for (const k in storylines) {
			const conf = storylines[k];
			const strl = AppCore.instance.game.inventory.get(k);
			if (!strl.started) {
				if (conf.video !== null) return conf.video;
			}
			count++;
			if (count >= 10) break;
		}
		return null;
	}

	/**
	 *
	 */
	registerStorylineEvents() {
		const config = AppCore.instance.game.raw.content.config.content;
		const storylines = config.storylines;

		const test = () => {
			for (const k in storylines) {
				const conf = storylines[k];
				const strl = AppCore.instance.game.inventory.get(k);
				try {
					if (!strl.started && AppCore.instance.game.achievements.test(conf.triggers, k)) {
						this._storylinesQueue.push(k);
					}
				} catch (err) {
					logger.groupWarn(
						'GAMEPLAY_TEXT_PRINT',
						`ViewRoot.testStoryline(${k});  triggers [${conf.triggers}] error: `,
						err
					);
				}
			}
		};

		AppCore.instance.events.on(
			'time_to_check_triggers',
			() => {
				test();

				if (this._muteStorylineTriggres) {
					return;
				}

				this._storylinesTrigger();
			},
			this
		);
	}

	/**
	 *
	 * @param {string} reward reward id
	 * @param {string} [message] reward message
	 */
	claimReward(reward, message = '') {
		this._muteStorylineTriggres = true;

		const rewardData = { id: reward };
		AppCore.instance.logOnServer("user_quest_rewarded", rewardData);
		AppCore.instance.game.actions.action('claim_reward', rewardData);

		if (!AppCore.instance.itd5ka.shell.isPopupHidden()) AppCore.instance.views.pop();
		AppCore.instance.views.push(new RewardView(inventoryGet(reward).icon));
		AppCore.instance.itd5ka.shell.events.once('popup_hidden', () => {
			this._muteStorylineTriggres = false;
			this._storylinesTrigger();
		});
	}

	/**
	 * #draft
	 */
	registerTimers() {
		//const coinsel = document.getElementById('td_currency_text_coin');
		//const ticketsel = document.getElementById('td_currency_text_tickets');
		//const coffeeel = document.getElementById('td_currency_text_coffee');
		this.tickId = AppCore.instance.ticker.events.on('game_tick', (dt, dtf, timestamp) => {
			const time = Date.now();
			if (time - this.cache.last_time_profit_collect > 1000) {
				action('collect_profit', { time: time }, true);
				this.cache.last_time_profit_collect = time;
			}

			if (time - this.cache.last_time_profit_update > 100) {
				const gold = AppCore.instance.game.inventory.getFormattedAmount('gold');
				const gems = AppCore.instance.game.inventory.getFormattedAmount('gems');
				const coffee = AppCore.instance.game.inventory.getFormattedAmount('coffee');
	
				if (this.cache.gold != gold) {
					AppCore.instance.itd5ka.shell.print(gold, 'gold');
					this.cache.gold = gold;
				}
				if (this.cache.gems != gems) {
					AppCore.instance.itd5ka.shell.print(gems, 'gems');
					this.cache.gems = gems;
				}
				if (this.cache.coffee != coffee) {
					AppCore.instance.itd5ka.shell.print(coffee, 'coffee');
					this.cache.coffee = coffee;
				}
				this.cache.last_time_profit_update = time;
			}

			/*
			// 220407
			AppCore.instance.itd5ka.shell.print(gold, 'gold', 'label_minified');
			AppCore.instance.itd5ka.shell.print(gems, 'gems', 'label_minified');
			AppCore.instance.itd5ka.shell.print(coffee, 'coffee', 'label_minified');
			*/
			this._updateBattle(dt, dtf, timestamp);
		}).count;
	}

	/* eslint-disable complexity, max-statements, max-lines-per-function */
	/**
	 * #draft
	 *
	 * @param {number} dt .
	 * @param {number} dtf .
	 * @param {number} timestamp .
	 */
	_updateBattle(dt, dtf, timestamp) {
		this._currentlyInBattle = false;

		const battles = AppCore.instance.game.battles;

		for (const k in battles) {
			const battle = battles[k];
			battle.step(timestamp);
			this._currentlyInBattle = true;
		}

		if (!this._currentlyInBattle) {
			const inventory =
				Object.values(AppCore.instance.game.getData('inventory').content)
				.sort(InventoryView.sortByItemRarityRate);
			
			for (const index in inventory) {
				const item = inventory[index];
				if (item.type === 'employee') {
					const employee = AppCore.instance.game.inventory.getItdItem(item.id);
					if (employee && employee.property('injuries') > 0) {
						let injuries = Math.max(
							0,
							employee.property('injuries') - employee.property('healthRegen') * (dt / 1000)
						);
						injuries = mathjs.round(injuries, 2);
						employee.property('injuries', injuries);
					}
				} else if (item.type === 'gadget') {
					const gadget = AppCore.instance.game.inventory.getItdItem(item.id);
					if (gadget && gadget.property('chargesUsed') > 0) {
						let chargesUsed = Math.max(
							0,
							gadget.property('chargesUsed') - dt / (gadget.property('regenRate') * 1000)
						);
						chargesUsed = mathjs.round(chargesUsed, 10);
						gadget.property('chargesUsed', chargesUsed);
					}
				}
			}

			// #draft - restoring all hp after battle
			/*for (const i in AppCore.instance.game.inventory.psources) {
				const room = AppCore.instance.game.inventory.psources[i];
				const employee = AppCore.instance.game.inventory.getItdItem(room.employee);
				const gadget = AppCore.instance.game.inventory.getItdItem(room.gadget);

				if (employee && employee.property('injuries') > 0) {
					let injuries = Math.max(
						0,
						employee.property('injuries') - employee.property('healthRegen') * (dt / 1000)
					);
					injuries = mathjs.round(injuries, 2);
					employee.property('injuries', injuries);
				}
				if (gadget && gadget.property('chargesUsed') > 0) {
					let chargesUsed = Math.max(
						0,
						gadget.property('chargesUsed') - dt / (gadget.property('regenRate') * 1000)
					);
					chargesUsed = mathjs.round(chargesUsed, 10);
					gadget.property('chargesUsed', chargesUsed);
				}
			}*/
		}
	}
	/* eslint-enable complexity, max-statements, max-lines-per-function */

	/**
	 * .
	 */
	dispose() {
		AppCore.instance.ticker.events.off(this.tickId);
		AppCore.instance.itd5ka.shell.events.off('user_click', this._click);
		for (const k in this.achievementsElements) {
			this.achievementsElements[k].dispose();
		}
	}
}
