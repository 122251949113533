/**
 * @File AssetsManager.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import logger from '../core/logger.js';

/**
 * Manages assets
 */
export default class AssetsManager {
	/**
	 * .
	 */
	init() {
		this.textures = {};
		this.guids = 0;
	}

	/**
	 * .
	 *
	 * @async
	 * @param {object} list asests list to load
	 * @returns {Promise} .
	 */
	load(list) {
		const promises = [];
		for (const k in list) {
			const arg = list[k];

			if (Array.isArray(arg)) {
				promises.push(this.loadArray(arg));
			} else {
				promises.push(this.loadImg(k, arg));
			}
		}

		return Promise.all(promises);
	}

	/**
	 * .
	 *
	 * @async
	 * @param {Array} list asests list to load
	 * @returns {Promise} .
	 */
	loadArray(list) {
		const promises = [];
		for (const i in list) {
			promises.push(this.loadImg('img' + this.guids++, list[i]));
		}

		return Promise.all(promises);
	}

	/**
	 * .
	 *
	 * @param {string} name .
	 * @param {string} path .
	 */
	loadImg(name, path) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = 'res/' + path;
			img.onload = () => {
				logger.group('GAME_RES_OPERATION', `Image ${name} (path: '${path}') loaded`);
				this.textures[name] = img;
				resolve();
			};
			img.onerror = () => {
				const str = `Image ${name} (path: '${path}') load error`;
				logger.groupError('GAME_RES_ERROR', str);
				reject(new Error(str));
			};
		});
	}
}
