/**
 * @File HttpRequest.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Core
 */

/**
 *
 */
export default class HttpRequest {
	/**
	 *
	 */
	constructor() {
		this.headers = {};
	}

	/**
	 *
	 */
	setHeader(header, data) {
		this.headers[header] = data;
	}

	/**
	 *
	 */
	init(baseurl) {
		this._baseurl = baseurl;
	}

	/* eslint-disable complexity, max-params */
	/**
	 * xmlHttpRequest. Sends json data
	 *
	 * @param {object} param .
	 * @param {string} param.url url string to request
	 * @param {string} [param.data] json data
	 * @param {string} [param.method = 'GET'] GET POST DELETE PUT
	 * @param {string} [param.type = 'application/json'] content-type header
	 * @param {object} [param.headers] headers list
	 * @returns {Promise} .
	 */
	static request({ url, data, method = 'GET', type = 'application/json', headers }) {
		return new Promise((resolve, reject) => {
			const xmlHttp = new XMLHttpRequest();
			xmlHttp.onreadystatechange = () => {
				if (xmlHttp.readyState === 1 && (method === 'POST' || method === 'PUT')) {
					for (const k in headers) {
						xmlHttp.setRequestHeader(k, headers[k]);
					}
					xmlHttp.setRequestHeader('Content-Type', type);
				}

				if (xmlHttp.readyState === 4) {
					let response = xmlHttp.responseText;
					if (xmlHttp.getResponseHeader('Content-Type')?.includes('application/json')) {
						response = JSON.parse(xmlHttp.responseText);
					}

					if (xmlHttp.status === 200) {
						resolve(response);
					} else if (xmlHttp.status === 500 || xmlHttp.status === 404 || xmlHttp.status === 400 ||
						xmlHttp.status === 401 || xmlHttp.status === 403) {
						reject(response);
					}
				}
			};
			xmlHttp.open(method, url, true);
			xmlHttp.send(data);
		});
	}

	/**
	 * xmlHttpRequest. Sends json data
	 *
	 * @param {string} url url string to request
	 * @param {string} data json data
	 * @param {string} method GET POST DELETE PUT
	 * @param {string} type content-type header
	 * @returns {Promise} .
	 */
	request(url, data, method, type = 'application/json') {
		return HttpRequest.request({ url, data, method, type, headers: this.headers });
	}
	/* eslint-enable complexity, max-params */

	/**
	 * GET request
	 *
	 * @param {string} url url to get
	 * @returns {Promise} .
	 */
	get(url) {
		return this.request(this.baseurl + url, null, 'GET');
	}

	/**
	 * POST request
	 *
	 * @param {string} url url to post
	 * @param {object} data data to post
	 * @returns {Promise} .
	 */
	post(url, data) {
		return this.request(this.baseurl + url, JSON.stringify(data), 'POST');
	}

	/**
	 * PUT request
	 *
	 * @param {string} url url to post
	 * @param {object} data data to post
	 * @returns {Promise} .
	 */
	put(url, data) {
		return this.request(this.baseurl + url, JSON.stringify(data), 'PUT');
	}

	/**
	 * DELETE request
	 *
	 * @param {string} url url to post
	 * @returns {Promise} .
	 */
	del(url) {
		return this.request(this.baseurl + url, null, 'DELETE');
	}

	/**
	 * @returns {string} base url
	 */
	get baseurl() {
		return this._baseurl || '';
	}
}
