/**
 * @File EmployeeDetailsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { action } from '../shorthands.js';
import ItdItemCard from '../tovdef/ItdItemCard.js';
import InventoryView from './InventoryView.js';
import { formatAssignedName } from '../dust/ui_utils.js';

const EMPLOYEE_STATS_DESCRIPTIONS = [
	'Боевой параметр, отвечающий за частоту атак менеджера по спринту.',
	'Боевой параметр, определяющий количество урона, которое менеджер наносит по таску за один удар.',
	'Боевой параметр, определяющий максимальное здоровье менеджера.'
];

const EMPLOYEE_BONUS_DESCRIPTIONS = {
	profit_bonus: 'Бонус к сумме дохода этажа',
	upgrade_bonus: 'Скидка на улучшение этажа',
	threshold_bonus: 'Ускорение получения дохода с этажа',
	click_bonus: 'Бонус к урону кликами'
};

const EFFECT_BONUS_DESCRIPTION = {
	heal_bonus: 'Восстанавливает _effectValue_ единиц здоровья'
};

const ASSIGNED_NAMES_WITH_ON_PREFIX = [
	"Склад"
];

/**
 * Game root view
 */
export default class EmployeeDetailsView extends ViewUiBase {
	/**
	 * @param {string} employeeid employee id to display
	 * @param {string} roomid opened room id
	 */
	constructor(employeeid, roomid) {
		super();

		this.employeeid = employeeid;
		this.roomid = roomid;
		this._currentTime = -1;
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		const item = AppCore.instance.game.inventory.getItdItem(this.employeeid);
		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				this._recalc_injure();
				AppCore.instance.logOnServer("user_tap", {name: e.target.id}, true);
				AppCore.instance.views.pop();
				break;
			case 'assign_btn':
				const actionData = {
					type: item.type,
					itemid: this.employeeid,
					roomid: this.roomid
				};

				AppCore.instance.logOnServer("user_attach_manager", actionData);

				action('assign_item', actionData);
				AppCore.instance.views.pop(); // this view
				AppCore.instance.views.pop(); // room details view
				AppCore.instance.itd5ka.shell.popupHide();
				//this.render();
				break;
			case 'deassign_btn': {
				// #dust-220405-00
				let field_key = 'assigned';
				if (this.roomid) {
					const r = AppCore.instance.game.inventory.get(this.roomid);
					field_key = r.type === 'psource' ? 'assigned' : 'dust_assignedPvp';
				}
				const room = AppCore.instance.game.inventory.get(item.property(field_key));
				const actionData = {
					type: item.type,
					itemid: this.employeeid,
					roomid: room.id
				};

				AppCore.instance.logOnServer("user_deattach_manager", actionData);

				action('assign_item', actionData);

				AppCore.instance.views.pop();

				break;
			}
			default:
				break;
		}
	}

	/**
	 * show.
	 */
	show() {
		AppCore.instance.itd5ka.shell.popup('itd_employee_details', false);

		const container = document.querySelector('page#itd_employee_details');
		const _qsearch = (query) => container.querySelector(query);

		this.container = container;
		this._qsearch = _qsearch;

		this.render();
	}

	_recalc_injure() {
		const item = AppCore.instance.game.inventory.getItdItem(this.employeeid);
		if (item.type == 'employee') {
			if (item.property('battle_endtime') > 0) {
				let dt = Date.now() - this._currentTime;
				this._currentTime = Date.now();
				let injuries = Math.max(
					0,
					item.property('injuries') - item.property('healthRegen') * (dt / 1000)
				);
				injuries = mathjs.round(injuries, 2);
				item.property('injuries', injuries);
				if (item.property('health') >= item.property('healthMax')) {
					item.property('battle_endtime', -1);
					this._currentTime = -1;
				}
			}
		}
	}

	/* eslint-disable complexity, max-statements, max-lines-per-function */
	/**
	 * .
	 */
	render() {
		const item = AppCore.instance.game.inventory.getItdItem(this.employeeid);
		const itemraw = AppCore.instance.game.inventory.getItdItem(this.employeeid).raw.content;
		if (item.type == 'employee') {
			if (item.property('battle_endtime') > 0) {
				if (this._currentTime < 0) {
					this._currentTime = item.property('battle_endtime');
				}
			}
		}

		// #dust-220405-00
		let field_key = 'assigned';
		if (this.roomid) {
			const r = AppCore.instance.game.inventory.get(this.roomid);
			field_key = r.type === 'psource' ? 'assigned' : 'dust_assignedPvp';
		}

		const room = AppCore.instance.game.inventory.get(item.property(field_key));

		const amount = item.amount;
		const upgcost = itemraw.levels[item.level] ?? null;
		const icon = itemraw.icon;
		const energy = item.property('healthMax') ? item.property('health') : item.property('charges');
		const energyMax = item.property('healthMax')
			? item.property('healthMax')
			: item.property('chargesMax');
		const energySign = item.property('healthMax') ? '♥' : '✦';
		const rarity = item.property('rarity');
		const rank = this._getEmployeeRankText(item);

		// Text
		this._qsearch('label#header').innerHTML =
			item.type === 'employee' ? 'сотрудник' : 'оборудование';
		this._qsearch('label#name').innerHTML = `${item.property('name')} ур.${item.property('level')}`;
		this._qsearch('text#employee_description').innerHTML = item.property('description');

		// Icon
		const iconel = this._qsearch('icon');
		iconel.classList.value = '';
		iconel.classList.add('textures-' + icon);
		const stats = this._qsearch('stats');
		const entries = stats.getElementsByTagName('entry');
		for (let i = 0; i < entries.length; i++) {
			entries[i].classList.add('hidden');
		}
		if (rarity) {
			const el = this._qsearch('entry#rarity');
			const args = rarity.split(',');
			el.classList.value = `rarity ${args[0]}`;
			el.innerText = `${args[1]}`;
		}
		if (rank) {
			const el = this._qsearch('entry#rank');
			el.innerText = `${rank}`;
			el.classList.remove('hidden');
		}

		// Badges
		this._renderBadge('profit_bonus', item);
		this._renderBadge('upgrade_bonus', item);
		this._renderBadge('threshold_bonus', item);
		this._renderBadge('click_bonus', item);
		
		this._renderEffectBadge('heal', 'heal_bonus', item);

		// Labels
		const labels = this._qsearch('labels');
		//labels.innerHTML = '';

		const assigned = itemraw.assigned;
		let assignedName = 'Свободен';
		if (assigned) {
			assignedName = formatAssignedName(AppCore.instance.game.inventory.get(assigned).name);
		}
		const assigned_office = assigned ? assigned.split('_').splice(0, 2).join('-') : 'no';
		const assignedel = this._qsearch('entry#assigned');
		assignedel.innerText = `${assignedName}`;
		assignedel.classList.value = `assigned-floor-${assigned_office}`;
		//labels.innerHTML += `<entry class='assigned-floor-${assigned_office}'>${assignedName}</entry>`;
		const deassignel = this._qsearch('button#deassign_btn');
		if (room) {
			//labels.innerHTML += `<button id='deassign_btn'>Отменить назначение`;
			deassignel.classList.remove('hidden');
		} else {
			deassignel.classList.add('hidden');
		}
		const assignel = this._qsearch('button#assign_btn');
		if (this.roomid && (!room || room.id !== this.roomid)) {
			assignel.classList.remove('hidden');
			const r = AppCore.instance.game.inventory.getItdItem(this.roomid);
			let assignName = formatAssignedName(r.property('name'));
			//labels.innerHTML += `<button id='assign_btn'>${this.getAssignedNamePrefix(assignName)} ${assignName}`;
			assignel.innerText = `${this.getAssignedNamePrefix(assignName)} ${assignName}`;
		} else {
			assignel.classList.add('hidden');
		}

		this._renderParams(item);

		// Level
		const level = this._qsearch('progressbar#level');
		level.classList.value = 'expirience';		
		level.innerHTML = upgcost !== null ? `★${amount}/${upgcost}` : '★МАКС';
		level.style.setProperty('--progress', `${upgcost !== null ? (amount / upgcost) * 100 : 100}%`);

		// Energy
		const energyel = this._qsearch('progressbar#energy');
		energyel.classList.value = String(item.property('healthMax') ? 'health' : 'charges');
		energyel.innerHTML = `${energySign}${Math.floor(energy)}`;
		energyel.style.setProperty('--progress', `${(energy / energyMax) * 100}%`);
	}

	getAssignedNamePrefix(assignedName) {
		if (ASSIGNED_NAMES_WITH_ON_PREFIX.indexOf(assignedName) > -1) {
			return "Назначить на";
		}

		return "Назначить в";
	}

	/* eslint-enable complexity, max-statements, max-lines-per-function */

	/**
	 * @param {string} name badge name
	 * @param {ItdItemCard} item .
	 */
	_renderBadge(name, item) {
		const icon = this._qsearch(`badges entry.${name}`);
		if (!icon) {
			return;
		}

		const bonus_property = item.property(name);
		const bonus = ((bonus_property - 1) * 100).toFixed(0);
		icon.classList[bonus_property ? 'remove' : 'add']('hidden');
		this.addHoverLabel(icon, `${EMPLOYEE_BONUS_DESCRIPTIONS[name]}: ${bonus}%`);
	}

	_renderEffectBadge(type, name, item) {
		const icon = this._qsearch(`badges entry.${name}`);
		if (!icon) {
			return;
		}

		const effectValue = item.property('effectValue');
		icon.classList[item.property('effectType') === type ? 'remove' : 'add']('hidden');
		this.addHoverLabel(icon, EFFECT_BONUS_DESCRIPTION[name].replace('_effectValue_', effectValue));
	}

	addHoverLabel(node, label) {
		if (AppCore.instance.is_mobile()) {
			node.onclick = (e) => node.setAttribute('hover-label', label);
		} else {
			node.setAttribute('hover-label', label);
		}
	}

	removeHoverLabel(node) {
		if (AppCore.instance.is_mobile()) {
			node.onclick = null;
		}
		node.removeAttribute('hover-label');
	}

	getStatElement(container, text, descriptionIndex) {
		const statElement = container.querySelector(`li#param${descriptionIndex}`);
		//const statElement = document.createElement('li');
		statElement.innerText = text;
		this.addHoverLabel(statElement, EMPLOYEE_STATS_DESCRIPTIONS[descriptionIndex]);
		statElement.classList.remove('hidden');
		return statElement;
	}

	/**
	 * @param {ItdItemCard} item .
	 */
	_renderParams(item) {
		const params = this._qsearch('params');
		const params_e = this._qsearch('params #entries');
		for (let i = 0; i < 5; i++ ) {
			const statElement = params_e.querySelector(`li#param${i}`);
			this.removeHoverLabel(statElement);
			statElement.classList.add('hidden');
		}
		switch (item.type) {
			case 'employee': {
				//params_e.innerHTML = ``;
				const attackRate = item.property('attackRate');
				const attackDamage = item.property('attackDamage');
				const healthMax = item.property('healthMax');
				this.getStatElement(params_e, `Организованность: ${attackRate}`, 0);
				this.getStatElement(params_e, `Креативность:  ${attackDamage}`, 1);
				this.getStatElement(params_e, `Стрессоустойчивость: ${healthMax}`, 2);
				break;
			}
			case 'gadget': {
				const statElement = params_e.querySelector(`li#param0`);
				statElement.innerHTML = item.property('description_stats');
				statElement.classList.remove('hidden');
				break;
			}
			default:
				break;
		}
		// TODO: crap, need to review/rewrite input completely
		params.classList.add('hidden');
		setTimeout((() => {
			params.classList.remove('hidden');
		}).bind(this), 50);
	}

	/**
	 * @param {ItdItemCard} employee .
	 * @returns {string?} .
	 */
	_getEmployeeRankText(employee) {
		const level = employee.property('level');
		const levels = employee.property('ranksLevels');

		if (!levels || !levels.length) {
			return null;
		}

		const names = employee.property('ranksNames');
		let name = names[names.length - 1];

		for (let i = levels.length - 1; i >= 0; i--) {
			const l = levels[i];
			if (level >= l) {
				name = names[i];
				break;
			}
		}

		return name;
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
