/**
 * @File ItemCard.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Game/Generic
 */

import { StandardRawData } from '../../core/standart_data_helpers';
import math from '../../core/mathjs';

const VALUE_FUNCTION_PREFIX = '@fn:';
const CACHED_LEVEL_VALUES = [
	'profit',
	'threshold_bonus',
	'upgrade_bonus',
	'profit_bonus',
	'effectValue',
	'cost',
	'healthMax',
	'healthRegen',
	'attackDamage'
];

/**
 * @constructor
 * @param {ItemCard} context context to work with
 * @param {object?} [custom=null] costom expressions functions
 */
function EXPRESSIONS_SCOPE(context, custom = null) {
	// ===
	// Scope functions

	// ===
	// Utils
	/**
	 * @param {string} key key to check
	 * @returns {boolean} .
	 */
	function contextHas(key) {
		return context.raw.content.hasOwnProperty(key);
	}

	/**
	 * @param {string} key key to check
	 * @returns {boolean} .
	 */
	function customHas(key) {
		return custom ? custom.hasOwnProperty(key) : false;
	}

	/**
	 * @param {string} key key to check
	 * @returns {boolean} .
	 */
	function customGet(key) {
		return typeof custom[key] === 'function' ? custom[key].bind(context) : custom[key];
	}

	this.get = function get(key) {
		return contextHas(key) ? context.property(key) : customGet(key);
	};
	this.has = function has(key) {
		return contextHas(key) || customHas(key);
	};
	this.keys = function keys() {
		return new Set([...Object.keys(context.raw.content), ...Object.keys(custom)]);
	};
	this.set = function set(key, value) {
		context.raw.content[key] = value;

		return value;
	};
}

/**
 * Represents generic game Item
 */
export default class ItemCard {
	/**
	 * @param {StandardRawData} raw raw item data
	 * @param {object?} [scope=null] expressions custom scope
	 */
	constructor(raw, scope = null) {
		this.raw = raw;
		this.scope = scope;
		this.clearCache();
	}

	clearCache() {
		this.cache = {
			expressions: {},
			values: {}
		};
	}

	/**
	 * Returns or sets property.
	 *
	 * @param {string} key property key
	 * @param {*?} value property value
	 * @returns {number|string|*} value in raw.content
	 */
	property(key, value) {
		if (typeof value === 'undefined') {
			return this.propertyGet(key);
		} // else

		return this.propertySet(key, value);
	}

	/**
	 * Returns static or calculated value
	 *
	 * @param {string} key value key
	 * @returns {number|string|*} value stored in raw.content
	 */
	propertyGet(key) {
		let prop = this.raw.content[key];
		if (typeof prop === 'string' && prop.startsWith(VALUE_FUNCTION_PREFIX)) {
			prop = this.expression(prop, key);

			const number = Number(prop);
			prop = isNaN(number) ? prop : number;
		}

		return prop === null ? 0 : prop;
	}

	/**
	 * Sets value
	 *
	 * @param {string} key value key
	 * @param {*} value value
	 * @returns {number|string|*} value stored in raw.content
	 */
	propertySet(key, value) {
		this.raw.content[key] = value;

		return value;
	}

	/**
	 * Calculates value from provided expression
	 *
	 * @param {string} string expression to calculate
	 * @param {string?} [cache=null] use cache path
	 * @returns {number} calculated value
	 */
	expression(string, cache = null) {
		try {
			let mathprops = null;
			if (!cache || !this.cache.expressions[cache]) {
				const expression = string.substring(VALUE_FUNCTION_PREFIX.length);
				const code = math.compile(expression);
				const scope = new EXPRESSIONS_SCOPE(this, this.scope);

				mathprops = { code, scope };

				if (cache) {
					this.cache.expressions[cache] = mathprops;
				}
			}

			if (!mathprops) {
				mathprops = this.cache.expressions[cache];
			}

			let value = null;
			if (CACHED_LEVEL_VALUES.indexOf(cache) >= 0) {
				const key = cache + '_' + this.level;
				if (!this.cache.values[key]) {
					value = mathprops.code.evaluate(mathprops.scope);
					this.cache.values[key] = value;
				} else {
					value = this.cache.values[key];
				}
			} else {
				value = mathprops.code.evaluate(mathprops.scope);
			}
			return value;
		} catch (err) {
			console.error(err);
			throw new Error('Expression parse error: ' + string, err);
		}
	}

	/**
	 * @returns {string} .
	 */
	get type() {
		return this.raw.content.type;
	}

	/**
	 * @returns {string} .
	 */
	get id() {
		return this.raw.meta.id;
	}
}
