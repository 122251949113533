/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { Pane } from 'tweakpane';
import { setDevPreset, setInitialState } from './dust/devpresets.js';
import Config from './dust/config.js';
import AppCore from './AppCore.js';
import { re } from 'mathjs';

/**
 * Manages dev UI controls
 */
export default class Devpanel {
	/**
	 * .
	 */
	constructor() {
		try {
			this.pane = new Pane({
				title: 'dev'
			});
			this.pane.expanded = false;
			this.state = {
				// --- Флаг переключения в режим разработчика
				devmode: false,
				// --- Флаг отображения отладочной информации
				debugmode: false,
				// --- Флаг подгрузки конфига по кастомной ссылке
				remoteconf: false,
				// --- Флаг подгрузки конфига с компьютера
				localconf: false,
				// --- Путь подгрузки кастомной ссылки
				remoteconfPath: 'https://pastebin.com/raw/hpTsuyUp',
				// --- Отображение состояния сервера
				serverStatus: 'disconnected'
			};
			const pel = document.getElementsByClassName('tp-dfwv');
			pel[0].style.top = 'auto';
		} catch (err) {
			console.error('new Devpanel() error: ', err);
		}
	}

	/**
	 * .
	 */
	init() {
		try {
			this._init();
		} catch (err) {
			console.error('Devpanel.init() error: ', err);
		}

		return this;
	}

	_init() {
		const tabs = this.pane.addTab({
			pages: [{ title: 'General' }, { title: 'Server' }, { title: 'Game' }, { title: 'Audio' }]
		});
		const gameTab = tabs.pages[2];
		const audioTab = tabs.pages[3];

		// --- tab: General

		tabs.pages[0].addInput(this.state, 'debugmode').on('change', () => {
			const d = this.state.debugmode;
			window.globalsettings.debugmode = d;
			document.body.classList[d ? 'add' : 'remove']('debugmode');
		});

		tabs.pages[0].addInput(this.state, 'devmode').on('change', () => {
			window.globalsettings.devmode = this.state.devmode;
		});

		tabs.pages[1].addButton({ title: 'Reset server user' }).on('click', () => {
			AppCore.instance.api.post('users/wipe', { fid: AppCore.instance.userid }).then((response) => {
				setDevPreset(3);
			});
		});

		tabs.pages[1].addMonitor(this.state, 'serverStatus', { title: 'Server status' });

		this._initAlterConfigInputs(tabs.pages[0]);

		// --- Tab: game
		// --- folder: Presets

		const presetsFolder = gameTab.addFolder({
			title: 'Presets'
		});

		presetsFolder.addButton({ title: 'Initial' }).on('click', () => {
			setDevPreset(0);
		});

		presetsFolder.addButton({ title: 'Preset 1' }).on('click', () => {
			setDevPreset(1);
		});

		presetsFolder.addButton({ title: 'Gold Resources Preset' }).on('click', () => {
			setDevPreset(2);
		});

		presetsFolder.addButton({ title: 'Gems Resources Preset' }).on('click', () => {
			setDevPreset(4);
		});

		presetsFolder.addButton({ title: 'Coffee Resources Preset' }).on('click', () => {
			setDevPreset(5);
		});

		// --- Tab: game

		// --- folder: Balace
		const balance = {
			profit: 'profitBase * level * 2',
			cost: 'costBase * 1.15^level',
			timescale: 1,
			profitscale: 1
		};

		const balanceFolder = gameTab.addFolder({
			title: 'Balance'
		});
		balanceFolder.addInput(balance, 'timescale').on('change', () => {
			AppCore.instance.game.actions.timescale = balance.timescale;
		});
		balanceFolder.addInput(balance, 'profitscale').on('change', () => {
			AppCore.instance.game.actions.profitscale = balance.profitscale;
		});

		balanceFolder.addInput(balance, 'profit').on('change', () => {
			const psources = AppCore.instance.game.inventory.psources;
			for (const i in psources) {
				const ps = psources[i];
				ps.profit = '@fn: ' + balance.profit;
			}
		});
		balanceFolder.addInput(balance, 'cost').on('change', () => {
			const psources = AppCore.instance.game.inventory.psources;
			for (const i in psources) {
				const ps = psources[i];
				ps.cost = '@fn: ' + balance.cost;
			}
		});

		// --- Tab: game
		// --- folder: Triggers
		const triggersFolder = gameTab.addFolder({
			title: 'Triggers'
		});

		const container = {
			output: false,
			trigger: ''
		};

		triggersFolder.addInput(container, 'trigger').on('change', () => {
			container.output = !!AppCore.instance.game.achievements.test(container.trigger);
		});
		triggersFolder.addMonitor(container, 'output');

		// --- Tab: game
		// --- folder: Appearance
		const appearanceFolder = gameTab.addFolder({
			title: 'Appearance'
		});

		const colors = {
			a: '#9cb93b',
			b: '#d1401f',
			c: '#ffd832',
			white: '#ffffe4'
		};

		appearanceFolder.addInput(colors, 'a').on('change', () => {
			document.body.style.setProperty('--color-5ka-a', colors.a);
		});

		appearanceFolder.addInput(colors, 'b').on('change', () => {
			document.body.style.setProperty('--color-5ka-b', colors.b);
		});

		appearanceFolder.addInput(colors, 'c').on('change', () => {
			document.body.style.setProperty('--color-5ka-c', colors.c);
		});

		appearanceFolder.addInput(colors, 'white').on('change', () => {
			document.body.style.setProperty('--color-5ka-white', colors.white);
		});

		// -- Tab: Audio
		audioTab.addButton({ title: `stop all` }).on('click', () => {
			for (const k in AppCore.instance.sounds.assets) {
				AppCore.instance.sounds.stop(k);
			}
		});

		audioTab.addSeparator();

		for (const k in AppCore.instance.sounds.assets) {
			const asset = AppCore.instance.sounds.assets[k];
			audioTab.addButton({ title: `play sound '${k}'` }).on('click', () => {
				AppCore.instance.sounds.play(k);
			});
		}

		return this;
	}

	/**
	 *
	 * @param {object} page .
	 */
	_initAlterConfigInputs(page) {
		// Remote config controls
		let remoteConfigPathInput = null;
		let remoteConfigFlagInput = null;
		let alterCongifFlagInput = null;
		let alterCongifButtonInput = null;

		let alterConfigData = null;

		const upd = () => {
			remoteConfigPathInput.hidden = !this.state.remoteconf;
			alterCongifButtonInput.hidden = !this.state.localconf;

			if (this.state.remoteconf) {
				Config.download(this.state.remoteconfPath).then(setInitialState);
			} else if (this.state.localconf && alterConfigData) {
				Config.set(JSON.parse(alterConfigData));
				setInitialState();
			} else {
				Config.discard();
				setInitialState();
			}

			//page.refresh();
		};

		remoteConfigFlagInput = page.addInput(this.state, 'remoteconf', {
			label: 'Remote config'
		});
		remoteConfigPathInput = page.addInput(this.state, 'remoteconfPath', {
			label: 'Config url'
		});
		alterCongifFlagInput = page.addInput(this.state, 'localconf', {
			label: 'Local config'
		});

		remoteConfigFlagInput.on('change', () => {
			if (this.state.remoteconf) {
				this.state.localconf = false;
			}

			upd();
		});

		const input = document.createElement('input');
		input.type = 'file';
		input.onchange = (e) => {
			// getting a hold of the file reference
			var file = e.target.files[0];

			// setting up the reader
			var reader = new FileReader();
			reader.readAsText(file, 'UTF-8');

			// here we tell the reader what to do when it's done reading...
			reader.onload = (readerEvent) => {
				alterConfigData = readerEvent.target.result; // this is the content!
				upd();
			};
		};
		alterCongifButtonInput = page.addButton({ title: 'Choose file...' }).on('click', () => {
			input.click();
		});

		alterCongifFlagInput.on('change', () => {
			if (this.state.localconf) {
				this.state.remoteconf = false;
			}

			upd();
		});

		remoteConfigPathInput.hidden = !this.state.remoteconf;
		alterCongifButtonInput.hidden = !this.state.localconf;
		//remoteConfigFlagInput.disabled = true; //unsupported
	}

	/**
	 * .
	 */
	dispose() {
		this.pane.dispose();
	}
}
