/**
 * @File BattleResultsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';

/**
 * Game root view
 */
export default class HintView extends ViewUiBase {
	constructor(name) {
		super();

		this.name = name;
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				AppCore.instance.views.pop();
				break;
		}
	}

	show() {
		AppCore.instance.itd5ka.shell.popup(this.name);
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
