/**
 * @File PvpView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import AppCore from '../AppCore.js';
import ViewUiBase from './ViewUiBase.js';
import ProfitSourceItd from '../../itd5ka/ProfitSourceItd.js';
import { autoScrollTo, listenScrollEvents, unlistenScrollEvents, holdScrollEvents } from '../dust/ui_utils.js';
import RoomDetailsView from './RoomDetailsView.js';
import { VIEW_STATES } from '../../core/views.js';
import ItdBattle from '../tovdef/ItdBattle.js';
import { config, action, viewPush } from '../shorthands.js';
import PvpResultsView from './PvpResultsView.js';
import OfficeView from './OfficeView.js';
import { generateFloorEffects } from '../../itd5ka/generateFloorEffects.js';

/**
 * Костыль поверх обычного OfficeView
 * Вся логика тут - workaround вокруг нормальной логики
 */
export default class PvpView extends ViewUiBase {
	/**
	 * @param {string} key click element key
	 * @param {oject} args click arguments
	 */
	_click(key, args) {
		switch (key) {
			case 'container_info':
				if (this.battle) {
					break;
				}
				viewPush(new RoomDetailsView(args.id));
				break;
			// case 'button_pvp_start':
			// 	this.startBattle();
			// 	break;
			// case 'button_pvp_stop':
			// 	AppCore.instance.views.pop();
			// 	AppCore.instance.views.push(new OfficeView());
			// 	break;
			case 'container_tasks': {
				if (!this.battle) {
					break;
				}
				action('battle_user_click', {
					room: args.id,
					battleid: 'pvp'
				}, true);
				const damage = config('battle_user_click', { room: args.id });
				this._draw_task_damage({ dmg: damage, roomId: args.id, dmg_type: 'click' });
				break;
			}
			case 'button_coffee': {
				action('use_coffee', {
					room: args.id,
					battleid: 'pvp'
				});
				break;
				}
		}
	}

	init() {
		this.psources = [];
		this.pvpTasks = [];

		this.currentPvpProgress = 0;
		this.pvpTimestamp = 0;
		this.pvpStartTimestamp = 0;

		this.cache = {
			autoscrollPos: -1,
			currentlyInBattleFloor: -1
		};
		// ---

		const shell = AppCore.instance.itd5ka.shell;
		shell.html.container_pvp_span.classList.value = '';
		shell.html.container_pvp_span.classList.add('state-0');

		const inventory = AppCore.instance.game.getData('inventory').content;
		for (const k in inventory) {
			const item = inventory[k];
			if (item.type !== 'pvp_floor') {
				continue;
			}
			const psItd = new ProfitSourceItd(
				item.id,
				shell.html.container_psource,
				shell.html.template_psource
			);

			psItd.init();

			this.psources.push(psItd);

			psItd.element.classList.add('pvp_floor');

			psItd.events.on('user_click', ({ detail, key }) => {
				this._click(key, { id: item.id });
			});
		}

		const click = (opts) => {
			if (this.state > VIEW_STATES.DISPLAYED) {
				return;
			}

			this._click(opts.key, {});
			shell.events.once('user_click', click, this);
		};
		shell.events.once('user_click', click, this);

		this.setPvpProgress(-1);
		requestAnimationFrame(() => {
			listenScrollEvents();
		});
	}

	/**
	 * show.
	 */
	show() {
		holdScrollEvents(false);
		this.draw();

		const shell = AppCore.instance.itd5ka.shell;
		shell.html.container_menu.classList.add('hidden');
		shell.html.container_pvp_span.classList.remove('hidden');

		shell.backgorundStyle('default');
	}

	/**
	 * Draws data into HTML
	 */
	draw() {
		let hasManager = false;
		for (const i in this.psources) {
			const ps = this.psources[i];
			const data = AppCore.instance.game.inventory.get(ps.id);

			generateFloorEffects(ps.html.decor_effects, data.sprite);
			ps.draw(data);
			ps.drawDecor(data);
			ps.html.container_controls.classList.remove('hidden');
			ps.html.container_info.classList.remove('hidden');
			ps.element.classList.remove('disabled');
			ps.element.classList.remove('unactivated');
			ps.print('label_name', data.name);

			if (data.employee) {
				hasManager = true;
			}
		}

		const header = document.querySelector('#psource.header_office');
		header.classList.add('hidden');
		const shell = AppCore.instance.itd5ka.shell;
		shell.html.button_pvp_start.classList[hasManager ? 'remove' : 'add']('disabled');

		this.roof = document.querySelector('#psource.roof_office');
		this.roof.classList.value = '';

		this.roof.classList.remove('unavailable');
		this.roof.classList.remove('hidden');

		this.roof.classList.add('roof_office', 'roof');
		this.roof.parentNode.appendChild(this.roof);
	}

	_draw_damage(dmg) {
		const firstManagerFloor = this._get_first_manager_floor();
		if (firstManagerFloor >= 0) {
			const rect = this.psources[firstManagerFloor].html.
				decor_employee.getBoundingClientRect();
			const offsetx = (Math.random() - 0.5) * (rect.width * 0.3);
			const offsety = (Math.random() - 0.5) * (rect.height * 0.3);
			const pos = { x: rect.left + offsetx, y: rect.top + rect.height / 2 + offsety };
			const efcts = AppCore.instance.itd5ka.shell.effects;
			efcts.spawnDamageNumbers(pos, dmg.dmg.toFixed(0), "employer");
		}
	}

	_get_first_manager_floor() {
		for (const i in this.psources) {
			const ps = this.psources[i];
			const data = AppCore.instance.game.inventory.get(ps.id);
			if (
				this.battle &&
				data.employee &&
				AppCore.instance.game.inventory.getItdItem(data.employee).property('health') > 0
			) {
				return i;
			}
		}
		return -1;
	}

	_draw_task_damage(args) {
		const dmg = args.dmg;
		const roomId = args.roomId;
		const dmg_type = args.dmg_type;
		const room = this.battle.roomsById[roomId];
		const firstManagerFloor = this._get_first_manager_floor();
		if (room.tasks[0] && firstManagerFloor >= 0) {
			const ps_battle = this.psources[firstManagerFloor].battle[room.tasks[0].id];
			if (ps_battle) {
				const rect =
					this.psources[firstManagerFloor].battle[room.tasks[0].id].
						entry.getBoundingClientRect();

				const offsetx = (Math.random() - 0.5) * (rect.width * 0.3);
				const offsety = (Math.random() - 0.5) * (rect.height * 0.3);
				AppCore.instance.itd5ka.shell.effects.spawnDamageNumbers(
					{ x: rect.left + offsetx, y: rect.top + rect.height / 2 + offsety },
					dmg.toFixed(0),
					dmg_type
				);
			}
		}
	}

	/**
	 * _loop. Application tick loop
	 *
	 * @param {number} dt delta time
	 * @param {number} dtf delta time factor (1 in perfect case)
	 * @param {number} timestamp .
	 */
	_loop(dt, dtf, timestamp) {
		const shell = AppCore.instance.itd5ka.shell;
		const battleid = 'pvp';

		let firstManagerFloor = -1;
		for (const i in this.psources) {
			const ps = this.psources[i];
			const data = AppCore.instance.game.inventory.get(ps.id);

			ps.drawLabels(data, this.battle);
			ps.drawBattle(data, this.battle);

			if (
				this.battle &&
				data.employee &&
				AppCore.instance.game.inventory.getItdItem(data.employee).property('health') > 0 &&
				firstManagerFloor === -1
			) {
				firstManagerFloor = i;
				this.psources[i].element.classList.add('currently_in_battle');
			} else if (this.battle && firstManagerFloor === -1) {
				this.psources[i].element.classList.add('defeated_in_battle');
			} else {
				this.psources[i].element.classList.remove('currently_in_battle');
				this.psources[i].element.classList.remove('defeated_in_battle');
			}
		}

		if (!this.battle || this.battle.finished) {
			return;
		}

		const glory = config('pvp_glory');
		const timeToDefeatTask =
			AppCore.instance.game.getConfig('game', 'pvp_time_to_task_defeat') * 1000 - glory * 250 / Math.max(1, this.pvpTasks.length);
		const timeToPvpDefeat = timeToDefeatTask * this.pvpTasks.length;
		if (this.pvpTimestamp === 0) {
			this.pvpTimestamp = timestamp;
			this.pvpStartTimestamp = timestamp;
		} else if (timestamp > this.pvpTimestamp + timeToDefeatTask) {
			this.pvpTimestamp = timestamp;
			this.setPvpProgress(this.currentPvpProgress + 1);
		}

		const time = new Date(Math.max(0, timeToPvpDefeat - (timestamp - this.pvpStartTimestamp)));
		shell.print(`${time.getMinutes()}:${time.getSeconds()}`, 'pvp_timer');

		if (this.pvpTasks.length && this.currentPvpProgress >= this.pvpTasks.length) {
			for (const i in this.psources) {
				const ps = this.psources[i];
				const data = AppCore.instance.game.inventory.get(ps.id);
				if (data.employee) {
					const empl = AppCore.instance.game.inventory.getItdItem(data.employee);
					empl.property('battle_endtime', Date.now());
				}
			}
			this.battle.events.emit('td_battle_finished', { win: false });
			AppCore.instance.events.off('td_battle_get_damage', this.get_damage);
			AppCore.instance.events.off('td_battle_task_damage', this.task_damage);
		}

		// --- activate shield badge
		if (this.battle && this.battle.cache.coffeeUseTimestamp > 0 && firstManagerFloor >= 0) {
			const duration = AppCore.instance.game.getConfig('game', 'coffee_effect_duration') * 1000;
			const shielded = this.battle.cache.coffeeUseTimestamp + duration > Date.now();
			const el = this.psources[firstManagerFloor].html.decor_employee;

			if (shielded && !el.classList.contains('shielded')) {
				AppCore.instance.sounds.play('coffee_on', 1, 0.5);
				// sound('coffee_on', 1, 0.5);
			} else if (!shielded && el.classList.contains('shielded')) {
				AppCore.instance.sounds.play('coffee_off');
				// sound('coffee_off');
			}

			this.psources[firstManagerFloor].html.decor_employee.classList[shielded ? 'add' : 'remove'](
				'shielded'
			);
		}

		// === manage coffee button
		// --- hide coffee button
		if (
			this.cache.currentlyInBattleFloor >= 0 &&
			(!this.battle || firstManagerFloor !== this.cache.currentlyInBattleFloor)
		) {
			this.psources[this.cache.currentlyInBattleFloor].element.classList.remove(
				'currently_in_battle'
			);
			this.cache.currentlyInBattleFloor = -1;
		}

		if (this.battle) {
			let cost = AppCore.instance.game.actions.conf('use_coffee_cost', { battleid });
			// -- show coffee button
			if (firstManagerFloor >= 0) {
				this.cache.currentlyInBattleFloor = firstManagerFloor;
				this.psources[this.cache.currentlyInBattleFloor].element.classList.add('currently_in_battle');
				this.psources[this.cache.currentlyInBattleFloor].html.button_coffee.dataset.cost =
					'' + AppCore.instance.game.actions.conf('use_coffee_cost', { battleid });
			}
	
			let cib;
			if (this.cache.currentlyInBattleFloor >= 0) cib = this.psources[this.cache.currentlyInBattleFloor].element.classList.contains(
				'currently_in_battle');
			else cib = false;
			if (
				this.cache.currentlyInBattleFloor >= 0 &&
				cib &&
				firstManagerFloor >= 0
			) {
				const active =
					!this.psources[firstManagerFloor].html.decor_employee.classList.contains('shielded') &&
					AppCore.instance.game.actions.testSpend('coffee', cost)
				const coffeebtn = this.psources[this.cache.currentlyInBattleFloor].html.button_coffee;
				const coffee = AppCore.instance.game.inventory.get('coffee').amount;
				coffeebtn.classList[(active && coffee > 0) ? 'remove' : 'add']('disabled');
			}
	
			// === scroll to battle
			if (firstManagerFloor >= 0 && this.cache.autoscrollPos !== firstManagerFloor) {
				this.autoscrollToFloor(firstManagerFloor);
			}
		}
	}

	autoscrollToFloor(index) {
		this.cache.autoscrollPos = index;
		const height = this.psources[index].element.clientHeight;
		const pixelpos = (this.psources.length - index - 1) * height;
		autoScrollTo(-pixelpos + height / 2);
	}

	startBattle() {
		this.cache.autoscrollPos = -1;
		const shell = AppCore.instance.itd5ka.shell;
		const container = shell.html.container_pvp_span;
		container.classList.value = '';
		container.classList.add('state-1');

		// ---

		const rooms = [];

		for (const i in this.psources) {
			const ps = this.psources[i];
			const data = AppCore.instance.game.inventory.get(ps.id);
			rooms.push(data);
		}

		this.battle = new ItdBattle(AppCore.instance.game);
		this.battle.init(rooms, 1, 1, 'sprints_pvp_pool');
		AppCore.instance.game.battles['pvp'] = this.battle;

		AppCore.instance.sounds.playTheme('theme-battle');
		this.battle.events.once('td_battle_finished', ({ win }) => {
			delete AppCore.instance.game.battles['pvp'];
			AppCore.instance.logOnServer(win? "user_won_pvp" : "user_lose_pvp");
			action('register_completed_pvp', { win });
			AppCore.instance.views.push(new PvpResultsView(win, 'pvp_reward_00'));
			AppCore.instance.itd5ka.shell.events.once('popup_hidden', () => {
				AppCore.instance.views.pop();
			});

			container.classList.value = '';
			container.classList.add(`state-${win ? 'win' : 'lose'}`);
			AppCore.instance.sounds.playTheme('theme-main');
		});

		this.populateTasks();
		this.pvpTimestamp = 0;
		this.pvpStartTimestamp = 0;

		this.get_damage = AppCore.instance.events.on('td_battle_get_damage', this._draw_damage, this).func;
		AppCore.instance.events.on('td_battle_finished', ({ }) => {
			delete AppCore.instance.game.battles['pvp'];
		});
		this.task_damage = AppCore.instance.events.on('td_battle_task_damage', this._draw_task_damage, this).func;
	}

	populateTasks() {
		const shell = AppCore.instance.itd5ka.shell;
		const container = shell.html.container_pvp_span.querySelector('icons.container');
		const pools = this.battle.tasksPools;
		const pool = [];
		for (const k in pools) {
			for (const i in pools[k].pool) {
				pool.push(pools[k].pool[i]);
			}
		}

		const len = pool.length;
		for (let i = 0; i < pool.length; i++) {
			const el = document.createElement('icon');
			el.id = 'task';
			container.appendChild(el);
			el.style.setProperty('--progress', `${((i + 1) / len) * 100}%`);
			this.pvpTasks.push({
				element: el,
				data: pool[i]
			});
		}
	}

	setPvpProgress(index) {
		this.currentPvpProgress = index;

		const shell = AppCore.instance.itd5ka.shell;
		const employee = shell.html.container_pvp_span.querySelector('icon#employee');
		const progressbar = shell.html.container_pvp_span.querySelector('progressbar');

		if (index < 0) {
			employee.style.setProperty('--progress', '0%');
			progressbar.style.setProperty('--progress', '0%');
		} else {
			const len = this.pvpTasks.length;
			const percenage = ((index + 1) / len) * 100;
			employee.style.setProperty('--progress', `${Math.min(100, percenage - 10)}%`);
			progressbar.style.setProperty('--progress', `${Math.min(100, percenage)}%`);
		}

		const task = this.pvpTasks[index - 1];
		if (task) {
			task.element.classList.add('defeated');
		}
	}

	/**
	 * hide.
	 */
	hide() {
		holdScrollEvents(true);
	}

	dispose() {
		unlistenScrollEvents();
		AppCore.instance.events.off('td_battle_get_damage', this.get_damage);
		AppCore.instance.events.off('td_battle_task_damage', this.task_damage);
		const shell = AppCore.instance.itd5ka.shell;
		shell.html.container_menu.classList.remove('hidden');
		shell.html.container_pvp_span.classList.add('hidden');

		for (const i in this.psources) {
			this.psources[i].dispose();
		}
		
		if (this.roof) {
			this.roof.classList.add('unavailable');
			this.roof.classList.add('hidden');
		}

		for (const i in this.pvpTasks) {
			const prop = this.pvpTasks[i];
			prop.element.parentNode.removeChild(prop.element);
		}

		this.pvpTasks.length = 0;
	}
}
