import Events from '../core/events.js';
import AppCore from '../game/AppCore.js';

const params = new Proxy(new URL(window.location.href.replace(/#/g, '?')).searchParams, {
	get: (searchParams, prop) => searchParams.get(prop)
});

/**
 * Wrapper for vk api
 */
export default class Vk {
  /**
   * .
   */
  constructor() {
    this.events = Events;
    this.token = null;
    this.user_id = null;
    this.search_string = null;
    this.platform = null;
  }

  init() {
    const mode = this.available;

    switch (mode) {
      case Vk.AUTH_MODE.OAUTH: {
        console.log(`Vk launch params`);
        console.log(window.location.href.replace(/#/g, '?'));
        this.token = params.access_token;
        this.user_id = params.vk_user_id;
        this.platform = params.vk_platform;
			  let build = document.querySelector('div#meta_info');
        build.getElementsByTagName("a")[0].innerHTML += '-' + params.vk_platform;
        build.getElementsByTagName("a")[1].innerHTML = 'uid: ' + this.user_id;
        AppCore.instance.itd5ka.shell.set_platform_scale();
        this.search_string = window.location.search.slice(1);
        this.events.emit('vk_authorized');
        break;
      }
    }

    return this;
  }

  /**
   * @returns {Vk.AUTH_MODE} .
   */
  get available() {
    if (params.vk_user_id) {
      return Vk.AUTH_MODE.OAUTH;
    }
    return Vk.AUTH_MODE.UNAVAILABLE;
  }

  /**
   * @returns {Vk.AUTH_MODE} .
   */
  get is_iphone() {
    return this.platform == Vk.PLATFORM.IPHONE_MOBILE || this.platform == Vk.PLATFORM.IPHONE_EXTERNAL;
  }

	/**
	 * @returns {Vk} instance
	 */
	static get instance() {
		if (!Vk._instance) {
			Vk._instance = new Vk();
		}

		return Vk._instance;
	}
}

Vk.AUTH_MODE = {
	UNAVAILABLE: 0,
	EXTENSION: 1,
	OAUTH: 2
};

Vk.PLATFORM = {
  IPHONE_MOBILE:  "mobile_iphone",
  IPHONE_EXTERNAL:  "iphone_external"
};
