import Vk from '../thirdparty/Vk.js';
import AppCore from './AppCore.js';
import Devpanel from './Devpanel.js';
import { delayedAction } from '../game/dust/ui_utils.js';
import TutorialView from './views/TutorialView.js';
import bridge from '@vkontakte/vk-bridge';
import settings from './settings.js';

window.globalsettings = {
	// --- Флаг переключения в режим разработчика
	devmode: false,
	// --- Флаг отображения отладочной информации
	debugmode: false
};

function get_brouser() {
	var userAgent = navigator.userAgent.toLowerCase();
	let brouser = 'Not supported';
	let version = "Unknown";
	if (/firefox/.test(userAgent)) {
		brouser = 'Firefox';
		version = userAgent.split("firefox/")[1];
	} else if (/chrome/.test(userAgent)) {
		brouser = 'Chrome';
		version = (userAgent.split("chrome/")[1]).split(" ")[0];
	} else if (/safari/.test(userAgent)) {
		brouser = 'Safari';
		const v = userAgent.split("Version/");
		if (v[1]) version = (v[1]).split(" ")[0];
		else version = (v[0]).split(" ")[0];
	} else if (/opera/.test(userAgent)) {
		brouser = 'Opera';
		version =  userAgent.split("Version/")[1];
	} else if (/mozilla/.test(userAgent)) {
		if (/msie/.test(userAgent)) {
			brouser = 'InternetExplorer';
			version = (userAgent.split("MSIE ")[1]).split(";")[0];
		} else if (/rv:/.test(userAgent)) {
			brouser = 'InternetExplorer';
			version = '11.0';
		} else if (/edg/.test(userAgent)) {
			brouser = 'Edge';
			version = (userAgent.split("Edg/")[1]);
		}
	}
	console.log('Find brouser: ' + brouser + '/' + version)

	return { brouser: brouser, version: version };
}

/**
 * Starts application
 *
 * @param {string?} userid user id to load save
 * @async
 * @returns {Promise} Promise
 */
function run(userid = null) {
	return app
		.load(userid)
		.catch((err) => {
			console.error(err);
		})
		.then(() => {
			let br = get_brouser();
			let ver = br.version.split('.');
			if (br.brouser == 'Safari' && Number(ver[0]) <= 15 && Number(ver[1]) < 4) {
				// for testing in firefox
				// if (br.brouser == 'Firefox' && Number(ver[0]) <= 110 && Number(ver[1]) < 4) {
				throw new Error('Обновите браузер до последней версии!')
			}
			app.run();
			app.pushNewRootView();
			if (settings.DEVPANEL) app.devpanel = new Devpanel().init();
			app.logOnServer("game_started");
			/*Vk.instance.init();
			AppCore.instance.vk_auth().then((response) => {
				console.log("AUTH RESPONSE: ");
				console.log(response);
			});*/
		})
		.then(() => {
			// wait fonts
			if (typeof FontFaceSet !== 'undefined') {
				return document.fonts.ready;
			}

			return true;
		})
		.then(() => {
			document.querySelector('itd5ka#splashscreen').classList.add('hidden');
			document.querySelector('div#videopanel').classList.add('hidden');
			document.querySelector('div#syserrorpanel').classList.add('hidden');
			
			if (AppCore.instance.new_user) {
				AppCore.instance.logOnServer("game_start_popup");
			}

			if (AppCore.instance.new_user || TutorialView.isTutorialInProgress()) {
				AppCore.instance.views.push(new TutorialView());
			}

			AppCore.instance.subscribeNotifications();

			const mobile_message = 'Лучше всего играть<br/>на мобильном телефоне!';
			if (!AppCore.instance.is_mobile()) AppCore.instance.showSysError(mobile_message);
		})
		.catch((err) => {
			const el = document.querySelector('#errorlog');
			if (err.message.includes('Обновите браузер')) {
				AppCore.instance.showError('\nВнимание! \n' + err.message + '\n', null, -1);
			} else {
				AppCore.instance.showError('Loading error \n' + err.message + '\n', err.stack, -2);
			}
		});
}

/**
 * main. App entry point
 */
export function runApp() {
	const portrait_message = 'Чтобы игра работала правильно,<br/>переверни устройство в портретный режим!';
	window.addEventListener("resize", () => {
		console.warn(`(resize) window resized ${window.innerWidth} ${window.innerHeight}`);
		if (window.innerWidth > window.innerHeight) {
			AppCore.instance.showSysError(portrait_message);
		} else {
			AppCore.instance.hideSysError();
		}
	});
	document.addEventListener('visibilitychange', () => {
		if (document.hidden) { 
			AppCore.instance.events.emit('td_battle_finished', { win: false });
			AppCore.instance.sounds.onWindowBlur();
			//AppCore.instance.inputs.unAssignTouchListeners();
		} else {
			setTimeout(()=> {
				AppCore.instance.sounds.onWindowFocus();
				AppCore.instance.resetRootView();
				//AppCore.instance.inputs.assignTouchListeners();
			}, 500);
		}
	});
	bridge.send('VKWebAppInit')
	.then((data) => {
		if (data.result) {
			console.log("VK Init");
			
			bridge.subscribe((e) => {
				if (!e.detail) {
					return;
				}
				if (e.detail.type === 'VKWebAppUpdateConfig') {
				  	console.log("VK Config updated");
				  	console.log(e.detail.data);
					if (e.detail.data.viewport_width > e.detail.data.viewport_height) {
						console.warn('(vk) Rotate device to portrait mode!');
						AppCore.instance.showSysError(portrait_message);
					} else {
						AppCore.instance.hideSysError();
					}
				} else if (e.detail.type === 'VKWebAppViewHide') {
					/*AppCore.instance.events.emit('td_battle_finished', { win: false });
					AppCore.instance.sounds.onWindowBlur();
					AppCore.instance.inputs.unAssignTouchListeners();*/
				} else if (e.detail.type === 'VKWebAppViewRestore') {
					/*setTimeout(()=> {
						AppCore.instance.sounds.onWindowFocus();
						AppCore.instance.resetRootView();
						AppCore.instance.inputs.assignTouchListeners();
					}, 500);*/
				}
			  });

			bridge.send('VKWebAppGetConfig')
				.then((data) => {
					console.log("VK Get Config");
					console.log(data);
					if (data.viewport_width > data.viewport_height) {
						console.warn('(vk) Rotate device to portrait mode!');
						AppCore.instance.showSysError(portrait_message);
					} else {
						AppCore.instance.hideSysError();
					}
				})
				.catch((error) => {
					console.error("VK Error");
					AppCore.instance.showError('General application error \n' + 'Vk get config error' + '\n', null, -3);
				});
		} else {
			console.error("VK Error");
			AppCore.instance.showError('General application error \n' + 'Vk init error' + '\n', null, -3);
		}
	})
	.catch((err) => {
		// Ошибка
		console.error(err);
		AppCore.instance.showError('General application error \n' + err.message + '\n', err.stack, -3);
	});
	
	const app = AppCore.instance.init();
	window.app = app;
	window.onerror = function(event, source, lineno, colno, error) {
		let errorMessage = '';
		if (event) errorMessage += event + '\n';
		if (source) errorMessage += `source: ${source} + \n`;
		if (lineno) errorMessage += `lineno: ${lineno} + \n`;
		if (colno) errorMessage += `colno: ${colno} + \n`;
		if (error) errorMessage += `error: ${error}`;
		AppCore.instance.logOnServer("error", { errorMessage: errorMessage });
		return true;
	};

	if (Vk.instance.available) {
		Vk.instance.init();
		AppCore.instance.vk_auth()
			.then((response) => {
				console.log("Vk auth response: ");
				console.log(response);
				run(Vk.instance.user_id);
			})
			.catch((err) => {
				console.error('Vk auth error:', err);
				AppCore.instance.showError('General application error', err.error, -3);
			});
	} else {
		AppCore.instance.standalone_auth()
			.then((response) => {
				run(response.data.id);
			})
			.catch((err) => {
				// Ошибка
				console.error(err);
				AppCore.instance.showError('Auth error', err.error, -3);
			});;
	}
}
