import { runApp } from './game/index.js';

/**
 *  Runs application
 */
function main() {
	runApp();
}


//main();

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', main);
} else {
	main();
}