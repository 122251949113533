/**
 * @File PvpPrepareView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import AppCore from '../AppCore.js';
import ViewUiBase from './ViewUiBase.js';
import { ViewBase, VIEW_STATES } from '../../core/views.js';
import PvpView from './PvpView.js';
import HintView from './HintView.js';
import { config } from '../shorthands.js';
import { setHeaderTitle } from '../shorthands.js';
import logger from '../../core/logger.js';

/**
 * Game root view
 */
export default class PvpPrepareView extends ViewUiBase {
	/**
	 * @param {string} key click element key
	 * @param {oject} args click arguments
	 */
	_click(e) {
		switch (e.target.id) {
			case 'start_btn':
				this.startSearch();
				break;
			case 'hint':
				AppCore.instance.views.push(new HintView('hint_pvp'));
				break;
			case 'close_popup':
				if ((AppCore.instance.views.top() instanceof PvpPrepareView)) {
					e.stopPropagation();
					AppCore.instance.restoreRootView();
				}
				break;
			default:
				break;
		}
	}

	/**
	 * show.
	 */
	show() {
		const shell = AppCore.instance.itd5ka.shell;
		this.container = shell.popup('itd_pvp_prepare');
		this.container.classList.remove('searching');
		shell.backgorundStyle('default');

		this.container.querySelector('label#player_rating').innerHTML = `Ваша репутация: ${config(
			'pvp_glory'
		)}`;

		const rankel = this.container.querySelector('icon#player_rank');
		rankel.classList.value = `textures-rank-${config('pvp_rank')}`;

		setHeaderTitle('Челленджи');

		let haveOneManager = false;
		const inventory = AppCore.instance.game.getData('inventory').content;
		for (const k in inventory) {
			const item = inventory[k];

			if (item.type === 'employee' && item.active) {
				haveOneManager = true;
				break;
			}
		}

		this.container.querySelector('label#pvp_start_warn').classList[haveOneManager ? 'add' : 'remove']('hidden');
		this.container.querySelector('button#start_btn').classList[haveOneManager ? 'remove' : 'add']('disabled');
	}

	/**
	 *
	 */
	startSearch() {
		this.container.classList.add('searching');

		const delay = 1000 + Math.random() * 6000;
		let started = false;
		//const delay = 1;

		AppCore.instance
			.getPvp()
			.then((response) => {
				if (response.content) {
					logger.group('CORE_STATUS', `Received pvp opponent data from server`, response.content);
					/*
						content: {
							user_id,
							userdata,
							savedata
						}
					*/
					AppCore.instance.ticker.timeout(() => {
						started = true;
						if (this.state !== VIEW_STATES.DISPLAYED) {
							return;
						}

						AppCore.instance.views.pop();
						AppCore.instance.views.push(new PvpView());
					}, delay);
				}
			});

		// sound
		//AppCore.instance.sounds.theme.fade(1, 0.3, 1000);
		const playSearchSound = () => {
			if (!started) {
				AppCore.instance.sounds.play('success', 0.5);
				this.searchSoundTimeoutId = AppCore.instance.ticker.timeout(playSearchSound, 1000);
			} else {
				//AppCore.instance.sounds.theme.fade(0.3, 1, 1000);
			}
		};

		playSearchSound();
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.ticker.timeoutStop(this.searchSoundTimeoutId);
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
