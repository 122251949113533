/**
 * @constructor
 * @param {HTMLElement} element root element
 */
export default function Itd5kaJournalEntry(element) {
	this.el = element;
	this.sprite = this.el.querySelector('icon#jentry');
	this.label = this.el.querySelector('label#jentry');
	this.description = this.el.querySelector('label#description');
	this.statusIcon = this.el.querySelector('icon#jentry_status');

	this.dispose = () => {
		this.el.parentNode.removeChild(this.el);
	};

	this.setIcon = (sprite) => {
		this.sprite.classList.value = '';
		this.sprite.classList.add('textures-' + sprite);
	};

	this.setLabel = (text) => {
		this.label.innerHTML = text;
	};

	this.setDescription = (text) => {
		this.description.innerHTML = text;
	};

	const statuses = ['completed', 'collected'];
	/**
	 * @param {number} status 1 for 'completed' or 2 for 'collected'
	 * @returns {boolean} true is status changed
	 */
	this.setStatus = (status) => {
		const toggled = this.el.classList.contains(statuses[status - 1]);

		if (!toggled) {
			this.el.classList.remove(...statuses);
			if (status > 0) {
				this.el.classList.add(statuses[status - 1]);
			}
		}

		return toggled;
	};

	this.visible = (status) => {
		const wasVisible = !this.el.classList.contains('hidden');
		if (wasVisible !== status) this.el.classList[status ? 'remove' : 'add']('hidden');
		return wasVisible !== status;
	};
}
