/**
 * @File ItdBattle.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ItemCard from '../generic/ItemCard.js';
import { constructStandardRawData as csrd } from '../../core/standart_data_helpers.js';
import Events from '../../core/events.js';
import Decimal from 'decimal.js';
import math from '../../core/mathjs';
import AppCore from '../AppCore.js';

/**
 * Manages battle flow
 *
 * Нулевой уровень абстрации. Фичи добавлены строго по ГДД: в целом класс расширяем, но сильно ворошить его не стоит.
 */
class ItdBattle {
	/**
	 * @param {GameCore} core game core pointer
	 */
	constructor(core) {
		this.finished = false;
		this.win = false;

		this.core = core;

		this.guids = 0;

		this.rooms = [];
		this.roomsById = {};
		this.tasksById = {};
		this.gadgetsData = {};
		this.stats = {
			tasksLeft: 0
		};
		this.events = Events;
		this.cache = {
			stepTimestamp: 0,
			coffeeUseTimestamp: -1,
			startTimestamp: 0,
			finishTimestamp: 0
		};
		this.coffeeUsed = 1;
		this.battleFinishTimeoutId = null;
	}

	/**
	 * @param {Array<object>} rooms .
	 * @param {number} audits audits to start per once
	 * @param {number} auditLevel selected audit level
	 * @param {string} [pool='sprints_pool'] pool to generate tasks from
	 */
	init(rooms, audits, auditLevel, pool = 'sprints_pool') {
		console.log(`battle_start ${pool}`);
		AppCore.instance.logOnServer("battle_start", { pool: pool });
		this.audits = audits;
		this.auditLevel = auditLevel;

		for (const i in rooms) {
			this.addRoom(rooms[i]);
		}

		this.tasksPools = {};
		const globalpool = this.core.inventory.get(pool).list;
		for (let i = 0; i < audits; i++) {
			const sprintPoolId = globalpool[Math.min(globalpool.length - 1, auditLevel + i)];
			const sprintPool = this.core.inventory.get(sprintPoolId).list;
			this.tasksPools['p' + this.guids++] = {
				pool: [...sprintPool],
				level: auditLevel + i + 1
			};

			this.stats.tasksLeft += sprintPool.length;
		}

		/*
		logger.group(
			'GAMEPLAY_ACTION',
			`Battle: Start (${audits}) new audits from level ${auditLevel}`
		);
		*/

		this.cache.startTimestamp = Date.now();
		this.cache.stepTimestamp = AppCore.instance.timestamp;

		this.events.once('td_battle_finished', ({ win }) => {
			this.finished = true;
			this.win = win;
			this.cache.finishTimestamp = Date.now();
			AppCore.instance.logOnServer(`battle_${win ? 'won' : 'lose'}`);
			console.log(`battle_${win ? 'won' : 'lose'}`);
		});
		for (let i = 0; i < this.rooms.length; i++) {
			const room = this.rooms[i];
			const employee = this.core.inventory.getItdItem(room.data.employee);
			if (employee) {
				employee.raw.content.attackTimespamp = this.cache.stepTimestamp;
			}
		}
	}

	/* eslint-disable max-lines-per-function, max-statements, complexity */
	/**
	 * Battle step calculation
	 *
	 * @param {number} timestamp .
	 */
	step(timestamp) {
		this.cache.stepTimestamp = timestamp;

		this.spawnNewTasks();

		for (let i = 0; i < this.rooms.length; i++) {
			let room = this.rooms[i];
			let employee = this.core.inventory.getItdItem(room.data.employee);

			if (!room.tasks.length) {
				continue;
			}

			if (!room.stats.battleStartTimestamp) {
				room.stats.battleStartTimestamp = timestamp;
			}

			// 1. Sort by audit level
			/*
			room.tasks.sort((a, b) => {
				return a.raw.content.level - b.raw.content.level;
			});
			*/

			// 2. Move tasks on next floor
			if (!employee || employee.property('health') <= 0) {
				if (!this.rooms[i + 1]) {
					this.events.emit('td_battle_finished', { win: false });
					if (employee) {
						employee.property('battle_endtime', Date.now());
					}
					return;
				}
				let new_employe;
				let ii = i;
				for (ii = i + 1; ii < this.rooms.length; ii++) {
					new_employe = this.core.inventory.getItdItem(this.rooms[ii].data.employee);
					if (new_employe) break;
				}
				if (!new_employe) {
					this.events.emit('td_battle_finished', { win: false });
					if (employee) {
						employee.property('battle_endtime', Date.now());
					}
					return;
				}

				this._removeDeadTasks(room);

				const croom = this.rooms[ii];
				while (room.tasks.length) {
					const task = room.tasks.pop();
					//delete this.rooms[task.raw.content.room].tasksBySprintId[task.raw.content.sprintId];
					if (task.property('health') > 0) {
						task.raw.content.room = ii;
						croom.tasks.push(task);
						task.raw.content.roomId = croom.id;
					}
				}
				croom.tasks.sort((a, b) => {
					return a.id - b.id;
				});
				employee = new_employe;
				room = croom;
			}

			// 3. Calc damage
			if (employee && employee.property('health') > 0) {
				const duration = this.core.getConfig('game', 'coffee_effect_duration') * 1000;
				const shielded = this.cache.coffeeUseTimestamp + duration > Date.now();
				if (!room.effects.freeze && !shielded) {
					for (let ii = 0; ii < 3 && ii < room.tasks.length; ii++) {
						const dmg = this._calcDmg(
							room.tasks[ii],
							employee,
							room.effects.slowdown ? room.effects.slowdown.effectScale : 1
						);
						if (dmg > 0) {
							this.events.emit('td_battle_get_damage', { dmg: dmg });
						}
						room.stats.enemiesTotalDamage += dmg;
						room.stats.enemiesHitsMade += 1;
					}
				}

				const dmg = this._calcDmg(
					employee,
					room.tasks[0],
					1 / (room.effects.boost ? room.effects.boost.effectScale : 1)
				);
				if (dmg > 0) {
					this.events.emit('td_battle_task_damage', { dmg: dmg, roomId: room.id, dmg_type: 'task' });
				}
				room.stats.employeeTotalDamage += dmg;
				room.stats.employeeHitsMade += 1;
			} else if (employee) {
				this.events.emit('td_battle_finished', { win: false });
				employee.property('battle_endtime', Date.now());
			}

			// 4.
			this._applyGadgetEffects(room);
			this._applyRoomEffects(room, timestamp);

			// 5. Remove dead tasks
			this._removeDeadTasks(room);
		}

		if (this.stats.tasksLeft <= 0 && this.battleFinishTimeoutId === null) {
			this.battleFinishTimeoutId = AppCore.instance.ticker.timeout(() => 
			{
				for (let i = 0; i < this.rooms.length; i++) {
					const room = this.rooms[i];
					const employee = this.core.inventory.getItdItem(room.data.employee);
					if (employee) {
						employee.property('battle_endtime', Date.now());
					}
				}
				this.events.emit('td_battle_finished', { win: true });
				this.battleFinishTimeoutId = null;
			}, 500);
		}
	}

	_removeDeadTasks(room) {
		for (let ii = 0; ii < room.tasks.length; ii++) {
			if (room.tasks[ii]) {
				const task = room.tasks[ii];
				if (task.raw.content.killTimerId === null && task.property('health') <= 0) {
					task.raw.content.killTimerId = AppCore.instance.ticker.timeout(() => 
					{
						//delete room.tasksBySprintId[task.raw.content.sprintId];
						delete this.tasksById[task.id];
						room.tasks.splice(ii, 1);
					}, 300);
					room.stats.enemiesKilled += 1;
					this.stats.tasksLeft -= 1;
				}
			}
		}
	}

	/**
	 * @param {ItemCard} by .
	 * @param {ItemCard} to .
	 * @param {number} rateScale .
	 * @returns {number} damage made
	 */
	_calcDmg(by, to, rateScale) {
		const timedelta = this.cache.stepTimestamp - by.raw.content.attackTimespamp;
		if (timedelta < by.property('attackRate') * rateScale) {
			return 0;
		}
		const dmg = by.property('attackDamage');
		const injuries = Math.min(to.property('healthMax'), to.property('injuries') + dmg);
		to.property('injuries', Math.round(injuries));

		by.raw.content.attackTimespamp = this.cache.stepTimestamp;

		return dmg;
	}

	/**
	 * @param {object} room .
	 * @param {number} timestamp .
	 */
	_applyRoomEffects(room, timestamp) {
		for (const k in room.effects) {
			const effect = room.effects[k];

			if (timestamp >= effect.endTime) {
				delete room.effects.poison;
			}
		}

		if (room.effects.poison) {
			const effect = room.effects.poison;

			const timedelta = timestamp - effect.attackTimespamp;
			if (timedelta > effect.attackRate) {
				effect.attackTimespamp = timestamp;
				for (const ii in room.tasks) {
					const unit = room.tasks[ii];
					const dmg = effect.attackDamage;
					const injuries = Math.min(unit.property('healthMax'), unit.property('injuries') + dmg);
					unit.property('injuries', injuries);
				}
			}
		}
	}

	/**
	 * @param {object} room .
	 */
	_applyGadgetEffects(room) {
		const gadget = this.core.inventory.getItdItem(room.data.gadget);

		if (!gadget || gadget.property('charges') < 1) {
			return;
		}

		let gadgetData = this.gadgetsData[gadget.id];

		// --- init triggers binds
		if (!gadgetData) {
			gadgetData = this.gadgetsData[gadget.id] = {
				trigger: math.compile(gadget.property('triggers')),
				stats: {}
			};

			Object.defineProperty(gadgetData.stats, 'employeeHealth', {
				get: () => {
					const employee = this.core.inventory.getItdItem(room.data.employee);
					if (!employee) {
						return 0;
					}

					const value = employee.property('health') / employee.property('healthMax');

					return Number(value.toFixed(2));
				}
			});

			Object.defineProperty(gadgetData.stats, 'enemiesTotalDamage', {
				get() {
					return room.stats.enemiesTotalDamage;
				}
			});

			Object.defineProperty(gadgetData.stats, 'employeeTotalDamage', {
				get() {
					return room.stats.employeeTotalDamage;
				}
			});

			Object.defineProperty(gadgetData.stats, 'enemiesKilled', {
				get() {
					return room.stats.enemiesKilled;
				}
			});

			Object.defineProperty(gadgetData.stats, 'enemiesHitsMade', {
				get() {
					return room.stats.enemiesHitsMade;
				}
			});

			Object.defineProperty(gadgetData.stats, 'employeeHitsMade', {
				get() {
					return room.stats.employeeHitsMade;
				}
			});

			Object.defineProperty(gadgetData.stats, 'enemiesLeftTotal', {
				get: () => {
					return this.stats.tasksLeft;
				}
			});

			Object.defineProperty(gadgetData.stats, 'battleDuration', {
				get: () => {
					if (!room.stats.battleStartTimestamp) {
						return 0;
					}

					const duration = (this.cache.stepTimestamp - room.stats.battleStartTimestamp) / 1000;

					return duration;
				}
			});
		}

		if (gadgetData.trigger.evaluate({ here: gadgetData.stats })) {
			// ... trigger effect
			switch (gadget.property('effectType')) {
				case 'heal': {
					const employee = this.core.inventory.getItdItem(room.data.employee);
					if (!employee) {
						return;
					}

					const heal = gadget.property('effectValue');
					employee.property('injuries', Math.max(0, employee.property('injuries') - heal));
					break;
				}
				case 'attack': {
					for (const ii in room.tasks) {
						const unit = room.tasks[ii];
						const dmg = gadget.property('effectValue');
						const injuries = Math.min(unit.property('healthMax'), unit.property('injuries') + dmg);
						unit.property('injuries', injuries);
					}
					break;
				}
				case 'poison': {
					const args = gadget.property('effectValue');
					const attackDamage = args[0];
					const attackRate = args[1] * 1000;
					const effectDuration = args[2] * 1000;
					room.effects.poison = {
						attackDamage,
						attackRate,
						effectDuration,
						endTime: this.cache.stepTimestamp + effectDuration,
						attackTimespamp: this.cache.stepTimestamp
					};

					break;
				}
				case 'boost': {
					const args = gadget.property('effectValue');
					const effectScale = args[0];
					const effectDuration = args[1] * 1000;
					room.effects.boost = {
						effectScale,
						effectDuration,
						endTime: this.cache.stepTimestamp + effectDuration
					};

					break;
				}
				case 'slowdown': {
					const args = gadget.property('effectValue');
					const effectScale = args[0];
					const effectDuration = args[1] * 1000;
					room.effects.slowdown = {
						effectScale,
						effectDuration,
						endTime: this.cache.stepTimestamp + effectDuration
					};

					break;
				}
				case 'freeze': {
					const effectDuration = gadget.property('effectValue');
					room.effects.slowdown = {
						effectDuration,
						endTime: this.cache.stepTimestamp + effectDuration
					};

					break;
				}
				default:
					break;
			}

			this.zeroRoomStats(room);
			gadget.property('chargesUsed', gadget.property('chargesUsed') + 1);
		}
	}
	/* eslint-enable max-lines-per-function, max-statements, complexity */

	/**
	 * Registers battle room
	 *
	 * @param {object} data .
	 */
	addRoom(data) {
		const room = {
			data,
			id: data.id,
			tasks: [],
			//tasksBySprintId: {},
			stats: {
				battleStartTimestamp: 0
			},
			effects: {}
		};
		this.zeroRoomStats(room);
		this.rooms.push(room);
		this.roomsById[data.id] = room;
	}

	/**
	 * Sets room stats to zero
	 *
	 * @param {object} room .
	 */
	zeroRoomStats(room) {
		room.stats.enemiesTotalDamage = 0;
		room.stats.enemiesHitsMade = 0;
		room.stats.employeeTotalDamage = 0;
		room.stats.employeeHitsMade = 0;
		room.stats.enemiesKilled = 0;
	}

	/**
	 * makeDamage.
	 *
	 * @param {string} roomid .
	 * @param {number} damage
	 */
	applyDamage(roomid, damage) {
		const task = this.roomsById[roomid].tasks[0];
		if (!task) {
			return;
		}

		const injuries = Math.min(
			task.property('healthMax'),
			task.property('injuries') + Math.round(damage * 100) / 100
		);
		task.property('injuries', Math.round(injuries));
	}

	/**
	 * Spawn tasks if allowed
	 */
	spawnNewTasks() {
		// issue #206 b.
		if (this.rooms[0].tasks.length > 2) {
			return;
		}
		for (const k in this.tasksPools) {
			// issue #206. Спринты вываливаются сразу все, до трех штук разом
			// a.
			/*
			if (this.rooms[0].tasksBySprintId[k]) {
				continue;
			}
			*/

			const itemid = this.tasksPools[k].pool.shift();
			const conf = this.core.raw.content.config.content;
			const item = conf.tasks[itemid];

			if (!item) {
				continue;
			}

			const id = this.guids++;
			const raw = {
				healthMax: item.healthMax,
				health: '@fn: max(0, healthMax - injuries)',
				injuries: new Decimal(0),
				attackDamage: item.attackDamage,
				attackRate: item.attackRate,
				id,
				room: 0,
				roomId: this.rooms[0].id,
				sprintId: k,
				level: this.tasksPools[k].level,
				attackTimespamp: this.cache.stepTimestamp,
				itemid,
				icon: item.icon,
				health_progress: -1,
				killTimerId: null
			};
			const task = new ItemCard(csrd(id, raw));
			this.tasksById[id] = task;
			this.rooms[0].tasks.push(task);
			//this.rooms[0].tasksBySprintId[k] = task;
		}
	}
}

export default ItdBattle;
