/**
 * @File BattlePrepareView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import ItdBattle from '../tovdef/ItdBattle.js';
import { config } from '../shorthands.js';
import HintView from './HintView.js';
import { VIEW_STATES } from '../../core/views.js';

/**
 * Game root view
 */
export default class BattlePrepareView extends ViewUiBase {
	/**
	 * @param {number} stage stage index
	 */
	constructor(stage) {
		super();

		this.stage = stage;
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e .
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				if (this.state == VIEW_STATES.DISPLAYED) {
					e.stopPropagation();
					AppCore.instance.views.pop();
				}
				break;
			case 'start_btn':
				startBattle(this.stage, this.auditsSelected);
				AppCore.instance.views.pop();
				break;
			case 'task_icon':
				this.auditsSelected = Number(e.target.dataset.index) + 1;
				this.updateSelectedTasks();
				break;
			case 'hint':
				AppCore.instance.views.push(new HintView('hint_battle'));
				break;
			default:
				break;
		}
	}

	/**
	 * show.
	 */
	show() {
		const auditsCompleted = config('audits_completed');

		this.container = AppCore.instance.itd5ka.shell.popup('itd_battle_prepare');

		const stage = AppCore.instance.game.inventory.stages[this.stage];
		this.auditsSelected = 1;
		const tasksContainer = this.container.querySelector('container');
		tasksContainer.innerHTML = '';
		const tasksMax = 5;
		for (let i = 0; i < tasksMax; i++) {
			const el = document.createElement('div');
			el.classList.add('task_icon', stage.auditsAvailable > i ? 'available' : 'unavailable');
			el.innerHTML = `<div class='task_icon_image'><p>${auditsCompleted + i + 1}</p></div>`;
			el.id = 'task_icon';
			el.dataset.index = i;
			tasksContainer.appendChild(el);
		}

		this.updateSelectedTasks();
	}

	/**
	 * .
	 */
	updateSelectedTasks() {
		this.container.querySelectorAll('.task_icon').forEach((el) => {
			el.classList[el.dataset.index < this.auditsSelected ? 'remove' : 'add']('deselected');
		});
	}

	/**
	 * hide.
	 */
	hide() {
		this.container = AppCore.instance.itd5ka.shell.popupHide();
	}
}

/**
 * @param {number} stageindex .
 * @param {number} audits amount of audits started at same time
 */
function startBattle(stageindex, audits) {
	AppCore.instance.game.inventory.raw.content.newstats.sprintsLaunchedAtSameTime = audits;

	const stage = AppCore.instance.game.inventory.stages[stageindex];
	const rooms = [];

	for (const i in AppCore.instance.game.inventory.psources) {
		const room = AppCore.instance.game.inventory.psources[i];
		if (room.stage === stageindex) {
			rooms.push(room);
		}
	}

	const auditsCompleted = config('audits_completed');
	const battle = new ItdBattle(AppCore.instance.game);
	battle.init(rooms, audits, auditsCompleted);
	AppCore.instance.game.battles[stage.id] = battle;

	// OfficeView перерендерит офис после вызова startBattle, пока и так сойдет
	AppCore.instance.ticker.timeout(() => lockUiElements(true), 1);

	AppCore.instance.sounds.playTheme('theme-battle');
	battle.events.once('td_battle_finished', ({ win }) => {
		//delete AppCore.instance.game.battles[stage.id];
		lockUiElements(false);
		AppCore.instance.sounds.playTheme('theme-main');
	});
}

/**
 * @param {boolean} locked .
 */
function lockUiElements(locked) {
	const action = locked ? 'add' : 'remove';
	const container = AppCore.instance.itd5ka.shell.html.container_menu;
	const elements = [];
	for (let i = 0; i < container.children.length; i++) {
		elements.push(container.children[i]);
	}
	elements.push(AppCore.instance.itd5ka.shell.html.button_battle);
	elements.push(AppCore.instance.itd5ka.shell.html.button_location_next);
	elements.push(AppCore.instance.itd5ka.shell.html.button_location_prev);

	for (const i in elements) {
		const el = elements[i];
		el.classList[action]('disabled');
	}
}
