/**
 * @File AppCore.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Game/Core
 */
import { ViewManager } from '../core/views.js';
import Ticker from '../core/Ticker.js';
import InputsManager from './InputsManager.js';
import Events from '../core/events.js';
import HttpRequest from '../core/HttpRequest.js';
import GameCore from './GameCore.js';
import AssetsManager from './AssetsManager.js';
import VideoManager from './VideoManager.js';
import Itd5kaShell from '../itd5ka/Itd5kaShell.js';
import SoundManager from './SoundManager.js';
import logger from '../core/logger.js';
import { storage } from '../js-client-utils/lib/storage.js';
import NotificationView from './views/NotificationView.js';
import { action } from './shorthands.js';
import Vk from '../thirdparty/Vk.js';
import ViewRoot from './views/ViewRoot.js';
import settings from './settings.js';

import { v4 as uuidv4 } from 'uuid';

/**
 * AppCore. Core application class
 */
export default class AppCore {
	static ignored_log_events = [
		'container_tasks',
		'button_upgrade'
	];

	static SAVE_INVERVAL = 1000 * 60; // 1m
	static DELAYED_SAVE_INVERVAL = 1000 * 10; // check if need resave faster

	/**
	 * constructor.
	 */
	constructor() {
		this.views = new ViewManager();
		this.ticker = new Ticker();
		this.events = Events;
		this.inputs = InputsManager.instance;
		this.video = new VideoManager();

		this.http = new HttpRequest();
		this.api = new HttpRequest();
		this.game = new GameCore();
		this.assets = new AssetsManager();
		this.sounds = new SoundManager();

		this.itd5ka = {
			shell: new Itd5kaShell(),
		};

		this.userid = null;
		this.new_user = false;
		this.userAgent = navigator.userAgent.toLowerCase();
		console.log(this.userAgent);
	}

	/**
	 * @returns {AppCore} this
	 */
	init() {
		this.views.init();
		this.ticker.init();
		this.inputs.initOnce();
		this.game.init();
		this.assets.init();
		this.sounds.init();
		/*
		this.render.init({
			canvas: document.getElementById('td_game_canvas'),
			resizeTo: document.getElementById('tamashi_td_root')
		});
		*/

		this.itd5ka.shell.init(document.querySelector('itd5ka#main.container'), this.ticker);
		
		let path = settings.SERVER_PATH;
		
		/*
			Use this replacement if the server used cors
		/*
		if (this.is_iphone() || this.is_ipad || this.is_ipod()) path = path.replace('https', 'vkcors');
		*/

		this.http.init(path);
		this.api.init(path + settings.SERVER_API);

		this.ticker.events.on('game_tick', this._loop, this);
		this.timestamp = 0;

		//const fps_container = document.querySelector('div#fps');
		const fps_container = document.querySelector('div#meta_info');
		this.fps = fps_container.getElementsByTagName("a")[2];
		this.fps_count = 0;
		this.fps_delta = 0;

		document.querySelector('body').classList.add(this.is_webkit() ? 'noselect_webkit' : 'noselect');

		return this;
	}

	pushNewRootView() {
		this.rootView = new ViewRoot();
		this.views.push(this.rootView);
	}

	resetRootView() {
		const root = this.views.root();
		root?.reset(false, 0);
	}

	restoreRootView() {
		const root = this.views.root();
		root?.reset(true, -1);
		this.itd5ka.shell.selectMenuElements([this.itd5ka.shell.html.button_itd_main, this.itd5ka.shell.html.label_itd_status]);
	}

	get selectedLocation() {
		const root = this.views.root();
		if (root) return root.selectedLocation;
		return 0;
	}

	check_local_user(user_id) {
		if (storage.available()) {
			this.userid = storage.get('itd_user_id');
			if (!this.userid) {
				this.new_user = true;
				if (user_id) this.userid = user_id;
				else this.userid = uuidv4(); //crypto.randomUUID()
				this.lasttime_collect = Date.now();
				storage.save('itd_user_id', this.userid);
				storage.save('itd_lasttime_collect', this.lasttime_collect);
				logger.group('CORE_STATUS', `New user id generated ${this.userid}`);
			} else {
				this.lasttime_collect = storage.get('itd_lasttime_collect');
				logger.group('CORE_STATUS', `User id got from storage ${this.userid}`);
				return true;
			}
		}
		return false;
	}

	showError(message, stack, type) {
		console.error(message, stack)
		const el = document.querySelector('#errorlog');
		if (type == -1) {
			el.className = 'attention';
			el.innerHTML += message + '\n\n';
		} else if (type == -2) {
			el.innerHTML += message + '\n';
			el.innerHTML += stack;
		} else {
			el.innerHTML += message + '\n';
			el.innerHTML += stack;
		}
	}

	/**
	 * @async
	 * @returns {Promise} .
	 */
	standalone_auth() {
		console.log("standalone_auth");
		this.check_local_user();
		let build = document.querySelector('div#meta_info');
        build.getElementsByTagName("a")[0].innerHTML += '-standalone';
        build.getElementsByTagName("a")[1].innerHTML = 'uid: ' + this.userid;
        AppCore.instance.itd5ka.shell.set_platform_scale();
		return this.api.post('users/auth', { fid: this.userid });
	}

	is_iphone() {
		return /iphone/.test(this.userAgent);
	}

	is_ipad() {
		return /ipad/.test(this.userAgent);
	}

	is_ipod() {
		return /ipod/.test(this.userAgent);
	}

	is_android() {
		return /android/.test(this.userAgent);
	}

	is_mobile() {
		return this.is_iphone() || this.is_ipad() || this.is_ipod() || this.is_android();
	}

	is_webkit() {
		return this.userAgent.indexOf('applewebkit') > -1;
	}

	vk_auth() {
		this.api.setHeader('Authorization', 'Bearer ' + Vk.instance.search_string);
		this.check_local_user(Vk.instance.user_id);
		return this.api.post('users/auth', {});
	}

	/**
	 * .
	 *
	 * @param {string?} userid user id to load save
	 * @async
	 * @returns {Promise} .
	 */
	load(userid = null) {
		const promises = [
			this.assets.load({
				textures: 'img/textures.png',
				arr: [
					'img/itd/floor-base.jpg',
					'img/itd/kafe.jpg',
					'img/itd/reception_3.jpg',
					'img/itd/sportzal.jpg',
					'img/itd/hr.jpg',
					'img/itd/konferenz_zal.jpg',
					'img/itd/reception_4.jpg',
					'img/itd/zal_gordosti.jpg',
					'img/itd/kabinet_glavnogo.jpg',
					'img/itd/reception_1.jpg',
					'img/itd/restroom.jpg',
					'img/itd/reception_2.jpg',
					'img/itd/sklad.jpg',
					'img/bg1_loop.jpg',
					'img/boss-background.png',
					'img/paper.png',
					'img/stamp-256px-tiled.png',
					'img/tutorial/tutorial_page_1.jpg',
					'img/tutorial/tutorial_page_2.jpg',
					'img/tutorial/tutorial_page_3.jpg',
					'img/tutorial/tutorial_page_4.jpg',
					'img/tutorial/tutorial_page_5.jpg',
					'img/tutorial/tutorial_page_6.jpg'
				]
			}),
			this.sounds.preload()
		];
		// , this.render.load()

		if (userid) {
			this.userid = userid;

			promises.push(
				this.api.post('users/load', { fid: this.userid }).then((response) => {
					logger.group('CORE_STATUS', `Loaded user data ${this.userid} from server`);
					if (response.content.savedata) {
						const savedata = response.content.savedata;
						// WARN: place the save data to parse for debug
						//const savedata = JSON.parse('');
						this.game.deserializeInventoryData(savedata);
						const now = Date.now();
						console.log('offline profit', now - this.lasttime_collect);
						action('collect_profit', { time: now + (now - this.lasttime_collect) }, true);
						action('collect_profit', { time: now }, true);
					}
				})
			);
		}

		return Promise.all(promises);
	}

	incAchievement(aid) {
		this.api.post('users/incahievement', { fid: this.userid, achievement: aid });
	}

	/**
	 * @async
	 * @returns {Promise} .
	 */
	getPvp() {
		return this.api.post('users/pvp', { fid: this.userid });
	}

	showSysError(text) {
		let syspanel = document.querySelector('div#syserrorpanel');
		if (syspanel.classList.contains('hidden')) {
			syspanel.classList.remove('hidden');
			let entry = syspanel.getElementsByTagName('entry');
			entry[0].innerHTML = text;
		}
	}
	
	hideSysError() {
		document.querySelector('div#syserrorpanel').classList.add('hidden');
	}

	/**
	 * @async
	 * @returns {Promise} .
	 */
	subscribeNotifications() {
		this.new_user = false;
		/*
		TODO: Unlock if need notifications system back to life
		AppCore.instance.api.post('users/notifications', { fid: this.userid }).then((response) => {
			if (response.data.length > 0) {
				AppCore.instance.views.push(new NotificationView(response.data[0]));
			}
			this.subscribeNotifications();
		});*/
	}

	/**
	 *
	 */
	saveGameDataOnServer(force = false) {
		if (this.userid) {
			if (force || this.lastTime === undefined) {
				AppCore.instance.api.post('users/save', {
					savedata: this.game.serializeInventoryData(),
					fid: this.userid
				});
				this.lastTime = Date.now();
				this.dirty = false;
			} else {
				const currTime = Date.now();
				const dt = currTime - this.lastTime;
				if (dt >= 10 * 1000) {
					AppCore.instance.saveGameDataOnServer(true);
					this.lastTime = currTime;
					this.dirty = false;
				} else {
					this.dirty = true;
				}
			}
		}
	}

	/**
	 *
	 */
	isRecentlySaved() {
		return Date.now() - this.lastTime < AppCore.DELAYED_SAVE_INVERVAL;
	}

	/**
	 *
	 */
	logOnServer(event, data, logWithResources = false) {
		const eventData = { event: event };

		if (data && Object.keys(data).length !== 0) {
			eventData.data = data;
		}

		if (logWithResources) {
			eventData.resources = {
				gems: this.game.actions.core.inventory.getFormattedAmount('gems'),
				gold: this.game.actions.core.inventory.getFormattedAmount('gold'),
				coffee: this.game.actions.core.inventory.getFormattedAmount('coffee')
			};
		}

		if (this.userid) {
			eventData.fid = this.userid;
			AppCore.instance.api.post('users/log', eventData);
		}
	}

	/**
	 * starts ticker and game logic
	 *
	 * @returns {AppCore} this
	 */
	run() {
		this.ticker.run();

		this.saveGameDataOnServer(true);
		this.ticker.interval(() => {
			if (!this.isRecentlySaved()) this.saveGameDataOnServer();
		}, AppCore.SAVE_INVERVAL);
		this.ticker.interval(() => {
			if (this.dirty) AppCore.instance.saveGameDataOnServer(true);
		}, AppCore.DELAYED_SAVE_INVERVAL);

		window.addEventListener('resize', () => {
			this.events.emit('resize');
			this.itd5ka.shell.events.emit('resize');
		});

		this.sounds.playTheme('theme-main');
		// this.sounds.playTheme('theme-main-test');

		return this;
	}

	/**
	 * _loop. Application tick loop
	 *
	 * @param {number} dt delta time
	 * @param {number} dtf delta time factor (1 in perfect case)
	 * @param {number} timestamp .
	 */
	_loop(dt, dtf, timestamp) {
		this.timestamp = timestamp;
		this.views._views.map((view) => {
			if (view._loop) {
				view._loop(dt, dtf, timestamp);
			}
		});

		if (this.fps_count >= 10) {
			this.fps.innerHTML = `fps: ${Math.round(1000 / (this.fps_delta / this.fps_count))}`;
			this.fps_count = 0;
			this.fps_delta = 0;
		}
		this.fps_count += 1;
		this.fps_delta += dt;
	}

	/**
	 * @returns {AppCore} instance
	 */
	static get instance() {
		if (!AppCore._instance) {
			AppCore._instance = new AppCore();
		}

		return AppCore._instance;
	}
}
