/**
 * @File PvpResultsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import AppCore from '../AppCore.js';
import ViewUiBase from './ViewUiBase.js';
import { inventoryGet, config, action } from '../shorthands.js';
import RewardView from './RewardView.js';
import OfficeView from './OfficeView.js';

/**
 *	.
 */
export default class PvpResultsView extends ViewUiBase {
	/**
	 *
	 * @param {boolean} win pvp result
	 */
	constructor(win, lootpackName) {
		super();
		this.win = win;
		this.lootpackName = lootpackName;
	}

	/**
	 * @param {string} key click element key
	 * @param {oject} args click arguments
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				AppCore.instance.views.pop();
				AppCore.instance.rootView.pushNewOfficeView();
				break;
			case 'open_reward':
				AppCore.instance.views.pop();
				AppCore.instance.rootView.pushNewOfficeView();
				const lootpack = inventoryGet(this.lootpackName);
				AppCore.instance.views.push(new RewardView(lootpack.icon));
				break;
			default:
				break;
		}
	}

	/**
	 * show.
	 */
	show() {
		const shell = AppCore.instance.itd5ka.shell;
		this.container = shell.popup('itd_pvp_results');
		shell.backgorundStyle('default');

		action('open_lootpack', { id: this.lootpackName });

		this.container.querySelector('label#header').innerHTML = this.win ? 'Победа' : 'Поражение';
		this.container.querySelector('label#player_rating').innerHTML = `Ваша репутация: ${config(
			'pvp_glory'
		)}`;

		const rankel = this.container.querySelector('icon#player_rank');
		rankel.classList.value = `textures-rank-${config('pvp_rank')}`;

		this.container.querySelector('label#pvp_result').innerHTML = this.win
			? `Вы получили +1 репутации!`
			: `Вы потеряли 1 репутации!`;

		this.container
			.querySelector('container#win_elements')
			.classList[this.win ? 'remove' : 'add']('hidden');

		shell.elementVisible('button_close_popup', !this.win);
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
		AppCore.instance.itd5ka.shell.elementVisible('button_close_popup', true);
	}
}
