/**
 * @File BattleResultsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { inventoryGet, action, sound } from '../shorthands.js';
import RewardView from './RewardView.js';

/**
 * Game root view
 */
export default class BattleResultsView extends ViewUiBase {
	/**
	 * @param {object} args
	 * @param {boolean} args.win .
	 * @param {string} args.lootpack lootpack id
	 * @param {number} args.gems amount of gems recieved
	 * @param {number} args.duration battle duration
	 */
	constructor({ win, lootpack, gems, duration }) {
		super();

		this.win = win;
		this.lootpack = lootpack;
		this.gemsReward = gems;
		this.duration = duration;
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
			case 'claim_reward':
				e.stopPropagation();
				AppCore.instance.views.pop();
				break;
			case 'open_reward': {
				action('add_currency', { type: 'gems', amount: this.gemsReward });

				const lootpack = inventoryGet(this.lootpack);
				AppCore.instance.views.push(new RewardView(lootpack.icon));
				break;
			}
			default:
				break;
		}
	}

	init() {
		super.init();
		// play sounds only once
		if (this.win) {
			sound('victory');
		} else {
			sound('defeat');
		}
	}

	/**
	 * show.
	 */
	show() {
		const duration = new Date(this.duration);
		if (this.win) {
			const lootpack = inventoryGet(this.lootpack);
			action('open_lootpack', { id: this.lootpack });
			AppCore.instance.itd5ka.shell.popupMessage(
				'Победа',
				`
				<container class='battle-results'>
					<label class='duration'>${duration.getMinutes()}м ${duration.getSeconds()}с</label>
					<div class='battle-reward-container'>
						${this.gemsReward > 0 ? 
							"<icon class='gems-icon sprite-textures textures-icon-gem'><label class='gems'>+" +
								this.gemsReward +
							"</icon>"
							: ""}
						<icon class='lootpack-icon sprite-textures textures-${lootpack.icon}-128px'></icon>
					</div>
					<button id='open_reward' class='decor-itd-0'> Забрать награду
				</container>
				`
			);
		} else {
			AppCore.instance.itd5ka.shell.popupMessage(
				'Поражение',
				`
				<container class='battle-results'>
					<label class='header'>Спринты оказались сильнее</label>
					<label class='duration'>${duration.getMinutes()}м ${duration.getSeconds()}с</label>
				</container>
				`
			);
		}
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
