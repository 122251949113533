export default class DebugDashboard {
	constructor() {
		this.confing = {
			logLen: 5
		};
	}
	init(element) {
		this.element = element;
		this.sync();

		return this;
	}

	sync() {
		const _qsearch = (query) => this.element.querySelector(query);

		this.html = {
			label_statename: _qsearch('#statename'),
			entry_log: _qsearch('#log')
		};
	}

	state(name) {
		this.print('statename', name);
		switch (name) {
			case 'frame':
				break;
		}
	}

	/**
	 * Sets label#id innerHTML
	 *
	 * @param {string} text text to set as innerHTML
	 * @param {string} id id in game-itd_5ka.pug
	 * @param {string} [_scope='label'] element type
	 */
	print(text, id, _scope = 'label') {
		let t = text;
		if (typeof text === 'number') {
			t = text.toFixed(2);
		}
		this.html[_scope + '_' + id].innerHTML = t;
	}

	log(message) {
		const m = document.createElement('entry');
		m.innerHTML = message;
		const log = this.html.entry_log;
		log.appendChild(m);
		if (log.children.length > this.confing.logLen) {
			log.removeChild(log.children[0]);
		}
	}
}
