/**
 * @File BattleResultsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { inventoryGet, action } from '../shorthands.js';

/**
 * Game root view
 */
export default class JournalView extends ViewUiBase {
	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				AppCore.instance.views.pop();
				break;
		}
	}

	show() {
		const shell = AppCore.instance.itd5ka.shell;
		shell.html.button_journal.classList.remove('notification');
		
		const container = AppCore.instance.itd5ka.shell.popup('journal').querySelector('#journal');
		const children = container.childNodes;

		for (let i = 0; i < children.length; i++) {
			const c = children[i];

			if (c.classList.contains('collected')) {
				container.removeChild(c);
				container.appendChild(c);
			}
			if (c.classList.contains('completed')) {
				container.removeChild(c);
				container.insertBefore(c, children[1]);
			}
		}
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
