/**
 * @File BattleResultsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { inventoryGet, action } from '../shorthands.js';

/**
 * Game root view
 */
export default class SignPaperView extends ViewUiBase {
	constructor(reward) {
		super();
		this.reward = reward;
		action('reset_psources');
	}

	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		AppCore.instance.views.pop();
		AppCore.instance.incAchievement(1);
	}

	show() {
		const el = AppCore.instance.itd5ka.shell.elementVisible('decor_sign_paper', true);
		el.querySelector('#reward_amount').innerHTML = ` ${this.reward} `;
		//AppCore.instance.itd5ka.shell.print('sign_paper', this.text);
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.elementVisible('decor_sign_paper', false);
	}
}
