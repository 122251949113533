/**
 * @File ProfitSourceItd.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @module Itd5ka
 */
import { EventsManager } from '../core/events.js';
import { config } from '../game/shorthands.js';
import AppCore from '../game/AppCore.js';
import { playAnimation, delayedAction } from '../game/dust/ui_utils.js';
import { formatTime } from '../game/dust/ui_utils.js';

const HIDDEN = 'hidden';
const DISABLED = 'disabled';

// progress bar
const PSOURCE_TRESHOLD = 'psource_threshold';
const PSOURCE_PROFIT = 'psource_profit';
const CONTINUES = 'continues';
const PROGRESS = '--progress';
const STAR_BOUNCE = 'star-bounce';
const LABEL_TIME = 'label_time';

// upgrade
const GAME = 'game';
const MAX_PSOURCE_LEVEL = 'maxPsourceLevel';
const UPGRADE_PSOURCE = 'upgrade_psource';
const PURCHASE_PSOURCE = 'purchase_psource';
const LABEL_UPGRADE_COST = 'label_upgrade_cost';
const LABEL_UPGRADE = 'label_upgrade';
const GOLD = 'gold';

// employee
const HEALTH = 'health';
const HEALTH_MAX = 'healthMax';
const CHARGES = 'charges';
const CHARGES_USED = 'chargesUsed';
const HEALTH_PROGRESS = 'health_progress';

const UPDATE_OFFICE_ELEMENTS_TIME = 0.2;

/**
 * .
 */
export default class ProfitSourceItd {
	/**
	 * @param {string} id profit source id
	 * @param {HTMLElement} container root element to append child too
	 * @param {HTMLElement} template floor template. Check itd5ka/psource.pug itd5ka#psource.template
	 */
	constructor(id, container, template) {
		this.id = id;
		this.template = template;
		this.container = container;
		this.events = new EventsManager();
		this.battle = {};
		this.resetCache();
		this.updateOfficeElementsTime = 0;
	}

	/**
	 * Creates psource element
	 *
	 * @returns {ProfitSourceItd} this
	 */
	init() {
		const el = this.template.cloneNode(true);
		el.classList.remove('template');
		this.element = el;

		this.container.appendChild(el);

		this.sync();
		this.run();
		this.resetCache();

		return this;
	}

	/**
	 * Update HTML Elements
	 */
	sync() {
		const _qsearch = (query) => this.element.querySelector(query);

		this.html = {
			icon_employee: _qsearch('icon#psource-manager'),
			label_name: _qsearch('label#psource-label'),
			label_level: _qsearch('label#psource-level'),
			label_time: _qsearch('label#psource-time'),
			progressbar_profit: _qsearch('progressbar#psource-progress'),
			label_upgrade: _qsearch('label#psource-upgrade-label'),
			// дизайнеры просили сделать весь контейнер апгрейда этажа кликабельным
			button_upgrade: _qsearch('#psource-upgrade-container'),
			label_upgrade_cost: _qsearch('label#psource-upgrade-value'),
			container_info: _qsearch('#psource-info-container.container'),
			container_controls: _qsearch('#psource-controls.container'),
			decor_employee: _qsearch('decor#psource-employee-sprite'),
			decor_npc_1: _qsearch('decor#psource-npc-sprite-1'),
			decor_npc_2: _qsearch('decor#psource-npc-sprite-2'),
			decor_npc_3: _qsearch('decor#psource-npc-sprite-3'),
			decor_effects: _qsearch('decor#effects-container'),
			stat_employee: _qsearch('progressbar#psource-employee-stat'),
			decor_gadget: _qsearch('decor#psource-gadget-sprite'),
			stat_gadget: _qsearch('progressbar#psource-gadget-stat'),
			decor_lock: _qsearch('decor#psource-lock-icon'),
			template_taskentry: _qsearch('#psource-tasks.container entry.template'),
			container_tasks: _qsearch('#psource-tasks.container'),
			button_coffee: _qsearch('button#use_coffee')
		};
	}

	resetCache() {
		this.cache = {
			prevFrameTreshold: -1,
			prevFrameProfit: -1,
			prevFrameFactor: -1,
			prevFrameGadgetCharges: -1,
			prevFrameGadgetHeight: -1,
			prevFrameEmployeeHealth: -1,
			prevFrameEmployeeHealthShake: false,
			prevFrameUpgradeCost: -1,
			prevFrameUpgradeMoneyEnough: undefined,
			prevFrameUpgradeFactor: -1,
			prevFrameGadgetChargesUsed: -1,
			employeeVisible: undefined,
			gadgetVisible: undefined,
			employeeDecor: undefined,
			gadgetDecor: undefined
		};
	}

	/**
	 * @param {object} data psource data
	 */
	draw(data, npcs, factor = 1) {
		if (data.sprite) {
			this.element.style.backgroundImage = `url(./res/img/itd/${data.sprite}.jpg)`;
			this.element.classList.add(data.sprite);
		}

		if (data.index > config('psources_purchased')) {
			this.element.classList.add('disabled');
			this.html.container_controls.classList.add('hidden');
			this.drawNpc(data, npcs);

			return;
		}

		this.element.classList.remove('disabled');
		this.html.container_controls.classList.remove('hidden');

		if (!data.active) {
			this.html.container_info.classList.add('hidden');
			this.element.classList.add('unactivated');
			this.print('label_upgrade', 'Открыть');
			let totalcost = Number(config('purchase_psource', { id: data.id }));
			this.print(LABEL_UPGRADE_COST, AppCore.instance.game.formatGameNumber(totalcost));
			this.drawNpc(data, npcs);

			return;
		}

		this.html.container_info.classList.remove('hidden');
		this.element.classList.remove('unactivated');

		//this.print('label_upgrade', 'Улучшить');

		this.print('label_name', data.name);
		this.print('label_level', 'ур.' + data.level);

		this.drawProgressbar(data, false);
		this.drawDecor(data, npcs);
		this.drawLabels(data, false);
	}

	/**
	 * @param {object} data psource data
	 * @param {number} factor число апгрейдов при нажатии кнопки
	 */
	drawUpgradeButton(data, factor) {
		if (data.level >= AppCore.instance.game.getConfig(GAME, MAX_PSOURCE_LEVEL)) {
			this.html.button_upgrade.classList.add(HIDDEN);
			return;
		}
		this.html.button_upgrade.classList.remove(HIDDEN);

		const action = data.level ? UPGRADE_PSOURCE : PURCHASE_PSOURCE;
		
		let totalcost = 0;

		if (action == PURCHASE_PSOURCE) {
			totalcost = Number(config(PURCHASE_PSOURCE, { id: data.id }));
		} else {
			for (let i = 0; i < factor; i++) {
				const value = config(action, { id: data.id, level: data.level + i });
				const cost = Math.round(Math.floor(value));
				totalcost += cost;
			}
		}

		if (this.cache.prevFrameUpgradeCost != totalcost) {
			this.print(LABEL_UPGRADE_COST, AppCore.instance.game.formatGameNumber(totalcost));
			this.cache.prevFrameUpgradeCost = totalcost;
		}
		const enoughMoney = AppCore.instance.game.actions.testSpend(GOLD, totalcost);
		
		if (this.cache.prevFrameUpgradeMoneyEnough != enoughMoney) {
			if (enoughMoney) {
				this.html.button_upgrade.classList.remove(DISABLED);
			} else {
				this.html.button_upgrade.classList.add(DISABLED);
			}
			this.cache.prevFrameUpgradeMoneyEnough = enoughMoney;
		}

		if (data.active && this.cache.prevFrameUpgradeFactor != factor) {
			this.print(LABEL_UPGRADE, `Улучшить x${factor}`);
			this.cache.prevFrameUpgradeFactor = factor;
		}
	}

	/**
	 * @param {object} data psource data
	 */
	drawProgressbar(data, in_battle) {
		if (in_battle) return;

		let factor = 1;

		const threshold = config(PSOURCE_TRESHOLD, { id: data.id });
		const profit = config(PSOURCE_PROFIT, { id: data.id });

		// --- fill
		if (this.cache.prevFrameTreshold != threshold) {
			if (threshold > 1) {
				this.html.progressbar_profit.classList.remove(CONTINUES);
			} else {
				this.html.progressbar_profit.classList.add(CONTINUES);
			}
		}

		if (threshold > 1) {
			const now = Date.now();
			const dt = (now - data.timestamp) / 1000;
			factor = Math.min(1, dt / threshold);
		}

		if (Math.abs(factor - this.cache.prevFrameFactor) > 0.001 || this.cache.prevFrameFactor > factor) {
			this.html.progressbar_profit.style.setProperty(PROGRESS, `${factor * 100}%`);
			if (this.cache.prevFrameFactor > factor) {
				playAnimation(this.html.progressbar_profit, STAR_BOUNCE, 300);
			}
			this.cache.prevFrameFactor = factor;
		}

		// --- text
		if (this.cache.prevFrameProfit != profit) {
			this.html.progressbar_profit.innerHTML = `${AppCore.instance.game.formatGameNumber(profit)}`;
		}
		if (this.cache.prevFrameTreshold != threshold) {
			this.print(LABEL_TIME, `${formatTime(threshold)}`);
		}

		this.cache.prevFrameTreshold = threshold;
		this.cache.prevFrameProfit = profit;
	}

	/* eslint-disable max-statements, complexity */
	/**
	 * @param {object} data psource data
	 * @param {object} npcs npcs shared array for random generation
	 */
	drawDecor(data, npcs) {
		const employee = AppCore.instance.game.inventory.get(data.employee);
		const gadget = AppCore.instance.game.inventory.get(data.gadget);
		const d_employee = this.html.decor_employee.classList;
		const i_employee = this.html.icon_employee.classList;
		const d_gadget = this.html.decor_gadget.classList;

		if (employee) {
			if (!this.cache.employeeVisible) {
				this.cache.employeeVisible = true;
				if (d_employee.contains(HIDDEN)) {
					d_employee.remove(HIDDEN);
					i_employee.remove(HIDDEN);
				}
			}

			const decorClassName = `sprites-managers-${employee.animation}`;
			if (this.cache.employeeDecor != decorClassName) {
				this.cache.employeeDecor = decorClassName;
				d_employee.value = '';
				i_employee.value = '';
				const iconClassName = `textures-${employee.icon}`;
				d_employee.add(decorClassName);
				i_employee.add(iconClassName);
			}
		} else if (this.cache.employeeVisible || typeof this.cache.employeeVisible === 'undefined') {
			this.cache.employeeVisible = false;
			if (!d_employee.contains(HIDDEN)) {
				d_employee.add(HIDDEN);
				i_employee.add(HIDDEN);
				const s_employee = this.html.stat_employee.classList;
				s_employee.add(HIDDEN);
			}
		}

		if (gadget) {
			if (!this.cache.gadgetVisible) {
				this.cache.gadgetVisible = true;
				d_gadget.remove(HIDDEN);
				this.html.stat_gadget.classList.remove(HIDDEN);
			}

			const decorClassName = `textures-${gadget.animation}`;
			if (this.cache.gadgetDecor != decorClassName) {
				this.cache.gadgetDecor = decorClassName;
				d_gadget.value = '';

				d_gadget.add(decorClassName);
			}
		} else if (this.cache.gadgetVisible || typeof this.cache.gadgetVisible === 'undefined') {
			this.cache.gadgetVisible = false;
			d_gadget.add(HIDDEN);
			const s_employee = this.html.stat_gadget.classList;
			s_employee.add(HIDDEN);
		}

		this.drawNpc(data, npcs);
	}

	/* eslint-disable max-statements, complexity */
	/**
	 * @param {object} data psource data
	 */
	drawNpc(data, npcs) {
		const decor_npc = [
			this.html.decor_npc_1,
			this.html.decor_npc_2,
			this.html.decor_npc_3
		];
		if (!data.npc) return;
		if (!data.active) {
			for (let i = 0; i < decor_npc.length; i++) {
				this.drawNpcElement(data, decor_npc[i], null);
			}
			return;
		}
		if (data.npc.length === 0) {
			const data_config = AppCore.instance.game.raw.content.config.content.psources[data.id];
			const npc_config = AppCore.instance.game.raw.content.config.content.npcs;
			if (data_config.npc_slot) {
				for (let i = 0; i < data_config.npc_slot.length; i++) {
					if (npcs.length === 0) {
						const n = Object.keys(npc_config);
						npcs.push(...n);
					}
					let rindex = Math.floor(npcs.length * Math.random());
					const npc_id = npcs[rindex];
					npcs.splice(rindex, 1);
					const slot = data_config.npc_slot[i].split(';');
	
					const anim_delay = -Math.floor(Math.random() * 3000);
					const flip = Math.random() < 0.5;
					const npc = {
						icon: npc_config[npc_id].icon,
						h_pos: slot[0],
						v_pos: slot[1],
						anim_delay: anim_delay,
						flip: flip
					};
					data.npc.push(npc);
	
					this.drawNpcElement(data, decor_npc[i], npc);
				}
				if (data_config.npc_slot.length < decor_npc.length) {
					for (let i = data_config.npc_slot.length; i < decor_npc.length; i++) {
						decor_npc[i].classList.add(HIDDEN);
					}
				}
			}
		} else {
			for (let i = 0; i < data.npc.length; i++) {
				const npc = data.npc[i];
				this.drawNpcElement(data, decor_npc[i], npc);
			}
			if (data.npc.length < decor_npc.length) {
				for (let i = data.npc.length; i < decor_npc.length; i++) {
					decor_npc[i].classList.add(HIDDEN);
				}
			}
		}
	}

	drawNpcElement(data, element, npc) {
		if (data.active) {
			element.classList.remove(HIDDEN);
			element.classList.add(`sprites-npc-${npc.icon}`);
			element.style.setProperty('--h_position', npc.h_pos);
			element.style.setProperty('--v_position', npc.v_pos);
			element.style.setProperty('--breath_delay', `${npc.anim_delay}ms`);
			if (npc.flip) {
				element.style.setProperty('--y-rotate', '180deg');
			}
		} else {
			element.classList.add(HIDDEN);
		}
	}
	
	/* eslint-disable max-statements, complexity */
	/**
	 */
	removeNpc(data) {
		for (let i = 0; i < data.npc.length; i++) {
			data.npc[i].classList.add('hidden');
		}
		data.npc = [];
	}

	/**
	 * @param {object} data psource data
	 * @param {ItdBattle} battle
	 */
	drawBattle(data, battle) {
		for (const k in this.battle) {
			const unit = this.battle[k];

			if (
				!battle || // бой удалился
				battle.finished || // бой кончился
				!battle.tasksById[k] || // таска умерла
				battle.tasksById[k].raw.content.roomId !== this.id // таска убежала в другую комнату
			) {
				if (battle && battle.tasksById[k]) battle.tasksById[k].raw.content.health_progress = -1;
				unit.entry.parentNode.removeChild(unit.entry);
				delete this.battle[k];
			}
		}

		if (!battle) {
			return;
		}

		const room = battle.roomsById[this.id];
		for (const i in room.tasks) {
			const task = room.tasks[i];

			if (!this.battle[task.id]) {
				const entry = this.html.template_taskentry.cloneNode(true);
				entry.id = task.id;
				entry.classList.remove('template');
				this.html.template_taskentry.parentNode.appendChild(entry);

				const header = entry.querySelector('label#psource-task-header');
				const text = entry.querySelector('label#psource-task-text');
				const stat = entry.querySelector('progressbar#psource-task-stat');
				const icon = entry.querySelector('icon#psource-task');
				icon.classList.value = '';
				icon.classList.add('textures-' + task.raw.content.icon);
				this.battle[task.id] = { entry, header, text, icon, stat };
				this.resetCache();

				// TODO: need to make tutor system (config: step;hint;pos;duration, progression, etc.)
				if (AppCore.instance.game.tutor <= AppCore.instance.game.raw.content.stats.content.auditsCompleted &&
					AppCore.instance.game.tutor < 4) {
					AppCore.instance.game.tutor++;
					const tooltip = this.html.container_controls.querySelector('tooltip.tooltip');
					tooltip.innerHTML = "Кликай по таскам, чтобы быстрее победить";
					tooltip.style.right = '2vw';
					tooltip.style.top = '6vw';
					delayedAction(function() {
						tooltip.classList.add('active');
						delayedAction(function() {
							tooltip.classList.remove('active');
						}, 6000);
					}, 500);
					const tooltip2 = tooltip.cloneNode(true);
					tooltip.parentNode.appendChild(tooltip2);
					tooltip2.innerHTML = "Пей кофе, чтобы защитить менеджера";
					tooltip2.style.left = '2vw';
					tooltip2.style.top = '20vw';
					delayedAction(function() {
						tooltip2.classList.add('active');
						delayedAction(function() {
							tooltip2.classList.remove('active');
						}, 6000);
					}, 500);
				}
			} else {
				//const conf = AppCore.instance.game.getConfig('tasks', task.raw.content.itemid);
				//this.battle[task.id].header.innerHTML = conf.name;
				//this.battle[task.id].text.innerHTML = task.property('health');
				const health = (task.property(HEALTH) / task.property(HEALTH_MAX));
				const hp = task.property(HEALTH_PROGRESS);
				if (hp != health) {
					this.battle[task.id].stat.style.setProperty(PROGRESS, `${health * 100}%`);
					task.property(HEALTH_PROGRESS, health);
				}
			}
		}
		
		this.drawMisc(data);
	}
	/* eslint-enable complexity, max-statements */

	/**
	 * @param {object} data psource data
	 */
	drawLabels(data, in_battle) {
		const time = Date.now();
		if (time - this.updateOfficeElementsTime > UPDATE_OFFICE_ELEMENTS_TIME) {
			this.updateOfficeElementsTime = time;

			const employee = AppCore.instance.game.inventory.getItdItem(data.employee);
			if (employee) {
				const health = employee.property(HEALTH) / employee.property(HEALTH_MAX);
				if (!in_battle && health >= 1) {
					this.html.stat_employee.classList.add(HIDDEN);
				} else if (this.cache.prevFrameEmployeeHealth != health) {
					this.html.stat_employee.classList.remove(HIDDEN);
					this.html.stat_employee.style.setProperty(PROGRESS, `${health * 100}%`);
					this.html.stat_employee.style.bottom =
						this.html.decor_employee.getBoundingClientRect().height + 'px';
					if (!in_battle) this.cache.prevFrameEmployeeHealth = health;
				}
			} else {
				this.html.stat_employee.classList.add(HIDDEN);
			}
	
			const gadget = AppCore.instance.game.inventory.getItdItem(data.gadget);
			if (gadget) {
				this.html.stat_gadget.classList.remove(HIDDEN);
				const charges = Math.floor(gadget.property(CHARGES));
				if (this.cache.prevFrameGadgetCharges != charges) {
					this.html.stat_gadget.innerHTML = `${charges}`;
					if (!in_battle) this.cache.prevFrameGadgetCharges = charges;
				}
				const gheight = this.html.decor_gadget.getBoundingClientRect().height
				if (this.cache.prevFrameGadgetHeight != gheight) {
					this.html.stat_gadget.style.bottom = (gheight + 10) + 'px';
				}
				this.cache.prevFrameGadgetHeight = gheight;
	
				const chargesUsed = gadget.property(CHARGES_USED);
				if (gadget && chargesUsed > 0 && chargesUsed % 1) {
					const chargesUsedPercent = Math.floor((1 - (chargesUsed % 1)) * 100);
					if (this.cache.prevFrameGadgetChargesUsed != chargesUsedPercent) {
						this.html.stat_gadget.style.setProperty(PROGRESS, `${chargesUsedPercent}%`);
					}
					this.cache.prevFrameGadgetChargesUsed = chargesUsedPercent;
				} else {
					if (this.cache.prevFrameGadgetChargesUsed != 100)
						this.html.stat_gadget.style.setProperty(PROGRESS, '100%');
				}
			} else {
				this.html.stat_gadget.classList.add(HIDDEN);
				if (this.cache.prevFrameGadgetChargesUsed != 100)
					this.html.stat_gadget.style.setProperty(PROGRESS, '100%');
			}
		}
	}

	/**
	 * @param {object} data psource data
	 * @param {ItdBattle} battle
	 */
	drawMisc(data) {
		const gadget = AppCore.instance.game.inventory.getItdItem(data.gadget);
		const employee = AppCore.instance.game.inventory.getItdItem(data.employee);

		// gadget usage effects
		if (gadget) {
			const charges = gadget.property(CHARGES);

			if (charges < this.cache.prevFrameGadgetCharges) {
				const rect = this.html.decor_gadget.getBoundingClientRect();
				const pos = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };
				AppCore.instance.itd5ka.shell.effects.spawnAreaEffect(pos, gadget.property('effectType'));
			}

			this.cache.prevFrameGadgetCharges = charges;
		}

		if (employee) {
			const health = employee.property(HEALTH);

			if (health < this.cache.prevFrameEmployeeHealth) {
				this.html.decor_employee.style.marginLeft = (Math.random() - 0.5) * 5 + 'px';
				this.cache.prevFrameEmployeeHealthShake = true;
			} else if (this.cache.prevFrameEmployeeHealthShake) {
				this.html.decor_employee.style.marginLeft = '0';
				this.cache.prevFrameEmployeeHealthShake = false;
			}

			this.cache.prevFrameEmployeeHealth = health;
		}
	}

	/**
	 * Starts events listen
	 */
	run() {
		for (const key in this.html) {
			const el = this.html[key];
			el.addEventListener('game_input_click', ({ detail }) => {
				this.events.emit('user_click', { detail, key });
			});
		}
	}

	/**
	 * Sets innerHTML for element
	 *
	 * @param {string} id id created in sync()
	 * @param {string} text text to set
	 */
	print(id, text) {
		if (this.html[id].innerHTML !== text) this.html[id].innerHTML = text;
	}

	/**
	 * Removes element
	 */
	dispose() {
		this.container.removeChild(this.element);
	}
}
