/**
 * @File BattleResultsView.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import ViewUiBase from './ViewUiBase.js';
import AppCore from '../AppCore.js';
import { inventoryGet, action } from '../shorthands.js';

/**
 * Game root view
 */
export default class MessageView extends ViewUiBase {
	constructor(title, message) {
		super();

		this.title = title;
		this.message = message;
	}
	/**
	 * _click.
	 *
	 * @param {InputEvent} e
	 */
	_click(e) {
		switch (e.target.id) {
			case 'close_popup':
				e.stopPropagation();
				AppCore.instance.views.pop();
				break;
		}
	}

	show() {
		AppCore.instance.itd5ka.shell.popupMessage(this.title, this.message);
	}

	/**
	 * hide.
	 */
	hide() {
		AppCore.instance.itd5ka.shell.popupHide();
	}
}
