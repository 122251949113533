/**
 * @File ViewUiBase.js
 * @Copyright Tamashi Games
 * @Version 1.0
 * @Module Idle Tovar Defense
 */
import { ViewBase } from '../../core/views.js';
import AppCore from '../AppCore.js';

/**
 * View with default UI methods
 */
export default class ViewUiBase extends ViewBase {
	/**
	 * constructor.
	 */
	constructor() {
		super();
		this.cache = {
			inputsEventsId: null
		};
	}

	/**
	 * init.
	 *
	 * @returns {ViewUiBase} this
	 */
	init() {
		this.cache.inputsEventsId = AppCore.instance.inputs.on('game_input_click', this._click, this).count;
		return this;
	}

	/**
	 * _click.
	 *
	 * @abstract
	 * @param {InputEvent} e
	 */
	_click(e) {
		//..
	}

	/**
	 * dispose.
	 */
	dispose() {
		if (this.cache.inputsEventsId) {
			AppCore.instance.inputs.off(this.cache.inputsEventsId);
			this.cache.inputsEventsId = null;
		}
	}
}
